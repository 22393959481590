import { Delete, Edit, Update, Insert } from '../../../../components/ApolloButtons';
/**helper */
import { paramToVariables, hasOnlyDigits } from '../../../../helper';
/** */
import { gql, useQuery } from "@apollo/client";
/** */
import { ApolloSelect } from '../../../../components/ApolloCells'
/**Apollo Columns */
import { onRender_SKU10 } from '../../../../components/ApolloCells'

/**GQL */
const parent = {/*purchases: [Purchase]*/
    query: gql`query STASH_PENDINGs_noholder{
        stashPendings(inOut: "out") {
            id
            id_stash_pending
            product {
                id
                id_product
                SKU10
                brand
                title
                spec_1
                spec_2
                spec_3
                unit
            }
            holder {
                id
                id_holder
                name
                location {
                    id
                    id_location
                    spot
                    shelf
                    floor
                }
            }
            qty
            destination {
                id
                id_destination
                title
            }
            user {
                id
                id_user
                alt_name
            }
            inserted
        }
    }`,
    table: {
        footer: true,
        accessor: 'stashPendings',
        actions: [
            (index, rowData, onClick_toggleEdit) => {
                return(
                    <Edit
                        key = {index}
                        onClick = { onClick_toggleEdit }
                        variableParser={ (newValues) => {
                            //console.log(newValues);
                            const variables = { 
                                variables: {
                                    id_stash_pending: +rowData.id_stash_pending, //newValues.id.split('_')[1],
                                    qty: +newValues.qty,
                                    destination: [[ 'title', newValues['destination.title'] ]]
                                }
                            };
                            return variables
                        } }
                        GQL = {gql`mutation updateStashPending($id_stash_pending: ID!, $qty: Int, $destination: [[String]]) {
                            updateStashPending(id_stash_pending: $id_stash_pending, qty: $qty, destination: $destination) {
                                id
                                id_stash_pending
                                product {
                                    id
                                    id_product
                                    SKU10
                                    brand
                                    title
                                    spec_1
                                    spec_2
                                    spec_3
                                    unit
                                }
                                holder {
                                    id
                                    id_holder
                                    name
                                }
                                qty
                                destination {
                                    id
                                    id_destination
                                    title
                                }
                            }
                        }`}
                        accessor = {'updateStashPendings'}
                        updateQuery = {gql`query STASH_PENDINGs_noholder{
                            stashPendings(fk_holder: null, inOut: "in") {
                                id
                                id_stash_pending
                                product {
                                    id
                                    id_product
                                    SKU10
                                    brand
                                    title
                                    spec_1
                                    spec_2
                                    spec_3
                                    unit
                                }
                                holder {
                                    id
                                    id_holder
                                    name
                                }
                                qty
                                destination {
                                    id
                                    id_destination
                                    title
                                }
                            }
                        }`}
                        accessor_cacheData = {'stashPendings'}
                    >{{edit:'改', abort:'撤', save:'存'}}</Edit>
                )
            },
            (index, rowData)=>{
                return (
                    <Delete
                        key={index}
                        variables = {paramToVariables( ['id_stash_pending'], rowData) }
                        GQL = { gql`mutation DELETE_STASH_PENDING($id_stash_pending: ID!){
                            deleteStashPending(id_stash_pending: $id_stash_pending) {
                                id
                                id_stash_pending
                                product {
                                    SKU10
                                }
                            }
                        }`}
                    >删</Delete>
                )
            }
        ]
    },
    columns: [
        {
            header: 'inserted',
            accessor: 'inserted',
            formatter: (value)=>{return value.substring(2, 10)},
            onRender: (value)=>{return value},
        },    
        {
            header: 'spot',
            accessor: 'holder.location.spot',
            formatter: (value)=>{return value},
            onRender: (value)=>{return value},
        },
        {
            header: 'shelf',
            accessor: 'holder.location.shelf',
            formatter: (value)=>{return value},
            onRender: (value)=>{return value},
        },
        {
            header: 'floor',
            accessor: 'holder.location.floor',
            formatter: (value)=>{return value},
            onRender: (value)=>{return value},
        },
        {
            header: 'holder',
            accessor: 'holder.name',
            formatter: (value)=>{return value},
            onRender: (value)=>{return value},
        },
        {
            header: 'SKU10',
            accessor: 'product.SKU10',
            formatter: (value)=>{return value},
            onRender: (value)=>{return onRender_SKU10(value)},
        },
        {
            header: 'brand',
            accessor: 'product.brand',
            formatter: (value)=>{return value},
            onRender: (value)=>{return value},
        },
        {
            header: 'title',
            accessor: 'product.title',
            formatter: (value)=>{return value},
            onRender: (value)=>{return value},
        },
        {
            header: 'spec 1',
            accessor: 'product.spec_1',
            formatter: (value)=>{return value},
            onRender: (value)=>{return value},
        },
        {
            header: 'spec 2',
            accessor: 'product.spec_2',
            formatter: (value)=>{return value},
            onRender: (value)=>{return value},
        },
        {
            header: 'spec 3',
            accessor: 'product.spec_3',
            formatter: (value)=>{return value},
            onRender: (value)=>{return value},
        },
        {
            header: 'qty',
            accessor: 'qty',
            formatter: (value)=>{return value},
            onRender: (value)=>{return -Math.abs(value)},
            onEdit: (value, [colAccessor, newValues, setNewValues], formatter) => {
                newValues[colAccessor] = value;
                return  (
                    <input 
                        style={{
                            width: '100%',
                            boxSizing: 'border-box',
                            textAlign: 'right',
                            fontFamily: 'Inconsolata, monospace',
                            fontSize: '14px'
                        }}
                        max="-1"
                        type={'number'} 
                        defaultValue={value}
                        onChange={(e)=>{
                            newValues[colAccessor] = parseInt(e.target.value);
                            setNewValues(newValues);
                        }}
                    />
                )
            }
        },
        {
            header: 'unit',
            accessor: 'product.unit',
            formatter: (value)=>{return value},
            onRender: (value)=>{return value},
        },
        {
            header: 'purpose',
            accessor: 'destination.title',
            formatter: (value)=>{return value},
            onRender: (value)=>{return value},
            onEdit: (value, [colAccessor, newValues, setNewValues], formatter) => { 
                newValues[colAccessor] = value;
                return (
                    <ApolloSelect
                    //style in component already. move it out
                        style={{
                            width: '100%',
                            boxSizing: 'border-box',
                            textAlign: 'center',
                            fontFamily: 'Inconsolata, monospace',
                            fontSize: '14px'
                        }}
                        defaultValue={value}
                        onChange={(e)=>{
                            newValues[colAccessor] = e.currentTarget.value;
                            setNewValues(newValues);
                            //console.log(newValues)
                        }}
                        GQL={gql`query DENTINATIONs {
                            destinations(show_hidden_option: false) {
                                id
                                id_destination
                                title
                            }
                        }`}
                        accessors={['destinations',['title']]}
                        valuePicker={['title']}
                    />
                )
            }
        },
        {
            header: 'user',
            accessor: 'user.alt_name',
            formatter: (value)=>{return value},
            onRender: (value)=>{return value},
        },
    ],
}
/**GQL */
const child0 = {
    query: gql`query HOLDER_products($id_holder: ID) {
        holders(id_holder: $id_holder) {
            id
            id_holder
            products {
                id
                id_product
                brand
                title
                spec_1
                spec_2
                spec_3
            }
        }
    }`,
    table: {
        accessor: 'holders',
    },
    columns: [
        {
            header: 'SKU10',
            accessor: 'products.SKU10',
            formatter: (value)=>{return value},
            onRender: (value)=>{return onRender_SKU10(value)},
        },
        {
            header: 'brand',
            accessor: 'products.brand',
            formatter: (value)=>{return value},
            onRender: (value)=>{return value},
        },
        {
            header: 'title',
            accessor: 'products.title',
            formatter: (value)=>{return value},
            onRender: (value)=>{return value},
        },
        {
            header: 'spec 1',
            accessor: 'products.spec_1',
            formatter: (value)=>{return value},
            onRender: (value)=>{return value},
        },
        {
            header: 'spec 2',
            accessor: 'products.spec_2',
            formatter: (value)=>{return value},
            onRender: (value)=>{return value},
        },
        {
            header: 'spec 3',
            accessor: 'products.spec_3',
            formatter: (value)=>{return value},
            onRender: (value)=>{return value},
        },
        {
            header: 'qty',
            accessor: 'products.qty',
            formatter: (value)=>{return value},
            onRender: (value)=>{return value},
        },
        {
            header: 'unit',
            accessor: 'products.unit',
            formatter: (value)=>{return value},
            onRender: (value)=>{return value},
        },
    ],
    variables: ['holder.id_holder'],
}
const config = [
    {
        parent,
        child0
    },
]
export default config;