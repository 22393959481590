/**apollo-client*/
import { useQuery, useMutation } from "@apollo/client";
/**react */
import { useEffect } from "react";
/** */
import { colDataReducer } from '../../helper'
/** */
import onRender_SKU10 from './onRender_SKU10';


const decimal_2 = (value) => {
    const stringVal = value.toString();
    if (stringVal.length === 0) return `0.00`;
    if (stringVal.length === 1) return `0.0${stringVal}`;
    if (stringVal.length === 2) return `0.${stringVal}`;
    return stringVal.slice(0,-2)+'.'+stringVal.slice(-2);
}
const decimalChecker = (decimalString) => {//console.log(decimalString);
    const decimalArray = decimalString.split('.');
    const decimalArrayLength = decimalArray.length;
    switch(decimalArrayLength) {
        case 1: return `${decimalArray}00`;
        default:
            if (decimalArray[0]==='') return decimalArray[1].length===1? `${decimalArray[1]}0` : decimalArray[1];
            if (decimalArray[1]==='') return `${decimalArray}00`;
            return `${decimalArray[0]}${decimalArray[1].length===1? `${decimalArray[1]}0` : decimalArray[1]}`;
    }
}

const idGetter = (newValues) => {
    return newValues.id? newValues.id.split('_').slice(-1)[0] : null;
}

function ApolloOption(props) {
    const {
        GQL, 
        variables, 
        accessors, // ['product', 'SKU10'] the first item
        valuePicker//pick what to render as option value attr
    } = props;
    
    const { loading, error, data } = useQuery(GQL,{variables});

    useEffect(()=>{

    },data)
    

    if (loading) return <option>Loading...</option>;
    if (error) return <option>{`${error}`}</option>;
    const [parent, child] = accessors;
    const options = data[parent];
    if (data) {
        return(<>
            <option value={''}></option>
            {options.map( (option) => {
                const id = idGetter(option);
                const text = option[child];
                const value = valuePicker==='id'? id : option[valuePicker];
                return <option key={id} value={value}>{text}</option>
            })}
            
        </>)
    }
}
function ApolloSelect(props) {
    const {
        fontSizePx,
        defaultValue,
        onChange,
        GQL, 
        variables, 
        accessors, // ['product', 'SKU10'] the first item from query result, the needed key to dispaly as option text
        valuePicker//pick what to render as option value attr.. most case is the same as accessors[1]
    } = props;

    const { loading, error, data } = useQuery(GQL,{variables});
    if (loading) return <p>Loading...</p>;
    if (error) return <p>{`${error}`}</p>;
    const [parent, child] = accessors;
    const options = data[parent];
    return (
        <select 
            style={{
                fontFamily: 'Inconsolata, monospace',
                fontSize: fontSizePx+'px'
            }}
            defaultValue={defaultValue}
            onChange={onChange}
        >
            <option value={''}></option>
            {options.map( (option) => {
                const id = idGetter(option);
                const text = colDataReducer(child, option);//const text = option[child];
                const value = valuePicker==='id'? id : colDataReducer(valuePicker, option);//option[valuePicker];
                return <option key={id} value={value}>{text}</option>
            })}
        </select>
    )
}
ApolloSelect.defaultProps = {
    fontSizePx: '14',
}

function ApolloInput(props) {
    const {
        type,
        minLength,
        maxLength,
        defaultValue,
        step,
        min,
        onChange
    } = props;
    return  (
        <input
            style={{
                width: '100%',
                boxSizing: 'border-box',
                textAlign: 'center',
                fontFamily: 'Inconsolata, monospace',
                fontSize: '14px'
            }}
            type={type}
            /**text */
            minLength={minLength}
            maxLength={maxLength}
            /**number */
            min={min}
            step={step}
            defaultValue={defaultValue}

            onChange={onChange}
        />
    )
}
/**this is a test only function. */
function render_ApolloInput(value, [colAccessor, newValues, setNewValues], formatter){
    newValues[colAccessor] = value;
    return  (
        <input
            style={{
                width: '100%',
                boxSizing: 'border-box',
                textAlign: 'center',
                fontFamily: 'Inconsolata, monospace',
                fontSize: '14px'
            }}
            type={'text'}
            minLength={3}
            maxLength={3}
            defaultValue={formatter(value)}
            onChange={(e)=>{
                //force uppercase on all inputs
                e.target.value = formatter(e.target.value)
                newValues[colAccessor] = e.target.value;
                console.log('value shown', newValues);
                setNewValues(newValues);
            }}
        />
    )
}
export { idGetter, decimal_2, decimalChecker, ApolloSelect, render_ApolloInput, ApolloInput,
    onRender_SKU10 }