import ApolloTable from "../../../../components/ApolloTable";
import { ApolloSelect } from '../../../../components/ApolloCells'
/**apollo-client*/
import { useQuery, useMutation, gql } from "@apollo/client";
/**css */
import './index.css';
import css from './index.module.css';
/**config */
import config from './config';
//
import { useState, useEffect } from 'react';


function filter(data, SKU02) {
    let result = [];
    data.forEach(element => {
        if ( element.SKU04.slice(0,2)===SKU02) {result.push(element)}
    });
    return result;
}

function Xzsp() {
    //category 
    const [parent, setParent] = useState('');
    //sku
    const [SKU02, setSKU02] = useState('--');
    const [SKU_root, setSKU_root] = useState('');
    const [SKU_suffix, setSKU_suffix] = useState('');
    //
    const [brand, setBrand] = useState('');
    const [title, setTitle] = useState('');
    //
    const [spec_1, setSpec_1] = useState('');
    const [spec_2, setSpec_2] = useState('');
    const [spec_3, setSpec_3] = useState('');
    //
    const [unit, setUnit] = useState('');
    //
    const [keywords, setKeywords] = useState('');
    //
    const formValue = {
        SKU_prefix: SKU02,
        SKU_root,
        SKU_suffix,
        brand,
        title,
        spec_1,
        spec_2,
        spec_3,
        unit,
        keywords
    }

    useEffect(()=>{
        console.log(SKU02 + SKU_root + SKU_suffix, formValue);
    },[        
        SKU02,
        SKU_root,
        SKU_suffix,
        brand,
        title,
        spec_1,
        spec_2,
        spec_3,
        unit,
        keywords
    ])
    //
    const [runMutation, { data, loading, error }] = useMutation(gql`
    mutation INSERT_PRODUCT($SKU_prefix: String! $SKU_root: String!, $SKU_suffix: String!, $brand: String!, $title: String!, $spec_1: String!, $spec_2: String!, $spec_3: String!, $unit: String!, $keywords: String!){
        insertProduct(SKU_prefix: $SKU_prefix, SKU_root: $SKU_root, SKU_suffix: $SKU_suffix, brand: $brand, title: $title, spec_1: $spec_1, spec_2: $spec_2, spec_3: $spec_3, unit: $unit, keywords: $keywords){
            id
            id_product
            SKU10
            brand
            title
            spec_1
            spec_2
            spec_3
            unit
            keywords
            msrp
        }
    }`, {

    });
    //
    const [renderImgPreview, setRenderImgPreview] = useState(<></>);
    const [imgsToUpload, setImgsToUpload] = useState([]);

    const useFilter = (data) => { //console.log(SKU02);
        return filter(data, SKU02);
    }
    function imgPreview(e) {
        const {length, ...fileKeys} = e.target.files;
        const files = Object.values(fileKeys);
        setImgsToUpload(files);

        const imgs = files.map( (file,index) => {
            return <img key={index} src={URL.createObjectURL(file)} alt={`${index}`} style={{width: '50%'}}/>
        })
    
        setRenderImgPreview(imgs);
    }
    if (loading) return <p>loading...</p>; 
    if (error) return console.log(error);
    if (data) { return <p>{JSON.stringify(data)}</p> };
    return(
        <div className={'Xzsp_container'}>
            <div className={'Xzsp_container0'}>
            <div className={css.form}>
                <p>Category</p>
                <div className={css.form_row}>
                    <ApolloSelect
                        fontSizePx={'16'}
                        defaultValue={''}
                        onChange={(e)=>{setParent(e.target.value)}}
                        GQL={gql`query CATEGORYs {
                            categorys(selection: "all") {
                                id
                                id_category
                                parent
                            }
                        }`}
                        accessors={['categorys', ['parent']]}
                        valuePicker={['parent']}
                    />
                    <ApolloSelect
                        fontSizePx={'16'}
                        defaultValue={''}
                        onChange={(e)=>{setSKU02(e.target.value); console.log(e.target.value)}}
                        GQL={gql`query BATCH_CODEs {
                            categorys(selection: "${parent}" ) {
                                id
                                id_category
                                child
                                SKU_prefix
                            }
                        }`}
                        accessors={['categorys', ['child']]}
                        valuePicker={['SKU_prefix']}
                    />
                </div>

                <p>SKU</p>
                <div className={css.form_row}>
                    <span className={css.font}>{SKU02}</span>
                    <span>&nbsp;+&nbsp;</span>
                    <input className={css.font+' '+css.sku34} type={'text'} minLength={2} maxLength={2} placeholder={'--'} onChange={(e)=>{e.target.value=e.target.value.toUpperCase(); setSKU_root(e.target.value)}}/>
                    <span>&nbsp;+&nbsp;</span>
                    <input className={css.font+' '+css.sku567890} type={'text'} minLength={6} maxLength={6} placeholder={'------'} onChange={(e)=>{e.target.value=e.target.value.toUpperCase(); setSKU_suffix(e.target.value)}}/>
                    <span>&nbsp;=&nbsp;</span>
                    <span className={css.font}>{SKU02 + SKU_root + SKU_suffix}</span>
                </div>

                <p>Brand</p>
                <div className={css.form_column}>
                    <input className={css.font} type={'text'} placeholder={'brand'} onChange={(e)=>{setBrand(e.target.value)}} />
                </div>

                <p>Title</p>
                <div className={css.form_column}>
                    <input className={css.font} type={'text'} placeholder={'title'} onChange={(e)=>{setTitle(e.target.value)}}  />
                </div>

                <p>Specs</p>
                <div className={css.form_column}>
                    <input className={css.font} type={'text'} placeholder={'spec 1'} onChange={(e)=>{setSpec_1(e.target.value)}} /> <br/>
                    <input className={css.font} type={'text'} placeholder={'spec 2'} onChange={(e)=>{setSpec_2(e.target.value)}} /> <br/>
                    <input className={css.font} type={'text'} placeholder={'spec 3'} onChange={(e)=>{setSpec_3(e.target.value)}} />
                </div>

                <p>Unit</p>
                <div className={css.form_row}>
                    <select className={css.font} onChange={(e)=>{setUnit(e.target.value)}} >
                        <option value={""}></option>
                        <option value="piece">piece</option>
                        <option value="pack">pack</option>
                        <option value="set">set</option>
                    </select>
                </div>

                <p>Keywords</p>
                <div className={css.keywords}>
                    <textarea className={css.font+' '+css.textarea}  cols="41" placeholder={'keyword, use comma, to seperate, english comma, only'} onChange={(e)=>{setKeywords(e.target.value)}} />
                </div>

                <br/>
                <input type='file' accept='image/*' multiple onChange={imgPreview}/>
                    <div className={css.row}>{renderImgPreview}</div>
                </div>
                <div>
                    <button className={css.form_confirm} onClick={()=>{
                        submitForm(imgsToUpload, formValue);
                        runMutation({variables:formValue});
                    }}> CONFIRM </button>
                </div>
            </div>
            <div className={'Xzsp_container1'}>
                <ApolloTable
                    cssPrefix={'Xzsp'} 
                    query={config.parent.query} 
                    columns={config.parent.columns} 
                    table={config.parent.table}
                    dataModifier={useFilter}
                >
                    <ApolloTable 
                        cssPrefix={'Xzsp0'} 
                        query={config.child0.query} 
                        columns={config.child0.columns} 
                        table={config.child0.table}
                        variables={config.child0.variables}
                    />
                </ApolloTable>
            </div>
        </div>
    )
}

export default Xzsp;

function submitForm(files, formValue) {
    let {
        SKU_prefix,
        SKU_root,
        SKU_suffix,
        brand,
        title,
        spec_1,
        spec_2,
        spec_3,
        unit,
        keywords
    } = formValue;
    const SKU10 = SKU_prefix + SKU_root + SKU_suffix;
    
    SKU_prefix = SKU_prefix.replace(/\s+/g, '');
    SKU_root = SKU_root.replace(/\s+/g, '');
    SKU_suffix = SKU_suffix.replace(/\s+/g, '');
    brand = brand.replace(/\s+/g, '');
    title = title.replace(/\s+/g, '');
    spec_1 = spec_1.replace(/\s+/g, '');
    spec_2 = spec_2.replace(/\s+/g, '');
    spec_3 = spec_3.replace(/\s+/g, '');
    unit = unit.replace(/\s+/g, '');
    keywords = keywords.replace(/\s+/g, '');

    if (files.length === 0) { return alert('upload at least 1 image'); }
    if ( SKU_prefix.length === 0 || SKU_prefix === '--' ) { return alert('pick a category'); }
    if ( SKU_root.length < 2 ) { return alert('fix SKU'); }
    if ( SKU_suffix.length < 6 ) { return alert('fix SKU'); }
    if ( brand.length === 0 ) { return alert('add brand'); }
    if ( title.length === 0 ) { return alert('add title'); }
    if ( spec_1.length === 0 && spec_1.includes(": ") ) { 
        const confirm = window.confirm('spec 1 not assigned. are you sure? \n\nyes = [OK] \nNO = [cancel]');
        if ( !confirm ) { return };
    }
    if ( spec_2.length === 0 && spec_1.includes(": ") ) { 
        const confirm = window.confirm('spec 2 not assigned. are you sure? \n\nyes = [OK] \nNO = [cancel]');
        if ( !confirm ) { return };
    }
    if ( spec_3.length === 0 && spec_1.includes(": ") ) { 
        const confirm = window.confirm('spec 3 not assigned. are you sure? \n\nyes = [OK] \nNO = [cancel]');
        if ( !confirm ) { return };
    }
    if ( unit.length === 0 ) { return alert('pick a unit'); }
    if ( keywords.length === 0 ) { 
        const confirm = window.confirm('no keywords assigned. are you sure? \n\nyes = [OK] \nNO = [cancel]');
        if ( !confirm ) { return };
    }
    //
    const confirm = window.confirm('have you double checked? \n\nyes = [OK] \nNO = [cancel]');
    if ( !confirm ) { return };
    //upload images
    const gallery = new FormData();
    gallery.append('sku', SKU10);
    files.forEach(file => gallery.append('imgs', file) );
    // my endpoint should know who sends the file
    //formData.append('identify', inputTarget.dataSet.sender);
    uploadImages(gallery);
    //upload product data
}

function uploadImages(formData) {
        
    const url = '/api/product/upload/';
    const options = {
        method: 'POST',
        'Content-Type': undefined,//'multipart/form-data;',
        body: formData
    };

    fetch(url, options)
        .then((response) => {
        if(!response.ok) {
            throw Error(response.statusText);
        }

            return response.json();
        })
        .then((data) => {
            console.log('success', data?.result);
            window.alert(data?.result);
        })
        .catch((error) => {
            console.error(error);
        })
}