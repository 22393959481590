/**css */
import css from './index.module.css';
/**apollo-client*/
import { gql, useQuery } from "@apollo/client";
/**react */
import { useState, useRef } from 'react';
import { useReactToPrint } from 'react-to-print';

/**GQL */
const HOLDERLABELS = gql`
    query P($id_holders: [ID], $names:[String], $limit: Int) {
        holderLabels(id_holders:$id_holders, names:$names, limit:$limit) {
            id
            id_holder
            name
        }
    }
`;

/**
 * this renders twice. weird
 * @param {*} props 
 * @returns 
 */
const Paper = ( props ) => {
    const { size, grid, variables } = props;
    
    
    const { loading, error, data } = useQuery(HOLDERLABELS, { variables, });

    if (variables===undefined) return <div className={css['page-A4']}></div>;
    if (loading) return <p>Loading...</p>;
    if (error) return <p>{`${error}`}</p>;
    //console.table(data);
    let holderLabels = [...data.holderLabels];
    //console.log(holderLabels);

    const [columns, rows] = grid.split('x');
    const labelsPerPage = columns*rows;

    let book = [];
    let page = 0;
    while ( holderLabels.length > 0) {
        book[page]=[];
        //if holderLabels cannot be divisable without remainer forcing a full page of labels will break program.
        let labelsToRender = holderLabels.length >= labelsPerPage? labelsPerPage : holderLabels.length;
        for (let i = 0; i < labelsToRender; i++) {
            book[page].push( holderLabels.shift() );
        }
        page++;

    }

    
    return (
        <div>
            {book.map( (pageLabels, index) => { console.log(pageLabels);
                return <PageGenerator key={index} size={size} grid={grid}>{{pageLabels}}</PageGenerator>
            })}
            
        </div>
    )
    
}

const PageGenerator = ( props ) => {
    const { size, grid, children } = props;
    const { pageLabels } = children;
    return (
        <div className={css[`page-${size}`]}>
            <div className={css[`safe_area-${size}-${grid}`]}>
                {pageLabels.map( label => { 
                    return( 
                        <Label key={label.id} size={size} grid={grid}>
                            { {
                                id_holder: label.id_holder,
                                name: label.name
                            } }
                        </Label> 
                    )
                })}
            </div>
        </div>
    )  
}

const Label = ( props ) => {
    //const { id_holder, name } = props;
    const { size, grid, children } = props;
    const { id_holder, name } = children;
    const qrText = `holder;${id_holder}`;

    let qrSize = '';
    switch (`${size}-${grid}`) {
        case 'A4-2x2': qrSize = '250x250'; break;
        case 'A4-4x11': qrSize = '80x80'; break;
        default: qrSize = '250x250';
    }
    let src = `https://chart.googleapis.com/chart?chs=${qrSize}&cht=qr&chld=H|0&chl=${qrText}`;
    return (
        <div className={css[`label-${size}-${grid}`]}>
            <div className={css[`name-${size}-${grid}`]}>{`${name[0]} ${name[1]}${name[2]}`}</div>
            <div className={css[`qr_code-${size}-${grid}`]}>
                <div className={css[`qr_text-${size}-${grid}`]}>{qrText}</div>
                <img className={css[`qr_img-${size}-${grid}`]} src={src}/>
                <div className={`${css[`qr_text-${size}-${grid}`]} ${css.hidden}`}>{qrText}</div>
            </div>
        </div>
    )
}

const Form = (props) => {
    const { passUp } = props;
    const [paperFormat, setPaperFormat] = useState('');
    const [manualId, setManualId] = useState('');
    const [autoId, setAutoId] = useState(0);

    const inputOnChange = (field, value) => {
        switch (field) {
            case 'paperFormat': setPaperFormat(value); break;
            case 'manualId': setManualId( value.replace(/\s+/g, '') ); break;
            case 'autoId': setAutoId( parseInt(value) ); break;
            default: break;
        }
    }
    const passUpHost = () => { //one object
        passUp( {paperFormat, manualId, autoId} );
    }
    return(
        <div>
            <p>Select a format</p>
            <select className={css.font} defaultValue='' onChange={e => inputOnChange('paperFormat', e.target.value)}>
                <option value='' disabled hidden>{'Choose here'}</option>
                <option value='A4  2x2'>{`A4  2x2`}</option>
                <option value='A4  4x11'>{`A4  4x11`}</option>
            </select>
            <p>
                Manually select id_holder(s): <br/>
                duplicates will merge
            </p>
            <input className={css.font} type="text" placeholder="e.g. 1536, 63, 264-985" onChange={e => inputOnChange('manualId', e.target.value)}/>
            <p>How many labels do you need?</p>
            <input className={css.font} type="number" placeholder="e.g. 88" onChange={e => inputOnChange('autoId', e.target.value)}/>
            <p></p>
            <button onClick={passUpHost} className={css.general_confirm_button}>generate</button>
        </div>
    )
}

function Lybq(props) {
    const [size, setSize] = useState();
    const [grid, setGrid] = useState();
    const [labels, setLabels] = useState();

    const formSubmit = ( formResponseObject ) => {
        //recover params from <Form/> component
        let { paperFormat, manualId, autoId } = formResponseObject; //show user the specs getting passed RAW DATA HERE. PROCESSED DATA IS NOW BEING LOGGED //console.log('label spec', { paper, manualId, autoId });
        //turn manualId and autoId into matching variables to graphQL. filter out id_holder and name first. then change autoId to limit
        const manualId__toArray = manualId.split(',');
        //name has to be ChineseChar. checking if parseInt(element) is NaN works because we use the minus '-' symbol to indicate range.
        const names = manualId__toArray.filter(element => isNaN( parseInt(element) ) );
        let id_holder__RawData = manualId__toArray.filter(element => !isNaN( parseInt(element) ) );
        const id_holders = id_holder__RawData.map(element => {
            //range is represented as '223-439' or '439-223'. 
            const rangeIndicator = element.split('-');
            switch (rangeIndicator.length) {
                case 1: return element;
                case 2: 
                    rangeIndicator.sort( (a, b) => a - b );
                    let [min, max] = rangeIndicator;
                    let rangeAllNumbers = [];
                    do {
                        //always push String representation of an Int, the first min is always String
                        rangeAllNumbers.push( min.toString() );
                        //always increment as Int
                        min = parseInt(min)+1;
                    } while (min<=max);
                    return rangeAllNumbers;
                default: console.log('code breaking input from <Form/>'); return undefined;
            }
        });
        //seperator is double spaces. grid at position [1] or the third spot is use single space as seperator
        const [sizePicked, gridPicked] = paperFormat.split('  ');
        console.log('Label.specs', { size:sizePicked, grid:gridPicked, id_holder:id_holders.flat(), names, limit:autoId });
        //pass values from <Form/> to <Paper/>
        //pass default value if format was not selected..change this out after implementing valid input check
        if (sizePicked===undefined || gridPicked===undefined) { 
            setSize('A4');
            setGrid('2x2');
        } else {
            setSize(sizePicked);
            setGrid(gridPicked);
        } 
        setLabels({id_holders:id_holders.flat(), names, limit:autoId});
    }

    /**for react-to-print */
    const componentRef = useRef();
    const handlePrint = useReactToPrint({
      content: () => componentRef.current,
    });

    return(
        <div className={css.container}>
            <div className={css.container_form}>
                <Form passUp={formSubmit}/>
            </div>
            
            <div className={css.container_book}>
                {/** div wrapper to use ref. ref only works on Class Components or HTML elements*/}
                <div ref={componentRef} >
                    <Paper size={size} grid={grid} variables={labels}/>
                </div>
                <button onClick={handlePrint} className={css.general_confirm_button}>SAVE TO PDF</button>
            </div>
        </div>
    )
}

export default Lybq;