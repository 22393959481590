import ApolloTable from "../../../../components/ApolloTable";
/**css */
import './index.css';
/**config */
import config from './config';
/**apollo-client*/
import { CallProcedure } from "../../../../components/ApolloButtons";
/** */
import { gql, useQuery } from "@apollo/client";

/**even though config querys for correct status.title 
 * but after user does mutation, new mutated data will be append to cache, causing duplicates
 * same data will sit in SHIPMENT(arrived || not arrived) BUT they show correct status and wont see duplicates in each table if updateQuery name is correct
 * 
 * turn this filter on for a second layer (right before frontend) filter
 * turn off when its hurting performance
 * */
/*const filter = (arr) => {
    let result = [];
    arr.forEach(element => {
        if ( element.status.title==='arrived') {result.push(element)}
    });
    return result;
}*/

function Rcb() {
    return(
        <div className={'Rcb_container'}>
            <div className={'Rcb_container_0'}>
                <div className={'Rcb_container_0_inner'}>
                    <ApolloTable
                        cssPrefix={'Rcb'}
                        query={config[0].parent.query} 
                        columns={config[0].parent.columns} 
                        table={config[0].parent.table} 
                        //dataModifier={filter}
                        exposedChildren={true}
                    >
                        <ApolloTable 
                            query={config[0].child0.query} 
                            columns={config[0].child0.columns} 
                            table={config[0].child0.table} 
                            variables={config[0].child0.variables} 
                        />
                    </ApolloTable>
                </div>
                <CallProcedure
                    className={'Rcb_confirm_button'}
                    variables={()=>{
                        const answer = window.confirm(`
                            About to update.
                            
                            Are you sure?
                        `) 
                        return {variables:{confirm:answer}}
                    }}
                    GQL = { gql`mutation CONFIRM_RESTOCK_STASH_PENDING($confirm: Boolean!){
                        restock_Stash(confirm: $confirm) {
                            id
                            id_stash_pending
                            product {
                                id
                                id_product
                                SKU10
                                brand
                                title
                                spec_1
                                spec_2
                                spec_3
                                unit
                            }
                            holder {
                                id
                                id_holder
                                name
                            }
                            qty
                            destination {
                                id
                                id_destination
                                title
                            }
                        }
                    }`}
    /*
    This part was supposed to update CCB but it doesnt work               
                    accessor = {'remove_Stash'}
                    updateQuery = {gql`query STASH_PENDINGs_noholder{
                        stashPendings(fk_holder: null, inOut: "in") {
                            id
                            id_stash_pending
                            product {
                                id
                                id_product
                                SKU10
                                brand
                                title
                                spec_1
                                spec_2
                                spec_3
                                unit
                            }
                            holder {
                                id
                                id_holder
                                name
                            }
                            qty
                            destination {
                                id
                                id_destination
                                title
                            }
                        }
                    }`}
                accessor_cacheData = {'stashPendings'}
    */
                >CONFIRM</CallProcedure>
            </div>
            <div className={'Rcb_container_1'}>
                <ApolloTable
                    cssPrefix={'Rcb'}
                    query={config[1].parent.query} 
                    columns={config[1].parent.columns} 
                    table={config[1].parent.table} 
                    //dataModifier={filter}
                >
                    <ApolloTable 
                        query={config[1].child0.query} 
                        columns={config[1].child0.columns} 
                        table={config[1].child0.table} 
                        variables={config[1].child0.variables} 
                    />
                </ApolloTable>
            </div>
        </div>
    )
}

export default Rcb;