import css from './index.module.css'
import { useState, useEffect } from 'react'
/**font awesome */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid, regular, brands } from '@fortawesome/fontawesome-svg-core/import.macro' // <-- import styles to be used
/**apollo-client*/
import { useQuery, useMutation, gql } from "@apollo/client";
import renderDraggableWindow from '../../../../components/DraggableWindow'

export function Notepad(props) {
    const {
        id_product,
        children //array
    } = props;

    useEffect(()=>{
        setKeywords(children)
    },[id_product])

    const [onEdit, setOnEdit] = useState(false);
    const [keywords, setKeywords] = useState(children);
    const [runMutation, { data, loading, error }] = useMutation(gql`
        mutation UPDATE_PRODUCT($id_product:ID!, $keywords: [String]){
            updateProduct(id_product:$id_product,keywords: $keywords){
                id
                id_product
                SKU10
                brand
                title
                spec_1
                spec_2
                spec_3
                unit
                keywords
                msrp
            }
        }`, {
    }); 

    //const keywords = data?.updateProduct.keywords || children;
/*
    useEffect(()=>{
        return data? setKeywords( data?.updateProduct.keywords ) : null;
    },[data])
*/
    return(
        <div 
            draggable={false}
            className = {css.notepad}
        >
            { 
                onEdit?  
                    <textarea 
                        className={css.textarea}
                        defaultValue={keywords.toString()}
                        onChange={(e)=>{ setKeywords(e.target.value.split(/,|，/)) }}
                        autoFocus
                        onFocus={(e)=>{
                            var val = e.target.value;
                            e.target.value = '';
                            e.target.value = val;
                        }}
                    />
                :
                    <div className={css.keywordsList}>
                        <ul> 
                            { keywords.map( (el,idx)=>{
                                if (idx===0) return <li key={idx} style={{listStyleType: 'star'}}>{el}</li>
                                return <li key={idx} style={{listStyleType: 'circle'}}>{el}</li>
                            })} 
                        </ul>
                    </div>
            }
            <div className={css.footer}>
                {
                    onEdit?
                        <><button 
                            onClick={()=>{
                                setOnEdit(!onEdit);
                                runMutation({variables:{id_product: id_product, keywords: keywords}});
                            }}
                            style={{
                                backgroundColor:'green',
                            }}
                        >
                            <FontAwesomeIcon icon={solid('save')} inverse/>
                        </button>   
                        <button 
                            onClick={()=>{setOnEdit(!onEdit)}}
                            style={{
                                backgroundColor: 'red',
                            }}
                        >
                            <FontAwesomeIcon icon={solid('xmark')} inverse/>
                        </button></>
                    :
                        <button 
                            onClick={()=>{setOnEdit(!onEdit)}}
                            style={{
                                backgroundColor: 'rgb(241, 226, 13)',
                            }}
                        >
                            <FontAwesomeIcon icon={solid('pen-to-square')}/>
                        </button>
                }
            </div>
        </div>
    )
}

export default Notepad

export function renderKeywordNotepad(value, rowData) {  
    //console.log(value) 
    return (<>
        <span>{`${value[0]} `}</span>
        <button
            className = {'tinyBtnIcon'}
            onClick = { async() => { 
                renderDraggableWindow({
                    spawnTitle: `${rowData.SKU10}`,
                    spawnContent: <Notepad id_product={rowData.id_product}>{value}</Notepad>,
                    type: 'KEYWORDS',
                    spawnPosition: [800, 200],
                });
            }}
        ><FontAwesomeIcon icon={solid('file-lines')}/>
        </button>
    </>)
}