/**css */
import css from './index.module.css';
/**font awesome */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid, regular, brands } from '@fortawesome/fontawesome-svg-core/import.macro' // <-- import styles to be used
/** */
import { domain } from '../../context/variables'
/**apollo-client*/
import { gql, useQuery, useMutation } from "@apollo/client";

function loggingOut() {
    localStorage.clear();
}

export function UserPanel(props) {
    const {

    } = props;

    const { loading, error, data } = useQuery(
        gql`query USER {
            user {
                id
                id_user
                username
                first_name
                last_name
                alt_name
            }
        }`
    );
    if (loading) return <p>Loading...</p>;
    if (error) return <p>{`${error}`}</p>; 
    console.log(data);
    const noUserData = {
        username: 'username', 
        first_name:'first_name',
        last_name: 'last_name',
        alt_name: 'alt_name' 
    };
    const { username, first_name, last_name, alt_name } = data? data.user : noUserData;
    return(<>
        <div className={css.avatarContainer}>
            <img
            className={css.avatar}
            src='https://t3.ftcdn.net/jpg/03/91/19/22/360_F_391192211_2w5pQpFV1aozYQhcIw3FqA35vuTxJKrB.jpg'
            alt='Avatar'
            />
        </div>
        <div className={css.userActionsContainer}>
            <a className={css.userDisplayname} href='#'>{`${first_name} ${last_name}`}</a>
            <a className={css.logOut} href={`${domain}`} onClick={loggingOut}>log out</a>
        </div>
    </>)
}

export default UserPanel