/**css */
import css from './index.module.css';
/**apollo-client*/
import { useQuery, useMutation } from "@apollo/client";
/**react */
import React, { useState, useEffect } from 'react';
/**helper */
import { colDataReducer, spaceToCamel } from '../../helper';

//import { client } from '../../index';

/**
 * component takes in 
 *  data - render ready object or graphQL query
 *  children - a <Table/>
 *  columns - array of objects
 *  table - array of objects
 * 
 * @param {*} props 
 * @returns 
 */
function ApolloTable(props){
    const { cssPrefix, query, columns, table, children, dataModifier, variables/** */, exposedChildren } = props;
    const { loading, error, data } = useQuery(query,{variables});
    if (loading) return <p>Loading...</p>;
    if (error) return <p>{`${error}`}</p>; //console.log(data)
    const rawData = table.accessor.split('.').reduce( (accumulator, value) => {
        return accumulator[value];
    }, data); //eval(`data.${table.accessor}`);
    let dataToRender = dataModifier( rawData ); //modifies the obj for render
    //setDataToRender(dataModifier( rawData ));

    return (
        <table className={css.table+' '+`${cssPrefix}_table`}>
            <thead>
                <tr>
                    <th className={css.th_in_thead_expander+' '+`${cssPrefix}_th_expander`}></th>
                    {columns.map( col => { 
                        return (
                            <th className={css.th_in_thead+' '+`${cssPrefix}_th_${spaceToCamel(col.header)}`}key={col.accessor}>
                                {col.header}
                            </th>
                        )
                    })}
                    <th className={css.th_in_thead+' '+`${cssPrefix}_th_actions`}></th>
                </tr>
            </thead>
            <tbody>
                {dataToRender.map( rowData => {
                    return( <ApolloTr exposedChildren={exposedChildren} key={rowData.id} rowData={rowData} columns={columns} table={table} children={children} cssPrefix={cssPrefix}/> )
                })}
                {table.dataAdder? 
                    <ApolloTrDataAdder columns={columns} table={table}/>
                :
                    null
                }
            </tbody>
            <tfoot>
            {table.footer? 
                <tr className={css.tr_in_tfoot}>
                    <th className={css.th_in_tfoot_expander+' '+`${cssPrefix}_th_expander`}></th>
                    {columns.map( col => { 
                        return (
                            <th className={css.th_in_tfoot+' '+`${cssPrefix}_th_${spaceToCamel(col.header)}`} key={col.accessor}>
                                {col.header}
                            </th>
                        )
                    })}
                    <th className={css.th_in_tfoot+' '+`${cssPrefix}_th_actions`}></th>
                </tr>
            :
                null
            }
            </tfoot>
        </table>
    )

}

/** 
 * showChildren
 *  this determines if child row should be rendered
 *  an identical useState is mirrored in "Edit(改)" button
 * toggleEdit
 *  this determins if cell is in its "editable" state. this is based on "onEdit" from configs
 *  the setter function will be forwarded to actionButtons durning render 
 * newValues
 *  this mirrors the editable values + id. 
 *  is forwarded to actionButtons durning render 
 * ^during button render passes : rowData, onClick_toggleEdit
 * ^col in on edit state passes : cellValue, [col.accessor, newValues, setNewValues]
*/
const ApolloTr = ( props ) => {
    const { rowData, table, columns, children, cssPrefix, exposedChildren } = props;
    //nest table
    const [showChildren, setShowChildren] = useState(exposedChildren);
    //toggle editable cells
    const [toggleEdit, setToggleEdit] = useState(false);
    //
    const [newValues, setNewValues ] = useState({id:rowData.id});
    //
    const onClick_showChildren = () => {
        setShowChildren(!showChildren);
    };
    //
    const onClick_toggleEdit = () => {
        const nextToggle = !toggleEdit;
        setToggleEdit(nextToggle);
        //console.log(newValues);
        //setNewValues(newValues);
        return {nextToggle, newValues};
    }
    //some cases setNewValues will ignore first trigger from config file
    useEffect(() => {
        //console.log('current value', newValues);
        setNewValues(newValues);
    },[toggleEdit]);//toggleEdit

    return(<>
        <tr className={css.tr_in_tbody}>
            <td className={css.td_in_tbody}> 
                {children===undefined?
                    <button className={css.toggler}>-</button>
                :
                    <button className={css.toggler} onClick={onClick_showChildren}>{showChildren? '=' : '>'}</button>
                }              
            </td>
            {columns.map( col => {
                //get needed data for the current cell
                const renderValue = colDataReducer( col.accessor.split('.'), rowData);
                return (
                    <td className={css.td_in_tbody+' '+`${cssPrefix}_td_${spaceToCamel(col.header)}`} key={col.accessor}>
                        {!toggleEdit?
                            //return formatted data
                            col.onRender(col.formatter(renderValue), rowData)
                        :
                            col.onEdit?
                                //pass formatter to onEdit renderer in config file
                                col.onEdit( renderValue, [col.accessor, newValues, setNewValues], col.formatter )
                            :
                                //return formatted data
                                col.onRender(col.formatter(renderValue), rowData)
                        }
                    </td> 
                )
            })}
            <td className={css.td_in_tbody+' '+css.action_button_group+' '+`${cssPrefix}_td_actions`}>
                {table.actions? 
                    table.actions.map( (action, index) => {
                        return action(index, rowData, onClick_toggleEdit);
                    })
                :
                    null
                }
            </td>
        </tr>
        {children===undefined?
            null
        :
            showChildren? 
                <tr>
                    <td></td>
                    <td colSpan='99'>{
                        (() => {
                            const getVariables = children.props.variables.reduce( (accumulator, value) => {
                                const keys = value.split('.');
                                const key = value.split('.')[keys.length-1];
                                const data = colDataReducer( keys, rowData, 'null' );
                                return`${accumulator}"${key}":"${data}",` 
                            },'');
                            const setVariables = `{"variables":{${getVariables.slice(0,-1)}}}`; console.log( setVariables );
                            const variables = JSON.parse(setVariables);
                            //console.log(variables);
                            return React.cloneElement(children, variables, children.props.children)
                        })()
                    }</td>
                </tr> 
            : 
                null
        }
    </>)

}
const ApolloTrDataAdder = (props) => {
    const {
        columns,
        table //table.dataAdder.actions
    }=props;

    const [dataToAdd, setDataToAdd] = useState({});
    const onClick_getDataToAdd = () => {
        return dataToAdd
    }
    /*
    useEffect(()=>{
        setDataToAdd(dataToAdd);
    },[dataToAdd])
    */
    return (      
        <tr className={css.tr_in_tbody_dataAdder}>
            <th className={css.th_in_tbody_dataAdder}>+</th>
            {columns.map( (col) => { 
                return (
                    <th key={col.accessor} className={css.th_in_tbody_dataAdder}>
                    {col.onEdit? 
                        col.onEdit( '', [col.accessor, dataToAdd, setDataToAdd], col.formatter )
                    :
                        null
                    }
                    </th>
                )
            })}
            <th className={css.th_in_tbody_dataAdder}>
                {table.dataAdder.actions.map( (action, index) => {
                    return action(index, dataToAdd, onClick_getDataToAdd);
                })}
            </th>
        </tr>
    )
}
export default ApolloTable;

ApolloTable.defaultProps = {
    dataModifier: (rawData) => { return rawData },
    exposedChildren: false
}