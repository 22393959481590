/**css */
import css from './index.module.css';
/**react */
import { useState, useEffect } from 'react';
/**react dom*/
import React from 'react';
import ReactDOM from 'react-dom';
/**font awesome */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid, regular, brands } from '@fortawesome/fontawesome-svg-core/import.macro' // <-- import styles to be used
/*apollo-client*/
import { ApolloProvider } from "@apollo/client";
import { client } from '../../index'
//
import renderDraggableWindow_History from './renderDraggableWindow_History'

export function DraggableWindow(props){
    const {
        spawnPosition,
        spawnContent,
        spawnTitle,
        spawnSize,
        expandSize,
        expanded,
        type
    } = props
/*
    const onMouseDown_resize = (e) => {
        const x = e.clientX;
        const y = e.clientY;
        //const initVal = [x,y];
        initResize(e);
        function initResize(e) {
            window.addEventListener('mousemove', getResizeVal, false);
            window.addEventListener('mouseup', setResize, false);
        }
        
        function getResizeVal(e) {
            const width = e.clientX-y;
            const height = e.clientY-x;
            setSize([size[0]+width,size[1]+height]);
        }

        function setResize(e) {
            window.removeEventListener('mousemove', getResizeVal, false);
            window.removeEventListener('mouseup', setResize, false);
        }
    }
*/
    const [size, setSize] = useState(expanded? expandSize : spawnSize);
    /*useEffect(()=>{
        console.log(size)
    },[size])*/
    const onMouseDownHandler_move = (e) => {
        const clickedElement = e.target.getBoundingClientRect();
        //get relative position of mouse and dragster
        const x = e.clientX - clickedElement.x;
        const y = e.clientY- clickedElement.y;
        setPositionOffset([x,y]);
    }

    const onDragHandler_move = (e) => {
        //console.log(e)
        const [offsetX, offsetY] = positionOffset;
        setPosition([
            e.clientX - offsetX,
            e.clientY - offsetY
        ]); 
    }

    const [positionOffset, setPositionOffset] = useState([0,0]);
    const [position, setPosition] = useState(spawnPosition);


    const [isExpanded, setIsExpanded] = useState(expanded);
    const onClick_resize = () => {
        const nextState = !isExpanded;
        if (nextState) { 
          setSize(isMinimized? [expandSize[0],20] : expandSize); 
        } else {
          setSize(isMinimized? [spawnSize[0],20] : spawnSize);
        }
        setIsExpanded(nextState);
    }
    const [isMinimized, setIsMinimized] = useState(false);
    const onClick_minimized = () => {
        const nextState = !isMinimized;
        if (nextState) { 
          setSize([size[0],20]);
        } else {
          setSize(isExpanded? expandSize : spawnSize);
        }
        setIsMinimized(nextState);
    }
    /**dynamic CSS */
    const divStyle = {
      position: 'inherit',
      width: `${size[0]}px`,
      height: `${size[1]}px`,
      left:`${position[0]}px`,
      top:`${position[1]}px`,
    };
    const contentStyle = {
        height: `${size[1]-20}px`,//`calx(${size[1]}px - 20px)`,
        display: isMinimized? 'none' : 'block',
    };
    /*const contentDisplayStyle = {
      display: isMinimized? 'none' : 'block',
    };*/
    return (
        <div 
            className={css.container}
            style={divStyle}
        >
            <div className={css.windowBar}>
                <button 
                    className={css.windowBtn+' '+css.exitBtn}
                    onClick={()=>{killDraggableWindow(type);}}
                > <FontAwesomeIcon icon={solid('times')} inverse/> 
                </button>

                <button 
                    className={css.windowBtn}
                    onClick={onClick_resize}
                > <FontAwesomeIcon icon={isExpanded? solid('compress') : solid('expand')} inverse/>
                </button>

                <div
                    className={css.windowDragBar}
                    onMouseDown={onMouseDownHandler_move} 

                    onDrag={onDragHandler_move} 
                    onDragEnd={onDragHandler_move}
                    
                    draggable
                >
                  <span className={css.windowTitlePrefix}>{type}</span>
                  <span className={css.windowTitle}>{spawnTitle}</span>
                </div>

                <button 
                    className={css.windowBtn+' '+css.minimizeBtn} //svg changes size... minimizeBtn doesnt work
                    onClick={onClick_minimized}
                > <FontAwesomeIcon icon={isMinimized? solid('greater-than') : solid('grip-lines')} inverse/>
                </button>
            </div>
            <div className={css.windowContent} style={contentStyle} draggable={false}>
                {spawnContent}
                {/*<button 
                    className={css.windowBtn+' '+css.resizeBtn}
                    onMouseDown={onMouseDown_resize}
                > <FontAwesomeIcon icon={solid('expand-arrows-alt')}/> 
                </button>*/}
            </div>
        </div>
    )
}
DraggableWindow.defaultProps = {
    spawnPosition: [700,150],
    spawnSize: [250,250],
    expandSize: [500,500],
    expanded: false
}

export function renderDraggableWindow(props){
    const {
        spawnPosition,
        spawnContent,
        spawnTitle,
        spawnSize,
        expandSize,
        expanded,
        type//dragster_IMAGE, dragster_SEARCH...etc
    } = props

    ReactDOM.render(
      <ApolloProvider client={client}>
        <React.StrictMode>
          <DraggableWindow
              spawnPosition = {spawnPosition}
              spawnContent = {spawnContent}
              spawnTitle = {spawnTitle}
              spawnSize = {spawnSize}
              expandSize = {expandSize}
              expanded = {expanded}
              type = {type}
          />
        </React.StrictMode>
      </ApolloProvider>,
        document.getElementById(`dragster_${type}`)
    );
}

export function killDraggableWindow(type){
    ReactDOM.render(
        null,
        document.getElementById(`dragster_${type}`)
    );
}

export { renderDraggableWindow_History };

export default renderDraggableWindow