import { Edit, Delete } from '../../../../components/ApolloButtons';
/**helper */
import { paramToVariables, intStrToDemicalStr } from '../../../../helper';
/** */
import { gql } from "@apollo/client";
/** */
import { idGetter, decimalChecker, ApolloSelect } from '../../../../components/ApolloCells'
/**Apollo Columns */
import { onRender_SKU10 } from '../../../../components/ApolloCells'

/**GQL */
const parent = {
    query: gql`query Purchase {
        purchases(isArrived: false) {
            id
            id_purchase
            inserted
            updated
            cost_per_unit
            currency_paid
            conversion_rate
            shipment {
                id
                id_shipment
                batch_code
                status {
                    id
                    id_status
                    title
                }
                departure
                est_arrival
                act_arrival
            }
            product {
                id
                id_product
                SKU10
                brand
                title
                spec_1
                spec_2
                spec_3
                qty_current
                unit
                category {
                    id
                    parent
                    child
                }
                need_purchase
            }
            qty_purchased
            total_cost
            supplier {
                id
                id_supplier
                company_name
            }
            user {
                id
                id_user
                alt_name
            }
        }
    }`,
    table: {
        footer: true,
        
        accessor: 'purchases',
        actions: [ //action button null. what do in table generator. ****param is nest what do? *****
        /*    (index, rowData) => {
                return (
                    <采
                        key={index}
                        variables = {['product.id_product']}
                        rowData = {rowData}
                        updateQuery={ gql`query Purchase {
                            purchases {
                                id
                                id_purchase
                                shipment {
                                    id
                                    id_shipment
                                    batch_code
                                    departure
                                    est_arrival
                                    act_arrival
                                }
                                product {
                                    id
                                    id_product
                                    SKU10
                                    brand
                                    title
                                    spec_1
                                    spec_2
                                    spec_3
                                    qty_current
                                    unit
                                    category {
                                        id
                                        parent
                                        child
                                    }
                                    need_purchase
                                }
                                qty_purchased
                                supplier {
                                    id
                                    id_supplier
                                    company_name
                                }
                            }
                        }`}
                    />
                )
            },
        */
            (index, rowData, onClick_toggleEdit) => {
                return (
                    <Edit
                        key={index}
                        onClick={onClick_toggleEdit}
                        GQL={ gql`mutation updatePurchase($id_purchase: ID!, $qty_purchased: Int, $cost_per_unit: Int, $conversion_rate: Int, $batch_code: String, $company_name: String, $currency_paid: String){
                            updatePurchase(id_purchase: $id_purchase, qty_purchased: $qty_purchased, cost_per_unit:$cost_per_unit, conversion_rate:$conversion_rate, batch_code:$batch_code, company_name:$company_name, currency_paid:$currency_paid) {
                                id
                                id_purchase
                                cost_per_unit
                                shipment {
                                    id
                                    id_shipment
                                    batch_code
                                    departure
                                    est_arrival
                                    act_arrival
                                }
                                product {
                                    id
                                    id_product
                                    SKU10
                                    brand
                                    title
                                    spec_1
                                    spec_2
                                    spec_3
                                    qty_current
                                    unit
                                    category {
                                        id
                                        parent
                                        child
                                    }
                                    need_purchase
                                }
                                qty_purchased
                                total_cost
                                conversion_rate
                                currency_paid
                                supplier {
                                    id
                                    id_supplier
                                    company_name
                                }
                                updated
                            }
                        }`}
                        variableParser={ (newValues)=>{
                            const variables = { 
                                variables: {
                                    id_purchase: newValues.id.split('_')[1],
                                    qty_purchased: +newValues.qty_purchased,
                                    cost_per_unit: +newValues.cost_per_unit,
                                    conversion_rate: +newValues.conversion_rate,
                                    batch_code: newValues['shipment.batch_code'], //could easily be id
                                    company_name: newValues['supplier.company_name'],
                                    currency_paid: newValues['currency_paid'],
                                }
                            };
                            return variables
                        } }
                    >{{edit:'改', abort:'撤', save:'存'}}</Edit>
                )
            },
            (index, rowData) => { //console.log(paramToVariables( ['id_purchase'], rowData))
                return (
                    <Delete
                        key={index}
                        variables = {paramToVariables( ['id_purchase'], rowData) }
                        GQL = { gql`mutation DELETE_PURCHASE($id_purchase: ID!){
                            deletePurchase(id_purchase: $id_purchase) {
                                id
                                id_purchase
                                shipment {
                                    batch_code
                                }
                                product {
                                    SKU10
                                }
                            }
                        }`}
                    >删</Delete>
                )
            }
        ]
    },
    columns: [
        {
            header: 'status',
            accessor: 'shipment.status.title',
            formatter: (value)=>{return value},
            onRender: (value)=>{return value},
        },
        {
            header: 'shipment',
            accessor: 'shipment.batch_code',
            formatter: (value)=>{return value},
            onRender: (value)=>{return value},
            onEdit: (value, [colAccessor, newValues, setNewValues], formatter) => { 
                newValues[colAccessor] = value;
                return (
                    <ApolloSelect
                        defaultValue={value}
                        onChange={(e)=>{
                            newValues[colAccessor] = e.currentTarget.value;
                            setNewValues(newValues);
                        }}
                        GQL={gql`query BATCH_CODEs {
                            shipments(status: "not here yet") {
                                id
                                id_shipment
                                batch_code
                            }
                        }`}
                        accessors={['shipments', ['batch_code']]}
                        valuePicker={['batch_code']}
                    />
                )
            }
        },
        /*{
            header: 'cat',
            accessor: 'product.category.parent',
            onRender: (value)=>{return value},
        },*/
        {
            header: 'cat',
            accessor: 'product.category.parent',
            formatter: (value)=>{return value},
            onRender: (value)=>{return value},
        },
        {
            header: 'SKU',
            accessor: 'product.SKU10',
            formatter: (value)=>{return value},
            onRender: (value)=>{return onRender_SKU10(value)},
        },
        {
            header: 'brand',
            accessor: 'product.brand',
            formatter: (value)=>{return value},
            onRender: (value)=>{return value},
        },
        {
            header: 'title',
            accessor: 'product.title',
            formatter: (value)=>{return value},
            onRender: (value)=>{return value},
        },
        {
            header: 'spec 1',
            accessor: 'product.spec_1',
            formatter: (value)=>{return value},
            onRender: (value)=>{return value},
        },
        {
            header: 'spec 2',
            accessor: 'product.spec_2',
            formatter: (value)=>{return value},
            onRender: (value)=>{return value},
        },
        {
            header: 'spec 3',
            accessor: 'product.spec_3',
            formatter: (value)=>{return value},
            onRender: (value)=>{return value},
        },
        {
            header: 'qty',
            accessor: 'qty_purchased',
            formatter: (value)=>{return value},
            onRender: (value)=>{return value},
            onEdit: (value, [colAccessor, newValues, setNewValues], formatter) => {
                newValues[colAccessor] = value;
                return  (
                    <input 
                        style={{
                            width: '100%',
                            boxSizing: 'border-box',
                            textAlign: 'right',
                            fontFamily: 'Inconsolata, monospace',
                            fontSize: '14px'
                        }}
                        min="0"
                        type={'number'} 
                        defaultValue={value}
                        onChange={(e)=>{
                            newValues[colAccessor] = parseInt(e.target.value);
                            setNewValues(newValues);
                        }}
                    />
                )
            }
        },
        {
            header: 'unit',
            accessor: 'product.unit',
            formatter: (value)=>{return value},
            onRender: (value)=>{return value},
        },
        {
            header: 'cpu',
            accessor: 'cost_per_unit',
            formatter: (value)=>{
                const stringVal = value.toString();
                return intStrToDemicalStr(stringVal, 2);
            },
            onRender: (value)=>{return value},
            onEdit: (value, [colAccessor, newValues, setNewValues], formatter) => {
                console.log(newValues);
                newValues[colAccessor] = value;
                return  (
                    <input
                        style={{
                            width: '100%',
                            boxSizing: 'border-box',
                            textAlign: 'right',
                            fontFamily: 'Inconsolata, monospace',
                            fontSize: '14px'
                        }}
                        
                        min="0.00"
                        step="0.01"
                        type={'number'} 
                        defaultValue={formatter(value)}
                        onChange={(e)=>{
                            //typeof e.target.value === 'string'
                            const restoreValue = decimalChecker( e.target.value );
                            newValues[colAccessor] = parseInt(restoreValue);
                            //console.log('value shown', newValues);
                            setNewValues(newValues);
                        }}
                    />
                )
            }
        },
        {
            header: 'ccy',
            accessor: 'currency_paid',
            formatter: (value)=>{return value.toUpperCase()},
            onRender: (value)=>{return value},
            onEdit: (value, [colAccessor, newValues, setNewValues], formatter) => {
                newValues[colAccessor] = value;
                return  (
                    /*<input
                        style={{
                            width: '100%',
                            boxSizing: 'border-box',
                            textAlign: 'center',
                            fontFamily: 'Inconsolata, monospace',
                            fontSize: '14px'
                        }}
                        type={'text'}
                        minLength={3}
                        maxLength={3}
                        defaultValue={formatter(value)}
                        onChange={(e)=>{
                            //force uppercase on all inputs
                            e.target.value = formatter(e.target.value)
                            newValues[colAccessor] = e.target.value;
                            console.log('value shown', newValues);
                            setNewValues(newValues);
                        }}
                    />*/
                    <select
                        defaultValue={formatter(value)}
                        onChange={(e)=>{
                            
                            e.target.value = formatter(e.target.value)
                            newValues[colAccessor] = e.target.value;
                            console.log('value shown', newValues);
                            setNewValues(newValues);
                        }}>
                        <option></option>
                        <option>RMB</option>
                        <option>USD</option>
                        <option>CAD</option>
                    </select>
                )
            }
        },
        {
            header: 'cvr',
            accessor: 'conversion_rate',
            formatter: (value)=>{
                const stringVal = value.toString();
                return intStrToDemicalStr(stringVal, 2);
            },
            onRender: (value)=>{return value},
            onEdit: (value, [colAccessor, newValues, setNewValues], formatter) => {
                newValues[colAccessor] = value;
                return  (
                    <input
                        style={{
                            width: '100%',
                            boxSizing: 'border-box',
                            textAlign: 'right',
                            fontFamily: 'Inconsolata, monospace',
                            fontSize: '14px'
                        }}
                        
                        min="0.00"
                        step="0.01"
                        type={'number'} 
                        defaultValue={formatter(value)}
                        onChange={(e)=>{
                            //typeof e.target.value === 'string'
                            const restoreValue = decimalChecker( e.target.value );
                            newValues[colAccessor] = parseInt(restoreValue);
                            //console.log('value shown', newValues);
                            setNewValues(newValues);
                        }}
                    />
                )
            }
        },
        {
            header: 'total',
            accessor: 'total_cost',
            formatter: (value)=>{
                const stringVal = value.toString();
                return intStrToDemicalStr(stringVal, 4).slice(0,-2);
            },
            onRender: (value)=>{return value},
        },
        {
            header: 'supplier',
            accessor: 'supplier.company_name',
            formatter: (value)=>{return value},
            onRender: (value)=>{return value},
            onEdit: (value, [colAccessor, newValues, setNewValues], formatter) => { 
                newValues[colAccessor] = value;
                return (
                    <ApolloSelect
                        defaultValue={value}
                        onChange={(e)=>{
                            newValues[colAccessor] = e.currentTarget.value;
                            setNewValues(newValues);
                        }}
                        GQL={gql`query SUPPLIERs {
                            suppliers {
                                id
                                id_supplier
                                company_name
                            }
                        }`}
                        accessors={['suppliers', ['company_name']]}
                        valuePicker={['company_name']}
                    />
                )
            }
        },
        {
            header: 'inserted',
            accessor: 'inserted',
            formatter: (value)=>{return value.substring(2, 10)},
            onRender: (value)=>{return value},
        },
        {
            header: 'updated',
            accessor: 'updated',
            formatter: (value)=>{return value.substring(2, 10)},
            onRender: (value)=>{return value},
        },
        {
            header: 'user',
            accessor: 'user.alt_name',
            formatter: (value)=>{return value},
            onRender: (value)=>{return value},
        },
    ],
}

/**GQL */
const child0 = {
    query: gql`
        query Shipment($id_shipment: ID) {
            shipments(id_shipment: $id_shipment) {
                id
                id_shipment
                departure
                est_arrival
            }
        }
    `,
    table: {
        accessor: 'shipments',
    },
    columns: [
        {
            header: 'departure',
            accessor: 'departure',
            formatter: (value)=>{return value.substring(0, 10)},
            onRender: (value)=>{return value},
        },
        {
            header: 'est_arrival',
            accessor: 'est_arrival',
            formatter: (value)=>{return value.substring(0, 10)},
            onRender: (value)=>{return value},
        }
    ],
    variables: ['shipment.id_shipment'],
}

const config = {
    parent,
    child0
}
export default config;