/**font awesome */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro' // <-- import styles to be used
/** */
import renderDraggableWindow from '../../components/DraggableWindow'
import FileIcon from '../Folder/FileIcon'
import Folder from '../Folder'
/** */ 
import { productImgLink, productFilesList } from '../../context/variables'
import { isNonEmptyArray } from '@apollo/client/utilities'

/*<a
    style={{color: 'black'}}
    href={`https://192.168.50.109:5000/products/${value}/image/${value}_00.jpg`} 
    target="_blank"
><FontAwesomeIcon icon={regular('images')}/>
</a>*/

const onRender_SKU10 = (value) => {
    return(<>
        <span>{` ${value} `}</span>
        <button
            className = {'tinyBtnIcon'}
            onClick = {async()=>{ 
                renderDraggableWindow({
                    spawnTitle: `${value}`,
                    spawnContent: 
                        <div 
                            style={{
                                height: '100%', 
                                width: '100%',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'space-between',
                                alignItems: 'center'
                            }}>
                            <img 
                                style={{height: 'calc(100% - 17px)', width: 'inherit', objectFit: 'contain'}}
                                src={await productImgLink(value)} 
                                alt={value}
                                draggable={false}
                            />
                            <div
                                style={{
                                    height: '17px', 
                                    width: '100%', 
                                    borderTop: 'black solid 1px',
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'space-between',
                                    alignItems: 'center'
                                }}
                            >
                                <button style={{ backgroundColor: 'transparent'}}>
                                    <FontAwesomeIcon icon={solid('caret-left')}/>
                                </button>
                                <button style={{ backgroundColor: 'transparent'}}>
                                    <FontAwesomeIcon icon={solid('caret-right')}/>
                                </button>
                                <button style={{ backgroundColor: 'transparent'}}>
                                    <FontAwesomeIcon icon={solid('download')}/>
                                </button>
                                <button
                                    style={{ backgroundColor: 'transparent'}}
                                    onClick = {async()=>{ 
                                        renderDraggableWindow({
                                            spawnTitle: `${value}`,
                                            spawnContent: <Folder folderRoot={value}/>,
                                            spawnSize: [400,400],
                                            spawnPosition: [300,300],
                                            type: 'FILES'
                                        });
                                    }}
                                >
                                    <FontAwesomeIcon icon={solid('folder')}/>
                                </button>
                            </div>
                        </div>,
                    type: 'IMAGE'
                });
            }}
        ><FontAwesomeIcon icon={regular('images')}/> {/*solid('circle-info')*/} {/*regular('images')*/}
        </button>

    </>)
}

export default onRender_SKU10;

/**
 *         
 *      <button className = {'tinyBtnIcon'}>
            <FontAwesomeIcon icon={solid('circle-info')}/>
        </button>

        <button
            className = {'tinyBtnIcon'}
        ><FontAwesomeIcon icon={solid('share-from-square')}/>
        </button>
 */