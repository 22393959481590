import './App.css';
import './pages/columns.css';
/** */
import { useState, useEffect } from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom';
/*components*/
import PageButton from './components/PageButton';
import SearchPanel from './components/SearchPanel';
import UserPanel from './components/UserPanel';
/**login */
import Login from "./pages/Login";
import SignUp from './pages/Login/SignUp.js';
import renderDraggableWindow, { killDraggableWindow } from './components/DraggableWindow';
import { useToken } from './context/auth';
/**content*/
import { RuTabs , Ru } from "./pages/Ru";
import { CangTabs , Cang } from "./pages/Cang";
import { CaiTabs , Cai } from "./pages/Cai";
/**test */

function App() {

  const { token, setToken } = useToken();
  //console.log('app',token);
  
  /*useEffect(()=>{
    if (token) { 
      return killDraggableWindow('LOGIN');
    }
  },[token]);*/

  if(!token) {
    return (
      <main className="container">
        <div className="panel-B" name="panel workspace">
          {renderDraggableWindow({
            spawnPosition: [350,150],//[0,0],
            spawnContent: 
              <Login setToken={setToken}>
                <SignUp/>
              </Login>,
            spawnTitle: ' \xa0 BNP warehouse',
            spawnSize: [280,400],
            expandSize: [350,450],//[1111,900],
            expanded: true,
            type: 'LOGIN'
          })}
        </div>

        <div className="panel-C" name="panel user">
          {/*<UserPanel/>*/}
        </div>

        <div className="panel-D" name="panel menu">

        </div>

        <div className="panel-E">
          {/*<SearchPanel/>*/}
        </div>
      </main>
    )
  }

  return (
    <main className="container">
      <BrowserRouter>
        <div className="panel-A" name="panel function">
        
          <Switch>
            <Route path="/ru">
              <RuTabs/>
            </Route>
            <Route path="/cang">
              <CangTabs/>
            </Route>
            <Route path="/cai">
              <CaiTabs/>
            </Route>
          </Switch>

        </div>

        <div className="panel-B" name="panel workspace">

          <Switch>
            <Route path="/ru">
              <Ru/>
            </Route>
            <Route path="/cang">
              <Cang/>
            </Route>
            <Route path="/cai">
              <Cai/>
            </Route>
          </Switch>

        </div>

        <div className="panel-C" name="panel user">
          <UserPanel/>
        </div>

        <div className="panel-D" name="panel menu">
          <PageButton type='master' to="/ru">入</PageButton>
          <PageButton type='master' to="/cang">仓</PageButton>
          <PageButton type='master' to="/cai">采</PageButton>
        </div>

        <div className="panel-E">
          <SearchPanel/>
        </div>

        <div className="panel-F" name="panel agile">

          <Switch>
            <Route path="/ru">
            </Route>
            <Route path="/cang">
            </Route>
            <Route path="/cai">
            </Route>
          </Switch>

        </div>
      </BrowserRouter>
    </main>
  );
}

export default App;
