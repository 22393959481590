//
import css from './index.module.css'
/**font awesome */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro' // <-- import styles to be used

function iconSelector(contentType) {
    switch( contentType.split('/')[0] ) {
        case 'image': return solid('file-image');
        case 'folder': return solid('folder');
        default: return solid('file');
    }
}

export function FileIcon(props) {
    const {
        onClick,
        dir,
        name,
        contentType,
    } = props;

    return (
        <div className={css.container}>
            <button className={css.iconButton} onClick={onClick}>
                <FontAwesomeIcon icon={iconSelector(contentType)}/>
            </button>
            <div className={css.iconName}>{name}</div>
        </div>
    )
}

export default FileIcon;