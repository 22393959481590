import { Delete, Edit, Update, Insert } from '../../../../components/ApolloButtons';
/**helper */
import { paramToVariables, hasOnlyDigits } from '../../../../helper';
/** */
import { gql, useQuery } from "@apollo/client";
/** */
import { idGetter, decimal_2, decimalChecker, ApolloSelect, render_ApolloInput, ApolloInput } from '../../../../components/ApolloCells'
/**Apollo Columns */
import { onRender_SKU10 } from '../../../../components/ApolloCells'

/**GQL */
const parent = {/*purchases: [Purchase]*/
    query: gql`query HOLDER_PENDINGs {
        holderPendings {
            id
            id_holder_pending
            holder {
                id
                id_holder
                name
                alive
                initialised
            }
            location {
                id
                id_location
                spot
                shelf
                floor
            }
        }
    }`,
    table: {
        dataAdder: {
            actions: [
                (index, dataToAdd, onClick_getDataToAdd)=>{
                    return(
                        <Insert
                            key={index}
                            onClick={onClick_getDataToAdd}
                            variableParser={ (newValues)=>{
                                const variables = { 
                                    variables: {
                                        'holder': [
                                            ['id', hasOnlyDigits(newValues['holder.name']) ? newValues['holder.name'] : null ],
                                            ['name', newValues['holder.name'] ],
                                        ],
                                        'location':[
                                            ['shelf', newValues['location.shelf'] ],
                                            ['spot', newValues['location.spot'] ],
                                            ['floor', newValues['location.floor'] ],
                                        ],
                                    }
                                };
                                return variables
                            } }
                            GQL={gql`mutation InsertHolder_to_HolderPending($holder:[[String]], $location:[[String]]) {
                                insertHolderPending(holder:$holder, location:$location) {
                                    id
                                    id_holder_pending
                                    holder {
                                        id
                                        id_holder
                                        name
                                        name
                                        alive
                                        initialised
                                    }
                                    location {
                                        id
                                        id_location
                                        spot
                                        shelf
                                        floor
                                    }
                                }
                            }`}
                            accessor={'insertHolderPending'}
                            updateQuery={gql`query HOLDER_PENDINGs {
                                holderPendings {
                                    id
                                    id_holder_pending
                                    holder {
                                        id
                                        id_holder
                                        name
                                        alive
                                        initialised
                                    }
                                    location {
                                        id
                                        id_location
                                        spot
                                        shelf
                                        floor
                                    }
                                }
                            }`}
                            accessor_cacheData = {'holderPendings'}
                            reusable={true}
                        >增</Insert>
                    )
                }
            ]
        },
        footer: true,
        accessor: 'holderPendings',
        actions: [
            (index, rowData, onClick_toggleEdit) => {
                return(
                    <Edit
                        key = {index}
                        onClick = {onClick_toggleEdit}
                        variableParser = {(newValues)=>{
                            const variables = { 
                                variables: {
                                    id_holder_pending: newValues.id.split('_')[1],
                                    holder: [['name', newValues['holder.name']]],//:[[String]], 
                                    location: [
                                        [ 'spot', newValues['location.spot'] ],
                                        [ 'shelf', newValues['location.shelf'] ],
                                        [ 'floor', newValues['location.floor'] ]
                                    ]
                                }
                            };
                            return variables
                        }}
                        GQL = {gql`mutation updateHolderPending($id_holder_pending: ID!, $holder: [[String]]!, $location: [[String]]) {
                            updateHolderPending(id_holder_pending: $id_holder_pending, holder: $holder, location:$location) {
                                id
                                id_holder_pending
                                holder {
                                    id
                                    id_holder
                                    name
                                    alive
                                    initialised
                                }
                                location {
                                    id
                                    id_location
                                    spot
                                    shelf
                                    floor
                                }
                            }
                        }`}
                        accessor = {'updateHolderPending'}
                        updateQuery = {gql`query HOLDER_PENDINGs {
                            holderPendings {
                                id
                                id_holder_pending
                                holder {
                                    id
                                    id_holder
                                    name
                                    alive
                                    initialised
                                }
                                location {
                                    id
                                    id_location
                                    spot
                                    shelf
                                    floor
                                }
                            }
                        }`}
                        accessor_cacheData = {'holderPendings'}
                    >{{edit:'改', abort:'撤', save:'存'}}</Edit>
                )
            },
            (index, rowData) => {
                return (
                    <Delete
                        key={index}
                        variables = {{
                            variables: {
                                id_holder_pending: rowData.id_holder_pending, 
                                fk_holder: rowData.holder.id_holder
                            }
                        }}
                        GQL = { gql`mutation DELETE_HOLDER_PENDING($id_holder_pending: ID! $fk_holder:ID){
                            deleteHolderPending(id_holder_pending: $id_holder_pending, fk_holder:$fk_holder) {
                                id
                                id_holder_pending
                            }
                        }`}

                        accessor = {'deleteHolderPending'}
                        updateQuery = {gql`query HOLDER_PENDINGs {
                            holderPendings {
                                id
                                id_holder_pending
                                holder {
                                    id
                                    id_holder
                                    name
                                    alive
                                    initialised
                                }
                                location {
                                    id
                                    id_location
                                    spot
                                    shelf
                                    floor
                                }
                            }
                        }`}
                        accessor_cacheData = {'holderPendings'}

                    >删</Delete>
                )
            }
        ]
    },
    columns: [
        {
            header: 'holder',
            accessor: 'holder.name',
            formatter: (value)=>{return value.replace(/\s/g, '');},
            onRender: (value)=>{return value},
            onEdit: (value, [colAccessor, newValues, setNewValues], formatter) => { 
                newValues[colAccessor] = value;
                return (
                    <input
                        style={{
                            width: '100%',
                            boxSizing: 'border-box',
                            textAlign: 'center',
                            fontFamily: 'Inconsolata, monospace',
                            fontSize: '14px'
                        }}
                        type='text'
                        defaultValue={formatter(value)}
                        onChange={(e)=>{
                            //force uppercase on all inputs
                            e.target.value = formatter(e.target.value)
                            newValues[colAccessor] = e.target.value;
                            console.log('value shown', newValues);
                            setNewValues(newValues);
                        }}
                    />
                )
            }
        },
        {
            header: 'spot',
            accessor: 'location.spot',
            formatter: (value)=>{return value.toUpperCase()},
            onRender: (value)=>{return value},
            onEdit: (value, [colAccessor, newValues, setNewValues], formatter) => { 
                newValues[colAccessor] = value;
                return (
                    <ApolloSelect
                    //style in component already. move it out
                        style={{
                            width: '100%',
                            boxSizing: 'border-box',
                            textAlign: 'center',
                            fontFamily: 'Inconsolata, monospace',
                            fontSize: '14px'
                        }}
                        defaultValue={value}
                        onChange={(e)=>{
                            newValues[colAccessor] = e.currentTarget.value;
                            setNewValues(newValues);
                            console.log(newValues)
                        }}
                        GQL={gql`query SPOTs {
                            spots {
                                id
                                id_spot
                                spot
                            }
                        }`}
                        accessors={['spots', ['spot']]}
                        valuePicker={['spot']}
                    />
                )
            }
        },
        {
            header: 'shelf',
            accessor: 'location.shelf',
            formatter: (value)=>{return value.toUpperCase()},
            onRender: (value)=>{return value},
            onEdit: (value, [colAccessor, newValues, setNewValues], formatter) => { 
                newValues[colAccessor] = value;
                //console.log(newValues);
                return (
                    <input
                        style={{
                            width: '100%',
                            boxSizing: 'border-box',
                            textAlign: 'center',
                            fontFamily: 'Inconsolata, monospace',
                            fontSize: '14px'
                        }}
                        type='text'
                        defaultValue={formatter(value)}
                        minLength={1}
                        maxLength={1}
                        onChange={(e)=>{
                            //force uppercase on all inputs
                            e.target.value = formatter(e.target.value)
                            newValues[colAccessor] = e.target.value;
                            console.log('value shown', newValues);
                            setNewValues(newValues);
                        }}
                    />
                )
            }
        },
        {
            header: 'floor',
            accessor: 'location.floor',
            formatter: (value)=>{return value},
            onRender: (value)=>{return value},
            onEdit: (value, [colAccessor, newValues, setNewValues], formatter) => { 
                newValues[colAccessor] = value;
                return (
                    <input
                        style={{
                            width: '100%',
                            boxSizing: 'border-box',
                            textAlign: 'center',
                            fontFamily: 'Inconsolata, monospace',
                            fontSize: '14px'
                        }}
                        type='number'
                        defaultValue={formatter(value)}
                        min='0'
                        max='9'
                        step='1'
                        onChange={(e)=>{
                            //force uppercase on all inputs
                            e.target.value = formatter(e.target.value)
                            newValues[colAccessor] = e.target.value;
                            setNewValues(newValues);
                        }}
                    />
                )
            }
        },
        {
            header: 'in use?',
            accessor: 'holder.alive',
            formatter: (value)=>{return value? 'yes' : 'no'},
            onRender: (value)=>{return value},
        },
        {
            header: 'used',
            accessor: 'holder.initialised',
            formatter: (value)=>{return value},
            onRender: (value)=>{return value},
        },
    ],
}

/**GQL */
const child0 = {
    query: gql`query STASH_PENDINGs($id_holder: ID) {
        stashPendings(fk_holder: $id_holder, inOut: "in") {
            id
            id_stash_pending
            product {
                id
                id_product
                SKU10
                brand
                title
                spec_1
                spec_2
                spec_3
                unit
            }
            purchase {
                id
                id_purchase
                shipment {
                    id
                    id_shipment
                    batch_code
                }
            }
            qty
        }
    }`,
    table: {
        accessor: 'stashPendings',
        actions: [
            (index, rowData, onClick_toggleEdit) => {
                return(
                    <Update
                        key = {index}
                        onClick = {()=>{ return {id_stash_pending: +rowData.id_stash_pending, holder: [['id', null]], qty: +rowData.qty} }}
                        variableParser = {(variables)=>{ return {variables}}}
                        GQL = {gql`mutation updateStashPending($id_stash_pending: ID!, $holder: [[String]], $qty: Int) {
                            updateStashPending(id_stash_pending: $id_stash_pending, holder: $holder, qty: $qty) {
                                id
                                id_stash_pending
                                product {
                                    id
                                    id_product
                                    SKU10
                                    brand
                                    title
                                    spec_1
                                    spec_2
                                    spec_3
                                    unit
                                }
                                holder {
                                    id
                                    id_holder
                                    name
                                }
                                qty
                            }
                        }`}
                        accessor = {'updateStashPending'}
                        updateQuery = {gql`query STASH_PENDINGs_noholder{
                            stashPendings(fk_holder: null, inOut: "in") {
                                id
                                id_stash_pending
                                product {
                                    id
                                    id_product
                                    SKU10
                                    brand
                                    title
                                    spec_1
                                    spec_2
                                    spec_3
                                    unit
                                }
                                holder {
                                    id
                                    id_holder
                                    name
                                }
                                purchase {
                                    id
                                    id_purchase
                                    shipment {
                                        id
                                        id_shipment
                                        batch_code
                                    }
                                }
                                qty
                            }
                        }`}
                        accessor_cacheData = {'stashPendings'}
                    >反</Update>
                )
            },
        ]
    },
    columns: [
        {
            header: 'shipment',
            accessor: 'purchase.shipment.batch_code',
            formatter: (value)=>{return value},
            onRender: (value)=>{return value},
        },
        {
            header: 'SKU10',
            accessor: 'product.SKU10',
            formatter: (value)=>{return value},
            onRender: (value)=>{return onRender_SKU10(value)},
        },
        {
            header: 'brand',
            accessor: 'product.brand',
            formatter: (value)=>{return value},
            onRender: (value)=>{return value},
        },
        {
            header: 'title',
            accessor: 'product.title',
            formatter: (value)=>{return value},
            onRender: (value)=>{return value},
        },
        {
            header: 'spec 1',
            accessor: 'product.spec_1',
            formatter: (value)=>{return value},
            onRender: (value)=>{return value},
        },
        {
            header: 'spec 2',
            accessor: 'product.spec_2',
            formatter: (value)=>{return value},
            onRender: (value)=>{return value},
        },
        {
            header: 'spec 3',
            accessor: 'product.spec_3',
            formatter: (value)=>{return value},
            onRender: (value)=>{return value},
        },
        {
            header: 'qty',
            accessor: 'qty',
            formatter: (value)=>{return value},
            onRender: (value)=>{return value},
        },
        {
            header: 'unit',
            accessor: 'product.unit',
            formatter: (value)=>{return value},
            onRender: (value)=>{return value},
        },
    ],
    variables: ['holder.id_holder'],
}

/**GQL */
const parent_1 = {/*purchases: [Purchase]*/
    query: gql`query STASH_PENDINGs_noholder{
        stashPendings(fk_holder: null, inOut: "in") {
            id
            id_stash_pending
            product {
                id
                id_product
                SKU10
                brand
                title
                spec_1
                spec_2
                spec_3
                unit
            }
            holder {
                id
                id_holder
                name
            }
            purchase {
                id
                id_purchase
                shipment {
                    id
                    id_shipment
                    batch_code
                }
            }
            qty
        }
    }`,
    table: {
        footer: true,
        accessor: 'stashPendings',
        actions: [
            (index, rowData, onClick_toggleEdit) => {
                return(
                    <Edit
                        key = {index}
                        onClick = { onClick_toggleEdit }
                        variableParser={ (newValues) => {
                            console.log(newValues);
                            const variables = { 
                                variables: {
                                    id_stash_pending: +rowData.id_stash_pending, //newValues.id.split('_')[1],
                                    qty: +newValues.qty,
                                    holder: [ ['name', 'holder.name' in newValues? newValues['holder.name'] : null] ]
                                }
                            };
                            return variables
                        } }
                        GQL = {gql`mutation updateStashPending($id_stash_pending: ID!, $holder: [[String]], $qty: Int) {
                            updateStashPending(id_stash_pending: $id_stash_pending, holder: $holder, qty: $qty) {
                                id
                                id_stash_pending
                                product {
                                    id
                                    id_product
                                    SKU10
                                    brand
                                    title
                                    spec_1
                                    spec_2
                                    spec_3
                                    unit
                                }
                                holder {
                                    id
                                    id_holder
                                    name
                                }
                                qty
                            }
                        }`}
                        accessor = {'updateStashPendings'}
                        updateQuery = {gql`query STASH_PENDINGs_noholder{
                            stashPendings(fk_holder: null, inOut: "in") {
                                id
                                id_stash_pending
                                product {
                                    id
                                    id_product
                                    SKU10
                                    brand
                                    title
                                    spec_1
                                    spec_2
                                    spec_3
                                    unit
                                }
                                holder {
                                    id
                                    id_holder
                                    name
                                }
                                qty
                            }
                        }`}
                        accessor_cacheData = {'stashPendings'}
                    >{{edit:'改', abort:'撤', save:'存'}}</Edit>
                )
            },
            (index, rowData)=>{
                return (
                    <Delete
                        key={index}
                        variables = {paramToVariables( ['id_stash_pending'], rowData) }
                        GQL = { gql`mutation DELETE_STASH_PENDING($id_stash_pending: ID!){
                            deleteStashPending(id_stash_pending: $id_stash_pending) {
                                id
                                id_stash_pending
                                product {
                                    SKU10
                                }
                            }
                        }`}
                    >删</Delete>
                )
            }
        ]
    },
    columns: [
        {
            header: 'shipment',
            accessor: 'purchase.shipment.batch_code',
            formatter: (value)=>{return value},
            onRender: (value)=>{return value},
        },
        {
            header: 'holder',
            accessor: 'holder.name',
            formatter: (value)=>{return value},
            onRender: (value)=>{return value},
            onEdit: (value, [colAccessor, newValues, setNewValues], formatter) => { 
                newValues[colAccessor] = value;
                return (
                    <ApolloSelect
                    //style in component already. move it out
                        style={{
                            width: '100%',
                            boxSizing: 'border-box',
                            textAlign: 'center',
                            fontFamily: 'Inconsolata, monospace',
                            fontSize: '14px'
                        }}
                        defaultValue={value}
                        onChange={(e)=>{
                            
                            newValues[colAccessor] = e.currentTarget.value;
                            setNewValues(newValues);
                            //console.log(newValues);
                        }}
                        GQL={gql`query HOLDERs_from_HOLDERPENDINGs {
                            holderPendings {
                                id
                                id_holder_pending
                                holder {
                                    id
                                    id_holder
                                    name
                                }
                            }
                        }`}
                        accessors={['holderPendings', ['holder','name']]}
                        valuePicker={['holder','name']}
                    />
                )
            }
        },
        {
            header: 'SKU10',
            accessor: 'product.SKU10',
            formatter: (value)=>{return value},
            onRender: (value)=>{return onRender_SKU10(value)},
        },
        {
            header: 'title',
            accessor: 'product.title',
            formatter: (value)=>{return value},
            onRender: (value)=>{return value},
        },
        {
            header: 'qty',
            accessor: 'qty',
            formatter: (value)=>{return value},
            onRender: (value)=>{return value},
            onEdit: (value, [colAccessor, newValues, setNewValues], formatter) => {
                newValues[colAccessor] = value;
                return  (
                    <input 
                        style={{
                            width: '100%',
                            boxSizing: 'border-box',
                            textAlign: 'right',
                            fontFamily: 'Inconsolata, monospace',
                            fontSize: '14px'
                        }}
                        min="0"
                        type={'number'} 
                        defaultValue={value}
                        onChange={(e)=>{
                            newValues[colAccessor] = parseInt(e.target.value);
                            setNewValues(newValues);
                        }}
                    />
                )
            }
        },
        {
            header: 'unit',
            accessor: 'product.unit',
            formatter: (value)=>{return value},
            onRender: (value)=>{return value},
        },
    ],
}
/**GQL */
const child0_1 = {
    query: gql`query STASH_PENDINGs($id_product: ID) {
        products(id_product: $id_product) {
            id
            id_product
            brand
            title
            spec_1
            spec_2
            spec_3
        }
    }`,
    table: {
        accessor: 'products',
    },
    columns: [
        {
            header: 'brand',
            accessor: 'brand',
            formatter: (value)=>{return value},
            onRender: (value)=>{return value},
        },
        {
            header: 'spec 1',
            accessor: 'spec_1',
            formatter: (value)=>{return value},
            onRender: (value)=>{return value},
        },
        {
            header: 'spec 2',
            accessor: 'spec_2',
            formatter: (value)=>{return value},
            onRender: (value)=>{return value},
        },
        {
            header: 'spec 3',
            accessor: 'spec_3',
            formatter: (value)=>{return value},
            onRender: (value)=>{return value},
        },
    ],
    variables: ['product.id_product'],
}
const config = [
    {
        parent,
        child0
    },
    {
        parent:parent_1,
        child0:child0_1
    },
]
export default config;