//
import css from './index.module.css'
//
import { useState, useEffect } from 'react'
/**font awesome */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro' // <-- import styles to be used
//
import FileIcon from './FileIcon'
//
import { productImgLink, productFilesList } from '../../context/variables'

function Folder(props) {

    const {
        folderRoot,
    } = props;

    useEffect(async () => {
        const bullShit = await productFilesList(folderRoot);
        setFilesList( bullShit );
        
      }, []);
    
    const [ rootFilesList, setRootFilesList ] = useState([]);
    const rootFolderDir  = useState(`bucket://products/${folderRoot}/`);
    //
    const [ filesList,  setFilesList ] = useState([]);
    const [ folderDir, setFolderDir ] = useState(`bucket://products/${folderRoot}/`);
    //list of child folders to avoid duplicates
    const nested = []
    //files to render in nested folders
    const result = {}
    
    return (
        <div className={css.container}>
            <div className={css.fileSys}>
                {filesList.map( ( el, idx ) => {
                    const dirWithRoot = el.name.split('/');
                    const dirNoRoot = el.name.split('/').slice(2);
                    const fileName = el.name.split('/').slice(-1);

                    const nestedFolder = el.name.split('/').slice(2)[0];

                    //console.log(dirNoRoot)
                    if ( dirNoRoot.length == 1 ) {
                        return <FileIcon key={idx} name={fileName} contentType={el.contentType} onClick={()=>{alert(el.name)}}/>
                    }
                    if ( dirNoRoot.length > 1 ) {
                        const file = {
                            name: dirWithRoot.slice(2).reduce( (accum,curr)=>{return accum+'/'+curr}, '' ),
                            contentType: el.contentType
                        }

                        if( result[nestedFolder] === undefined ) {
                            result[nestedFolder] = [file]
                        } else {
                            result[nestedFolder].push(file)
                        }
                        if ( !nested.includes(nestedFolder) ) { 
                            nested.push( nestedFolder );
                            //console.log(result[nestedFolder]);
                            return <FileIcon key={idx} name={nestedFolder} contentType={'folder'} onClick={()=>{
                                setFolderDir(`bucket://products/${folderRoot}/image`)
                                setFilesList(result[nestedFolder])
                                setRootFilesList(filesList)
                            }}/>
                        }
                    }
                })}
            </div>
            <div className={css.fileSysNav}>
                <button 
                    className={css.fileSysNavUp}
                    onClick={ ()=>{ setFolderDir(rootFolderDir); return setFilesList(rootFilesList); } }
                > 
                    <FontAwesomeIcon icon={ solid('turn-up')} flip={'horizontal'}/>
                </button>
                <div className={css.fileSysNavDir}>{ folderDir }</div>
            </div>
        </div>
    )
}

export default Folder

//function