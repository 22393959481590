import ApolloTable from "../../../../components/ApolloTable";
/**css */
import './index.css';
/**config */
import config from './config';

function Spzb() {
    return(
        <div className={'master_container'}>
            <button>toggle</button>
            <ApolloTable
                cssPrefix={'Spzb'} 
                query={config.parent.query} 
                columns={config.parent.columns} 
                table={config.parent.table} 
            >
                <ApolloTable 
                    cssPrefix={'Spzb0'} 
                    query={config.child0.query} 
                    columns={config.child0.columns} 
                    table={config.child0.table}
                    variables={config.child0.variables}
                >
                    <ApolloTable
                        cssPrefix={'Spzb1'}  
                        query={config.child1.query} 
                        columns={config.child1.columns} 
                        table={config.child1.table}
                        variables={config.child1.variables}
                    >
                        <ApolloTable 
                            cssPrefix={'Spzb2'} 
                            query={config.child2.query} 
                            columns={config.child2.columns} 
                            table={config.child2.table}
                            variables={config.child2.variables}
                        />
                    </ApolloTable>
                </ApolloTable>
            </ApolloTable>
        </div>
    )
}

export default Spzb;