import { Insert } from '../../../../components/ApolloButtons';

import { gql } from "@apollo/client";
/**helper */
import { paramToVariables } from '../../../../helper';
/**Apollo Columns */
import { onRender_SKU10 } from '../../../../components/ApolloCells'

/**GQL */
const parent = {
    query: gql`query Products{
        products {
            id
            id_product
            SKU10
            brand
            title
            spec_1
            spec_2
            spec_3
            qty_current
            qty_future
            unit
            category {
                id
                parent
                child
            }
            need_purchase
        }
    }`,
    table: {
        accessor: 'products',
        actions: [ //action button null. what do in table generator. ****param is nest what do? *****
            (index, rowData) => {
                return (
                    <Insert
                        key={index}
                        onClick={()=>{return { fk_product: rowData['id_product'] } }}
                        variableParser={(values)=>{return { variables: values } }}
                        GQL={gql`mutation BUYTHIS($fk_product: ID!){
                            insertPurchase(fk_product: $fk_product) {
                                id
                                id_purchase
                                shipment {
                                    id
                                    id_shipment
                                    batch_code
                                    departure
                                    est_arrival
                                    act_arrival
                                }
                                product {
                                    id
                                    id_product
                                    SKU10
                                    brand
                                    title
                                    spec_1
                                    spec_2
                                    spec_3
                                    qty_current
                                    unit
                                    category {
                                        id
                                        parent
                                        child
                                    }
                                    need_purchase
                                }
                                qty_purchased
                            }
                        }`}
                        accessor={'insertPurchase'}
                        updateQuery={gql`query Purchase {
                            purchases {
                                id
                                id_purchase
                                shipment {
                                    id
                                    id_shipment
                                    batch_code
                                    departure
                                    est_arrival
                                    act_arrival
                                }
                                product {
                                    id
                                    id_product
                                    SKU10
                                    brand
                                    title
                                    spec_1
                                    spec_2
                                    spec_3
                                    qty_current
                                    unit
                                    category {
                                        id
                                        parent
                                        child
                                    }
                                    need_purchase
                                }
                                qty_purchased
                            }
                        }`}
                        accessor_cacheData={'purchases'}
                    >采</Insert>
                )
            },
        ]
    },
    columns: [

        {
            header: 'cat',
            accessor: 'category.parent',
            formatter: (value)=>{return value},
            onRender: (value)=>{return value},
        },
        {
            header: 'subcat',
            accessor: 'category.child',
            formatter: (value)=>{return value},
            onRender: (value)=>{return value},
        },
        {
            header: 'SKU',
            accessor: 'SKU10',
            formatter: (value)=>{return value},
            onRender: (value)=>{return onRender_SKU10(value)},
        },
        {
            header: 'brand',
            accessor: 'brand',
            formatter: (value)=>{return value},
            onRender: (value)=>{return value},
        },
        {
            header: 'title',
            accessor: 'title',
            formatter: (value)=>{return value},
            onRender: (value)=>{return value},
        },
        {
            header: 'spec 1',
            accessor: 'spec_1',
            formatter: (value)=>{return value},
            onRender: (value)=>{return value},
        },
        {
            header: 'spec 2',
            accessor: 'spec_2',
            formatter: (value)=>{return value},
            onRender: (value)=>{return value},
        },
        {
            header: 'spec 3',
            accessor: 'spec_3',
            formatter: (value)=>{return value},
            onRender: (value)=>{return value},
        },
        {
            header: 'qty stash',
            accessor: 'qty_current',
            formatter: (value)=>{return value},
            onRender: (value)=>{return value},
        },
        {
            header: 'qty future',
            accessor: 'qty_future',
            formatter: (value)=>{return value},
            onRender: (value)=>{return value},
        },
        {
            header: 'unit',
            accessor: 'unit',
            formatter: (value)=>{return value},
            onRender: (value)=>{return value},
        },
    ],
}

const config = {
    parent,
}
export default config;