export const domain = 'https://unaware.house'

export const productImgLink = async (sku) => {
    const storage = 'https://unaware.house/api/product';
    const params = `/${sku}`;
    const getUrl = await fetch(storage+params);
    const url = await getUrl.text();
    //console.log (typeof url);
    return url
}

export const productFilesList = async (sku) => {
    const storage = 'https://unaware.house/api/product/files';
    const params = `/${sku}`;
    const getFilesList = await fetch(storage+params);
    const filesList = await getFilesList.text();
    
    const filesList_ARRAY = JSON.parse(filesList).result;
    //console.log(filesList_ARRAY)  WHY [[{},{},{}]] ? what is the point of that outer array
    let result = filesList_ARRAY[0].map((el)=>{
        //console.log(el)
        const ement = el.metadata;
        return {contentType: ement.contentType, name: ement.name}
    })
    //console.log(result)
    return result
}