/**css */
import './index.css';
/**font awesome */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid, regular, brands } from '@fortawesome/fontawesome-svg-core/import.macro' // <-- import styles to be used
/**apollo-client*/
import { useQuery, useMutation } from "@apollo/client";

function ApolloButton_Procedure(props) {
    const { 
        variables, //variables for mutation
        GQL, //mutation itself
    //with default values
        loadingReturn, //what to show when loading
        errorReturn, //what to show when error
    //
        accessor,
        updateQuery,
        accessor_cacheData,
    //
        children,
        className
    } = props;
    
    const [runMutation, { data, loading, error }] = useMutation(GQL,  {
        update(cache, mutationResult) {
            if (accessor === undefined) return;
            if (updateQuery === undefined) return;
            if (accessor_cacheData === undefined) return;
            
            //console.log('updated', mutationResult);
            //retrieve the data needed to be updated in cache
            const oldData = cache.readQuery({query:updateQuery});
            //if it doesnt exist abort
            if (! oldData) return;
            //if the needed key in it doesnt exist abort
            if (! accessor_cacheData in oldData) return;
            //construct new cache data by appending new data to old data
            const newData = {};
            //before appending mutationResult.data[accessor] to oldData we need to check if there is duplicate id using filter. 
            //filter to exclude mutationResult.data[accessor]. if it doesnt exist it will just return the same array.
            const filtered_oldData = oldData[accessor_cacheData].filter(element => element.id!==mutationResult.data[accessor].id);
            newData[accessor_cacheData] = [ ...filtered_oldData, mutationResult.data[accessor] ];
            //console.log( newData );
            cache.writeQuery({
                query: updateQuery,
                data: newData
            });
        }
    });

    const onClickHandler = () => {
        const answer = variables();
        //console.log(answer)
        runMutation(answer);
        /*if ( window.confirm(`
                About to update.
                
                Are you sure?
            `) ) {
            runMutation(variables);
        }*/
    }

    if (loading) return loadingReturn;
    if (error) return errorReturn(error.message);
    if (data) console.log('updated', data);
    return(
        <button className={className} onClick={onClickHandler}>{children}</button>
    )
}

ApolloButton_Procedure.defaultProps = {
    loadingReturn: <button className={'faBtnCircleNotch'}><FontAwesomeIcon icon={solid('circle-notch')} spin/></button>,
    errorReturn: (errorMessage)=>{return <button className={'faBtnBug'} onClick={()=>{alert(errorMessage);}}><FontAwesomeIcon icon={solid('bug')}/></button>}
}

export default ApolloButton_Procedure;

