/**css */
import './index.css';
/**font awesome */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid, regular, brands } from '@fortawesome/fontawesome-svg-core/import.macro' // <-- import styles to be used
/**apollo-client*/
import { useQuery, useMutation } from "@apollo/client";
/**react router */
import { useLocation } from 'react-router-dom';
/**global variables */
import { domain } from '../../context/variables'

function ApolloButton_Update(props) {
    const {
        onClick, //some function from table Component that returns a state value.
        variableParser, //set up variables needed for GQL
        GQL, //mutation
        accessor,  //first key to mutation return data
        updateQuery, //queries to refetch after mutation
        accessor_cacheData, //first key to query return data in cache
        reusable,
//
        children,
        loadingReturn, //what to show when loading
        errorReturn, //what to show when error
        successReturn, //what to show when success
    } = props;
/*
    successReturn, //what to show when successful
*/    
    const current_url = useLocation();
    const [runMutation, { data, loading, error }] = useMutation(GQL, {
        update(cache, mutationResult) {
            //console.log('updated', mutationResult);
            //retrieve the data needed to be updated in cache
            const oldData = cache.readQuery({query:updateQuery});
            //if it doesnt exist abort
            if (! oldData) return;
            //if the needed key in it doesnt exist abort
            if (! accessor_cacheData in oldData) return;
            //construct new cache data by appending new data to old data
            const newData = {};
            //before appending mutationResult.data[accessor] to oldData we need to check if there is duplicate id using filter. 
            //filter to exclude mutationResult.data[accessor]. if it doesnt exist it will just return the same array.
            const filtered_oldData = oldData[accessor_cacheData].filter(element => element.id!==mutationResult.data[accessor].id);
            newData[accessor_cacheData] = [ ...filtered_oldData, mutationResult.data[accessor] ];//newData[accessor_cacheData] = [ ...oldData[accessor_cacheData], mutationResult.data[accessor] ];
            console.log( newData );
            cache.writeQuery({
                query: updateQuery,
                data: newData
            });
        }
    });

    const onClickHandler = () => {
        const variables = onClick(); //get current form values
        console.log(variableParser(variables));
        runMutation(variableParser(variables));
    }

    if (loading) return loadingReturn;
    if (error) return errorReturn(error, current_url);//`${errorReturn}${error.message}`;
    if (data) return (
        reusable?
            <button
                className={'aplBtnUpdate'}
                onClick={onClickHandler}
            >{children}</button>
        :
            successReturn
    );
    return (
        <button
            className={'aplBtnUpdate'}
            onClick={onClickHandler}
        >{children}</button>
    )
}
ApolloButton_Update.defaultProps = {
    loadingReturn: <button className={'faBtnCircleNotch'}><FontAwesomeIcon icon={solid('circle-notch')} spin/></button>,
    errorReturn: (error, current_url)=>{
        return (
            <button className={'faBtnBug'} onClick={()=>{ 
                
                if( window.confirm( JSON.stringify(error, null, 2) ) ){
                    window.location.href = `${domain}${current_url.pathname}`;
                }
            }}>
                <FontAwesomeIcon icon={solid('bug')} flash/>
            </button>
        )},
    successReturn: <button className={'faBtnCheck'}><FontAwesomeIcon icon={solid('check')} /></button>,
    reusable: false
}

export default ApolloButton_Update;