import { Update } from '../../../../components/ApolloButtons';
/**helper */
import { paramToVariables } from '../../../../helper';
/** */
import { gql } from "@apollo/client";
/** */
import { idGetter, decimal_2, decimalChecker, ApolloSelect, render_ApolloInput, ApolloInput } from '../../../../components/ApolloCells'
/**Apollo Columns */
import { onRender_SKU10 } from '../../../../components/ApolloCells'

/**GQL */
const parent = {/*purchases: [Purchase]*/
    query: gql`query SHIPMENTs {
        shipments(status: "not here yet") {
            id
            id_shipment
            status {
                id
                id_status
                title
            }
            batch_code
            origin
            #
            departure
            est_arrival
            #act_arrival
            updated
            inserted
            #
            shipping_fee
            currency_paid
            conversion_rate
            tariff
        }
    }`,
    table: {
        footer: true,
        accessor: 'shipments',
        actions: [
            (index, rowData, onClick_toggleEdit) => {
                return(
                    <Update
                        key = {index}
                        onClick = {()=>{ return {
                            id_shipment: +rowData.id_shipment, 
                            status: "arrived"
                        } }}
                        variableParser = {(variables)=>{ return {variables}}}
                        GQL = {gql`mutation updateShipment($id_shipment: ID!, $status: String) {
                            updateShipment(id_shipment: $id_shipment, status: $status) {
                                id
                                id_shipment
                                status {
                                    id
                                    id_status
                                    title
                                }
                                batch_code
                                origin
                                #
                                departure
                                est_arrival
                                #act_arrival
                                updated
                                inserted
                                #
                                shipping_fee
                                currency_paid
                                conversion_rate
                                tariff
                            }
                        }`}
                        accessor = {'updateShipment'}
                        updateQuery = {gql`query ARRIVED_SHIPMENTs {
                            shipments(status: "arrived") {
                                id
                                id_shipment
                                status {
                                    id
                                    id_status
                                    title
                                }
                                batch_code
                                origin
                                #
                                departure
                                est_arrival
                                #act_arrival
                                updated
                                inserted
                                #
                                shipping_fee
                                currency_paid
                                conversion_rate
                                tariff
                            }
                        }`}
                        accessor_cacheData = {'shipments'}
                    >收</Update>
                )
            },
        ]
    },
    columns: [
        {
            header: 'status',
            accessor: 'status.title',
            formatter: (value)=>{return value},
            onRender: (value)=>{return value},
        },
        {
            header: 'batch_code',
            accessor: 'batch_code',
            formatter: (value)=>{return value.toUpperCase()},
            onRender: (value)=>{return value},
        },
        {
            header: 'origin',
            accessor: 'origin',
            formatter: (value)=>{
                return value.split('').reduce( (result, current, index, array)=>{
                    switch (array[index-1]){
                        case undefined: return result+current.toUpperCase();
                        case ' ': return result+current.toUpperCase();
                        default: return result+current;
                    }
                },'')
            },
            onRender: (value)=>{return value},
        },
        {
            header: 'departure',
            accessor: 'departure',
            formatter: (value)=>{return value.substring(0, 10)},
            onRender: (value)=>{return value},
        },
        {
            header: 'est_arrival',
            accessor: 'est_arrival',
            formatter: (value)=>{return value.substring(0, 10)},
            onRender: (value)=>{return value},
        },
        {
            header: 'shipping fee',
            accessor: 'shipping_fee',
            formatter: (value)=>{return decimal_2(value)},
            onRender: (value)=>{return value},
        },
        {
            header: 'ccy',
            accessor: 'currency_paid',
            formatter: (value)=>{return value.toUpperCase()},
            onRender: (value)=>{return value},
        },
        {
            header: 'cvr',
            accessor: 'conversion_rate',
            formatter: (value)=>{return decimal_2(value)},
            onRender: (value)=>{return value},
        },
        {
            header: 'tariff',
            accessor: 'tariff',
            formatter: (value)=>{return decimal_2(value)},
            onRender: (value)=>{return value},
        },
        {
            header: 'inserted',
            accessor: 'inserted',
            formatter: (value)=>{return value.substring(0, 10)},
            onRender: (value)=>{return value},
        },           
        {
            header: 'updated',
            accessor: 'updated',
            formatter: (value)=>{return value.substring(0, 10)},
            onRender: (value)=>{return value},
        },
    ],
}

/**GQL */
const child0 = {
    query: gql`query SHIPMENTs($id_shipment: ID) {
        shipments(id_shipment: $id_shipment) {
            purchases {
                id
                id_purchase
                product {
                    category {
                        id
                        id_category
                        child
                    }
                    id
                    id_product
                    SKU10
                    brand
                    title
                    spec_1
                    spec_2
                    spec_3
                    unit
                }
                qty_purchased
                supplier {
                    company_name
                }
                inserted
            }
        }
    }`,
    table: {
        accessor: 'shipments.0.purchases',
    },
    columns: [
        {
            header: 'subcat',
            accessor: 'product.category.child',
            formatter: (value)=>{return value},
            onRender: (value)=>{return value},
        },
        {
            header: 'SKU',
            accessor: 'product.SKU10',
            formatter: (value)=>{return value},
            onRender: (value)=>{return onRender_SKU10(value)},
        },
        {
            header: 'brand',
            accessor: 'product.brand',
            formatter: (value)=>{return value},
            onRender: (value)=>{return value},
        },
        {
            header: 'title',
            accessor: 'product.title',
            formatter: (value)=>{return value},
            onRender: (value)=>{return value},
        },
        {
            header: 'spec 1',
            accessor: 'product.spec_1',
            formatter: (value)=>{return value},
            onRender: (value)=>{return value},
        },
        {
            header: 'spec 2',
            accessor: 'product.spec_2',
            formatter: (value)=>{return value},
            onRender: (value)=>{return value},
        },
        {
            header: 'spec 3',
            accessor: 'product.spec_3',
            formatter: (value)=>{return value},
            onRender: (value)=>{return value},
        },
        {
            header: 'qty',
            accessor: 'qty_purchased',
            formatter: (value)=>{return value},
            onRender: (value)=>{return value},
        },
        {
            header: 'unit',
            accessor: 'product.unit',
            formatter: (value)=>{return value},
            onRender: (value)=>{return value},
        },
        {
            header: 'inserted',
            accessor: 'inserted',
            formatter: (value)=>{return value.substring(2, 10)},
            onRender: (value)=>{return value},
        },
        {
            header: 'supplier',
            accessor: 'supplier.company_name',
            formatter: (value)=>{return value},
            onRender: (value)=>{return value},
        },
    ],
    variables: ['id_shipment'],
}

const config = {
    parent,
    child0
}
export default config;