import { gql } from "@apollo/client";
/**Apollo Columns */
import { onRender_SKU10 } from '../../components/ApolloCells'
import { idGetter, decimal_2, decimalChecker, ApolloSelect, render_ApolloInput, ApolloInput } from '../../components/ApolloCells'

const DcbH = {/*purchases: [Purchase]*/
    query: gql`query ARRIVED_SHIPMENTs {
        shipments(status: "arrived all stashed") {
            id
            id_shipment
            status {
                id
                id_status
                title
            }
            batch_code
            origin
            #
            departure
            est_arrival
            act_arrival
            updated
            inserted
            #
            shipping_fee
            currency_paid
            conversion_rate
            tariff
        }
    }`,
    table: {
        footer: true,
        accessor: 'shipments',
        actions: null
    },
    columns: [
        {
            header: 'status',
            accessor: 'status.title',
            formatter: (value)=>{return value},
            onRender: (value)=>{return value},
        },
        {
            header: 'batch_code',
            accessor: 'batch_code',
            formatter: (value)=>{return value.toUpperCase()},
            onRender: (value)=>{return value},
        },
        {
            header: 'origin',
            accessor: 'origin',
            formatter: (value)=>{
                return value.split('').reduce( (result, current, index, array)=>{
                    switch (array[index-1]){
                        case undefined: return result+current.toUpperCase();
                        case ' ': return result+current.toUpperCase();
                        default: return result+current;
                    }
                },'')
            },
            onRender: (value)=>{return value},
        },
        {
            header: 'departure',
            accessor: 'departure',
            formatter: (value)=>{return value.substring(0, 10)},
            onRender: (value)=>{return value},
        },
        {
            header: 'est_arrival',
            accessor: 'est_arrival',
            formatter: (value)=>{return value.substring(0, 10)},
            onRender: (value)=>{return value},
        },
        {
            header: 'arrival',
            accessor: 'act_arrival',
            formatter: (value)=>{return value.substring(0, 10)},
            onRender: (value)=>{return value},
        },
        {
            header: 'shipping fee',
            accessor: 'shipping_fee',
            formatter: (value)=>{return decimal_2(value)},
            onRender: (value)=>{return value},
        },
        {
            header: 'ccy',
            accessor: 'currency_paid',
            formatter: (value)=>{return value.toUpperCase()},
            onRender: (value)=>{return value},
        },
        {
            header: 'cvr',
            accessor: 'conversion_rate',
            formatter: (value)=>{return decimal_2(value)},
            onRender: (value)=>{return value},
        },
        {
            header: 'tariff',
            accessor: 'tariff',
            formatter: (value)=>{return decimal_2(value)},
            onRender: (value)=>{return value},
        },
        {
            header: 'inserted',
            accessor: 'inserted',
            formatter: (value)=>{return value.substring(0, 10)},
            onRender: (value)=>{return value},
        },           
        {
            header: 'updated',
            accessor: 'updated',
            formatter: (value)=>{return value.substring(0, 10)},
            onRender: (value)=>{return value},
        },
    ],
}

const DcbH0 = {
    query: gql`query SHIPMENTs($id_shipment: ID) {
        shipments(id_shipment: $id_shipment) {
            purchases {
                id
                id_purchase
                product {
                    id
                    id_product
                    category {
                        id
                        id_category
                        child
                    }
                    SKU10
                    brand
                    title
                    spec_1
                    spec_2
                    spec_3
                    unit
                }
                qty_purchased
                qty_stashing
                qty_stashed
                supplier {
                    company_name
                }
                inserted
            }
        }
    }`,
    table: {
        accessor: 'shipments.0.purchases',
        actions: null
    },
    columns: [
        {
            header: 'subcat',
            accessor: 'product.category.child',
            formatter: (value)=>{return value},
            onRender: (value)=>{return value},
        },
        {
            header: 'SKU',
            accessor: 'product.SKU10',
            formatter: (value)=>{return value},
            onRender: (value)=>{return onRender_SKU10(value)},
        },
        {
            header: 'brand',
            accessor: 'product.brand',
            formatter: (value)=>{return value},
            onRender: (value)=>{return value},
        },
        {
            header: 'title',
            accessor: 'product.title',
            formatter: (value)=>{return value},
            onRender: (value)=>{return value},
        },
        {
            header: 'spec 1',
            accessor: 'product.spec_1',
            formatter: (value)=>{return value},
            onRender: (value)=>{return value},
        },
        {
            header: 'spec 2',
            accessor: 'product.spec_2',
            formatter: (value)=>{return value},
            onRender: (value)=>{return value},
        },
        {
            header: 'spec 3',
            accessor: 'product.spec_3',
            formatter: (value)=>{return value},
            onRender: (value)=>{return value},
        },
        {
            header: 'purchased',
            accessor: 'qty_purchased',
            formatter: (value)=>{return value},
            onRender: (value)=>{return value},
        },
        {
            header: 'stashing',
            accessor: 'qty_stashing',
            formatter: (value)=>{return value},
            onRender: (value)=>{return value},
        },
        {
            header: 'stashed',
            accessor: 'qty_stashed',
            formatter: (value)=>{return value},
            onRender: (value)=>{return value},
        },
        {
            header: 'unit',
            accessor: 'product.unit',
            formatter: (value)=>{return value},
            onRender: (value)=>{return value},
        },
        {
            header: 'inserted',
            accessor: 'inserted',
            formatter: (value)=>{return value.substring(2, 10)},
            onRender: (value)=>{return value},
        },
        {
            header: 'supplier',
            accessor: 'supplier.company_name',
            formatter: (value)=>{return value},
            onRender: (value)=>{return value},
        },
    ],
    variables: ['id_shipment'],
}

const RcbH = {
    query: gql`query STASHACTIVITYs_OUT($inOut: String) {
        stashActivitys(inOut: $inOut) {
            id
            id_stash_activity
            inserted
            updated
            qty
            product {     
                id
                id_product           
                category {
                    id
                    id_category
                    parent
                }
                SKU10
                brand
                title
                spec_1
                spec_2
                spec_3
                unit
            }
            holder {
                id
                id_holder
                name
                location {
                    id
                    id_location
                    spot
                    shelf
                    floor
                }
            }
            destination {
                id
                id_destination
                title
            }
            user {
                id
                id_user
                alt_name
            }
        }
    }`,
    table: {
        footer: true,
        accessor: 'stashActivitys',
    },
    columns: [
        {           
            header: 'time',
            accessor: 'updated',
            formatter: (value)=>{return value.substring(0, 10)},
            onRender: (value)=>{return value},
        }, 
        {           
            header: 'spot',
            accessor: 'holder.location.spot',
            formatter: (value)=>{return value},
            onRender: (value)=>{return value},
        },  
        {           
            header: 'shelf',
            accessor: 'holder.location.shelf',
            formatter: (value)=>{return value},
            onRender: (value)=>{return value},
        },  
        {           
            header: 'floor',
            accessor: 'holder.location.floor',
            formatter: (value)=>{return value},
            onRender: (value)=>{return value},
        },  
        {           
            header: 'name',
            accessor: 'holder.name',
            formatter: (value)=>{return value},
            onRender: (value)=>{return value},
        },  
        {           
            header: 'SKU10',
            accessor: 'product.SKU10',
            formatter: (value)=>{return value},
            onRender: (value)=>{return onRender_SKU10(value)},
        }, 
        {           
            header: 'cat',
            accessor: 'product.category.parent',
            formatter: (value)=>{return value},
            onRender: (value)=>{return value},
        },
        {           
            header: 'brand',
            accessor: 'product.brand',
            formatter: (value)=>{return value},
            onRender: (value)=>{return value},
        }, 
        {           
            header: 'title',
            accessor: 'product.title',
            formatter: (value)=>{return value},
            onRender: (value)=>{return value},
        }, 
        {           
            header: 'qty',
            accessor: 'qty',
            formatter: (value)=>{return value},
            onRender: (value)=>{return value},
        },  
        {           
            header: 'unit',
            accessor: 'product.unit',
            formatter: (value)=>{return value},
            onRender: (value)=>{return value},
        },  
        {           
            header: 'purpose',
            accessor: 'destination.title',
            formatter: (value)=>{return value},
            onRender: (value)=>{return value},
        },
        {
            header: 'user',
            accessor: 'user.alt_name',
            formatter: (value)=>{return value},
            onRender: (value)=>{return value},
        },
    ],
    variables: {
        "inOut": "in"
    }
}

const addOn = {
    DcbH,
    DcbH0,
    RcbH,
}

export default addOn