import css from './index.module.css';
import { Link, useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

function PageButton( props ) {
    const { type, to, children, icon, onClick_addOn } = props;

    const current_url = useLocation('');
    const [buttonClicked, setButtonClicked] = useState(false);

    useEffect(()=>{
        const urlParts = current_url.pathname.split('/');
        const master = '/'+urlParts[1];
        const slave = urlParts[2]? '/'+urlParts[1]+'/'+urlParts[2] : master;

        switch (type) {
            case 'master': setButtonClicked(master===to); break;
            case 'slave' : setButtonClicked(slave===to); break;
            default: setButtonClicked(current_url.pathname===to);
        }

        /*
        type==='master'?
            setButtonClicked(current_url.pathname===to || master===to) 
        :
            setButtonClicked(current_url.pathname===to) 
        */
        /*
            if (current_url.pathname===to) { 
                setButtonClicked(true) 
            } else {
                setButtonClicked(false) 
            }
        */
    }, [current_url])

    const themeSelector = (type) => {
        switch (type) {
            case 'master': return css.master;
            case 'slave' : return css.slave;
            default: return css.tool;
        }
    }
    //const cssTheme = type==='master'? css.master : css.slave;
    const cssTheme = themeSelector(type);
    return (<>
        <Link to={to}>
            <button className={buttonClicked? cssTheme+' '+css.clicked : cssTheme+' '+css.not_clicked}>
                {children}
            </button>
        </Link>
        {icon?
            <button className={buttonClicked? css.addOn+' '+css.addOn_active : css.addOn+' '+css.addOn_notActive} onClick={onClick_addOn}>
                <FontAwesomeIcon className={buttonClicked? css.addOnIcon+' '+css.addOnIcon_active : css.addOnIcon+' '+css.addOnIcon_notActive} icon={icon}/>
            </button>
        :
            null
        }
    </>);
}

export default PageButton