import css from './index.module.css'
import PropTypes from 'prop-types';
import { useState } from 'react';
/** */
import { useToken } from '../../context/auth';
/**font awesome */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid, regular, brands } from '@fortawesome/fontawesome-svg-core/import.macro' // <-- import styles to be used
/** */
import { domain } from '../../context/variables'
import { useEffect } from 'react';
import renderDraggableWindow, { killDraggableWindow } from '../../components/DraggableWindow'
import SignUp from './SignUp';

async function loginUser(credentials) {
    return fetch('/api/login', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(credentials)
    })
        .then(data => {return data.json()})
}

function Login(props) {
    const {
        setToken,
        children
    } = props;

    const [username, setUsername] = useState();
    const [password, setPassword] = useState();


    const handleLogin = async e => {
        e.preventDefault();
        const getToken = await loginUser({
            username,
            password
        });
        setToken(getToken);
        if( getToken.nekot ) { 
            window.location.reload() 
        } else {
            alert('Login failed. Check your username and password.')
        };
    }

    return(
        <div className={css.container}>
            <a className={css.iconContainer} href='https://www.bnpbeautysupply.com'>
                <img className={css.icon} src={'/SVG/logo-bnp-icon-0.svg'} />
            </a>            
            <form className={css.form} onSubmit={handleLogin}>
                <label className={css.formRow}>
                    <div className={css.formRowIcon} >
                        <FontAwesomeIcon icon={solid('user')} inverse/>
                    </div>
                    <input className={css.formRowInput} type="text" onChange={e => setUsername(e.target.value)}/>
                </label>
                <label className={css.formRow}>
                    <div className={css.formRowIcon} >
                        <FontAwesomeIcon icon={solid('key')} inverse/>
                    </div>
                    <input className={css.formRowInput} type="password" onChange={e => setPassword(e.target.value)}/>
                </label>
                <button className={css.formLoginButton} type="submit">{' LOG IN '}</button>
            </form>
{/* turn off register button 
            <button className={css.signUpButton} onClick={()=>{
                renderDraggableWindow({
                    spawnPosition: [370,130],//[0,0],
                    spawnContent: children,//</div><Login token={token} setToken={setToken}/>,
                    spawnTitle: ' \xa0 BNP warehouse',
                    spawnSize: [280,400],
                    expandSize: [350,450],//[1111,900],
                    expanded: true,
                    type: 'REGISTER'
                });
            }}>{' REGISTER '}</button>
*/}
            <a className={css.formForgotPasswordContainer} href={`${domain}`} onClick={()=>{alert('lol')}}>
                forgot password?
            </a>
        </div>
    )
}

export default Login;


Login.propTypes = {
    setToken: PropTypes.func.isRequired
}