import ApolloTable from "../../../../components/ApolloTable";
/**css */
import './index.css';
/**config */
import config from './config';

const filter = (arr) => {
    let result = [];
    arr.forEach(element => {
        if (element.need_purchase) {result.push(element)}
    });
    return result;
}
function Cgb() {
    return(
        <div className={'Cgb_container'}>
            <ApolloTable
                cssPrefix={'Cgb'}
                query={config.parent.query} 
                columns={config.parent.columns} 
                table={config.parent.table} 
                //dataModifier={filter}
            >
                <ApolloTable 
                    query={config.child0.query} 
                    columns={config.child0.columns} 
                    table={config.child0.table} 
                    variables={config.child0.variables} 
                    //dataModifier={filter}
                />
            </ApolloTable>

        </div>
    )
}

export default Cgb;