/**css */
import css from './index.module.css';
import './index.css';
/**config */
import config from './config';
/**react */
import { useState } from 'react';
/** */
import renderDraggableWindow from '../DraggableWindow'
import ApolloTable from '../ApolloTable';
/**font awesome */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid, regular, brands } from '@fortawesome/fontawesome-svg-core/import.macro' // <-- import styles to be used

export function SearchPanel(props) {
    const {

    } = props;

    const [searchQuery, setSearchQuery] = useState('');
    const onClick_search = () => {

    }
    return(
        <div className={css.container}>
            <div className={css.searchContainer}>
                <input className={css.searchBox} onChange={(e)=>{setSearchQuery(e.target.value)}} type='text'/>
                <button 
                    className={css.searchBtn}
                    onClick = {(e)=>{ 
                        renderDraggableWindow({
                            spawnPosition: [0,0],
                            spawnSize: [600,300],
                            expandSize: [1280,800],
                            spawnTitle: `${searchQuery}`,
                            spawnContent: <div className={'SEARCH_master_container'}>
                                <ApolloTable 
                                    cssPrefix={'SEARCH'}  
                                    query={config.parent.query} 
                                    columns={config.parent.columns} 
                                    table={config.parent.table}
                                    variables={{searchQuery: searchQuery}}
                                />
                            </div>,//<h1>{`searched: ${searchQuery}`}</h1>,
                            type: 'SEARCH',
                            expanded: true
                        });
                    }}
                >Search</button>
            </div>
            <div className={css.functionContainer}>
            <button 
                    className={'tinyBtnIcon'}
                    onClick = {(e)=>{ 
                        renderDraggableWindow({
                            spawnPosition: [300,300],
                            spawnSize: [300,300],
                            expandSize: [600,600],
                            spawnTitle: `PWA portal`,
                            spawnContent: <div className={'PWA_master_container'}>
                                <img 
                                    className={'qrPWAimg'}
                                    src = {`https://api.qrserver.com/v1/create-qr-code/?size=250x250&data='https://pwa-dot-bnp-warehouse.nn.r.appspot.com/'`}
                                    alt = {'https://pwa-dot-bnp-warehouse.nn.r.appspot.com/'}
                                />
                            </div>,
                            type: 'PWA',
                            expanded: false
                        });
                    }}
                ><FontAwesomeIcon icon={solid('qrcode')} inverse/></button>
            </div>
        </div>
    )
}

export default SearchPanel