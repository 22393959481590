import { Update } from '../../../../components/ApolloButtons';
/**helper */
import { paramToVariables } from '../../../../helper';
/** */
import { gql, useQuery } from "@apollo/client";
/** */
import { idGetter, decimal_2, decimalChecker, ApolloSelect, render_ApolloInput, ApolloInput } from '../../../../components/ApolloCells'
/** */
import { Insert } from '../../../../components/ApolloButtons';
/**font awesome */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid, regular, brands } from '@fortawesome/fontawesome-svg-core/import.macro' // <-- import styles to be used
/**Apollo Columns */
import { onRender_SKU10 } from '../../../../components/ApolloCells'

/**GQL */
const parent = {/*purchases: [Purchase]*/
    query: gql`query ARRIVED_SHIPMENTs {
        shipments(status: "arrived need stash") {
            id
            id_shipment
            status {
                id
                id_status
                title
            }
            batch_code
            origin
            #
            departure
            est_arrival
            act_arrival
            updated
            inserted
            #
            shipping_fee
            currency_paid
            conversion_rate
            tariff
        }
    }`,
    table: {
        footer: true,
        accessor: 'shipments',
        actions: [
            (index, rowData, onClick_toggleEdit) => {
                return(
                    <Update
                        key = {index}
                        onClick = {()=>{ return {id_shipment: +rowData.id_shipment, status: "lastStatus"} }}
                        variableParser = {(variables)=>{ return {variables}}}
                        GQL = {gql`mutation updateShipment($id_shipment: ID!, $status: String) {
                            updateShipment(id_shipment: $id_shipment, status: $status) {
                                id
                                id_shipment
                                status {
                                    id
                                    id_status
                                    title
                                }
                                batch_code
                                origin
                                #
                                departure
                                est_arrival
                                #act_arrival
                                updated
                                inserted
                                #
                                shipping_fee
                                currency_paid
                                conversion_rate
                                tariff
                            }
                        }`}
                        accessor = {'updateShipment'}
                        updateQuery = {gql`query SHIPMENTs {
                            shipments {
                                id
                                id_shipment
                                status {
                                    id
                                    id_status
                                    title
                                }
                                batch_code
                                origin
                                #
                                departure
                                est_arrival
                                #act_arrival
                                updated
                                inserted
                                #
                                shipping_fee
                                currency_paid
                                conversion_rate
                                tariff
                            }
                        }`}
                        accessor_cacheData = {'shipments'}
                    >反</Update>
                )
            },
        ]
    },
    columns: [
        {
            header: 'status',
            accessor: 'status.title',
            formatter: (value)=>{return value},
            onRender: (value)=>{return value},
        },
        {
            header: 'batch_code',
            accessor: 'batch_code',
            formatter: (value)=>{return value.toUpperCase()},
            onRender: (value)=>{return value},
        },
        {
            header: 'origin',
            accessor: 'origin',
            formatter: (value)=>{
                return value.split('').reduce( (result, current, index, array)=>{
                    switch (array[index-1]){
                        case undefined: return result+current.toUpperCase();
                        case ' ': return result+current.toUpperCase();
                        default: return result+current;
                    }
                },'')
            },
            onRender: (value)=>{return value},
        },
        {
            header: 'departure',
            accessor: 'departure',
            formatter: (value)=>{return value.substring(0, 10)},
            onRender: (value)=>{return value},
        },
        {
            header: 'est_arrival',
            accessor: 'est_arrival',
            formatter: (value)=>{return value.substring(0, 10)},
            onRender: (value)=>{return value},
        },
        {
            header: 'arrival',
            accessor: 'act_arrival',
            formatter: (value)=>{return value.substring(0, 10)},
            onRender: (value)=>{return value},
        },
        {
            header: 'shipping fee',
            accessor: 'shipping_fee',
            formatter: (value)=>{return decimal_2(value)},
            onRender: (value)=>{return value},
        },
        {
            header: 'ccy',
            accessor: 'currency_paid',
            formatter: (value)=>{return value.toUpperCase()},
            onRender: (value)=>{return value},
        },
        {
            header: 'cvr',
            accessor: 'conversion_rate',
            formatter: (value)=>{return decimal_2(value)},
            onRender: (value)=>{return value},
        },
        {
            header: 'tariff',
            accessor: 'tariff',
            formatter: (value)=>{return decimal_2(value)},
            onRender: (value)=>{return value},
        },
        {
            header: 'inserted',
            accessor: 'inserted',
            formatter: (value)=>{return value.substring(0, 10)},
            onRender: (value)=>{return value},
        },           
        {
            header: 'updated',
            accessor: 'updated',
            formatter: (value)=>{return value.substring(0, 10)},
            onRender: (value)=>{return value},
        },
    ],
}

/**GQL */
const child0 = {
    query: gql`query SHIPMENTs($id_shipment: ID) {
        shipments(id_shipment: $id_shipment) {
            purchases {
                id
                id_purchase
                product {
                    id
                    id_product
                    category {
                        id
                        id_category
                        child
                    }
                    SKU10
                    brand
                    title
                    spec_1
                    spec_2
                    spec_3
                    unit
                }
                qty_purchased
                qty_stashing
                qty_stashed
                supplier {
                    company_name
                }
                inserted
            }
        }
    }`,
    table: {
        accessor: 'shipments.0.purchases',
        actions: [
            (index, rowData) => {
                console.log(rowData);
                if (rowData.qty_purchased < rowData.qty_stashing+rowData.qty_stashed) {
                    return(
                    <button className={'faBtnBug'} key={index} onClick={()=>{ 
                        alert('qty_stashed should be lower than qty_purchased');
                    }}>
                        <FontAwesomeIcon icon={solid('bug')} flash/>
                    </button>
                )};
                if (rowData.qty_purchased === rowData.qty_stashing+rowData.qty_stashed) {
                    return(
                    <button className={'faBtnCheck'} key={index} onClick={()=>{ 
                        alert('fully stashed');
                    }}>
                        <FontAwesomeIcon icon={solid('check')}/>
                    </button>
                )};
                return (
                    <Insert
                        key={index}
                        onClick={()=>{
                             //console.log(rowData.product);
                            const max_qty = rowData.qty_purchased - rowData.qty_stashing - rowData.qty_stashed;

                            let qty = prompt(`数量 (MAX ${ max_qty} ${rowData.product.unit})：`, '1');
                            if ( Math.abs(+qty) > max_qty ) {
                                qty = prompt(`数量错误\n请重新输入 (MAX ${max_qty} ${rowData.product.unit})：`, '1');
                            }
                            return { 
                                product: [ 
                                    ['id', rowData.product.id_product.toString() ],
                                ],
                                purchase: [ 
                                    ['id', rowData.id_purchase.toString() ],
                                ],
                                qty: Math.abs(+qty),
                            } 
                        }}
                        variableParser={(values)=>{return { variables: values } }}
                        GQL={gql`mutation BUYTHIS($product: [[String]]!, $purchase: [[String]]!, $qty: Int){
                            insertStashPending(product: $product, purchase: $purchase, qty: $qty) {
                                id
                                id_stash_pending
                                holder{
                                    id
                                    name
                                }
                                product{
                                    id
                                    SKU10
                                }
                                purchase {
                                    id
                                    qty_stashing
                                }
                                qty
                            }
                        }`}
                        accessor={'insertStashPending'}
                        updateQuery={gql`query STASH_PENDINGs {
                            stashPendings(fk_holder: null, inOut: "in") {
                                id
                                id_stash_pending
                                product {
                                    id
                                    id_product
                                    SKU10
                                    brand
                                    title
                                    spec_1
                                    spec_2
                                    spec_3
                                    unit
                                }
                                holder {
                                    id
                                    id_holder
                                    name
                                }
                                qty
                            }
                        }`}
                        accessor_cacheData={'stashPendings'}
                    >入</Insert>
                )
            },
        ]
    },
    columns: [
        {
            header: 'subcat',
            accessor: 'product.category.child',
            formatter: (value)=>{return value},
            onRender: (value)=>{return value},
        },
        {
            header: 'SKU',
            accessor: 'product.SKU10',
            formatter: (value)=>{return value},
            onRender: (value)=>{return onRender_SKU10(value)},
        },
        {
            header: 'brand',
            accessor: 'product.brand',
            formatter: (value)=>{return value},
            onRender: (value)=>{return value},
        },
        {
            header: 'title',
            accessor: 'product.title',
            formatter: (value)=>{return value},
            onRender: (value)=>{return value},
        },
        {
            header: 'spec 1',
            accessor: 'product.spec_1',
            formatter: (value)=>{return value},
            onRender: (value)=>{return value},
        },
        {
            header: 'spec 2',
            accessor: 'product.spec_2',
            formatter: (value)=>{return value},
            onRender: (value)=>{return value},
        },
        {
            header: 'spec 3',
            accessor: 'product.spec_3',
            formatter: (value)=>{return value},
            onRender: (value)=>{return value},
        },
        {
            header: 'purchased',
            accessor: 'qty_purchased',
            formatter: (value)=>{return value},
            onRender: (value)=>{return value},
        },
        {
            header: 'stashing',
            accessor: 'qty_stashing',
            formatter: (value)=>{return value},
            onRender: (value)=>{return value},
        },
        {
            header: 'stashed',
            accessor: 'qty_stashed',
            formatter: (value)=>{return value},
            onRender: (value)=>{return value},
        },
        {
            header: 'unit',
            accessor: 'product.unit',
            formatter: (value)=>{return value},
            onRender: (value)=>{return value},
        },
        {
            header: 'inserted',
            accessor: 'inserted',
            formatter: (value)=>{return value.substring(2, 10)},
            onRender: (value)=>{return value},
        },
        {
            header: 'supplier',
            accessor: 'supplier.company_name',
            formatter: (value)=>{return value},
            onRender: (value)=>{return value},
        },
    ],
    variables: ['id_shipment'],
}

const config = {
    parent,
    child0
}

export default config;