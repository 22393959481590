
/*export function isObjectEmpty(obj){
    return(
            // we have to do some additional check
            //obj // 👈 null and undefined check
            //&& 
            Object.keys(obj).length === 0
            && Object.getPrototypeOf(obj) === Object.prototype
    )

}

export function arrayToKey(obj, element){
    return obj[element];
}*/
export function ifObjExistAndHasKey(obj, key){
    return(
            // we have to do some additional check
            obj // 👈 null and undefined check
            && 
            key in obj
    )

}
/**
 * array of keys, array of dataObjects, what string to return if no data is available
 * @param {Array} accessors 
 * @param {Object} rowData 
 * @param {*} noDataVal 
 * @returns 
 */
export function colDataReducer( accessors, rowData, noDataVal='' ) {
    return (
        accessors.reduce( (accumulator, value, index, arr) => {
            let rawValue = accumulator[value];
            //if data is int 0
            if ( rawValue===0 ) return rawValue;
            //if data is null or empty string
            if ( !rawValue ) {  arr.slice(0,index); return noDataVal }
            return rawValue;
        }, rowData)
    )
}
/**
 * access data with ['a.b','c','d.e.f'] addressBook object
 * it will turn all elements into array of keys
 * then it will traverse through dataMap for those addresses, starting from addressBook[0][0]
 * if key returns undefined skip 'this' key of address[this][n], move on to 'next' key address[next][0]
 * if key returns value, parse the 'last' key address[this][last] as key and bind returned value to it
 * 
 * @param {*} accessorsOfVariablesInArray 
 * @param {*} rowData 
 * @returns 
 */
export function paramToVariables( accessorsOfVariablesInArray, rowData ) {
    const params = accessorsOfVariablesInArray.map( key => {
        return key.split('.');
    });
    const getVariables = params.reduce( (variables, param) => {
        const value = colDataReducer(param, rowData, false);
        return value? `${variables} "${param[param.length-1]}":"${value}",` : variables;
    },'')
    const setVariables = `{"variables":{${getVariables.slice(0,-1)}}}`;
    const variables = JSON.parse( setVariables );

    return variables
}

export function spaceToCamel(stringToChange){
    const splitBySpace = stringToChange.split(' ');
    const stringToCamel = splitBySpace.reduce((accumulator, value, index)=>{
        switch(index) {
            case 0: return accumulator + value;
            default: 
                return accumulator + value[0].toUpperCase() + value.substr(1);
        }
    },'')
    return stringToCamel;
}

export function hasOnlyDigits(value) {
    return /^\d+$/.test(value);
}

export function intStrToDemicalStr(stringVal, demicalPoint) {
    switch (demicalPoint) {
        case 4: switch (stringVal.length) {
            case 0: return `0.0000`;
            case 1: return `0.000${stringVal}`;
            case 2: return `0.00${stringVal}`;
            case 3: return `0.0${stringVal}`;
            case 4: return `0.${stringVal}`;
            default: return stringVal.slice(0,-4)+'.'+stringVal.slice(-4);
        }
         case 2: switch (stringVal.length) {
            case 0: return `0.00`;
            case 1: return `0.0${stringVal}`;
            case 2: return `0.${stringVal}`;
            default: return stringVal.slice(0,-2)+'.'+stringVal.slice(-2);
        } 
    }
}
