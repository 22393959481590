import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
/*apollo-client*/
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
} from "@apollo/client";
//initialize ApolloClient
const authorization = localStorage.getItem('nekot');

export const client = new ApolloClient({
  uri: '/api/graphql',
  cache: new InMemoryCache(),
  //connectToDevTools: true
  credentials: 'omit', //'include' crashes app
  headers: {
    authorization,
  }
});

ReactDOM.render(
  <ApolloProvider client={client}>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </ApolloProvider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();