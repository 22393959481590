import './index.css'
import renderDraggableWindow from './index';
import ApolloTable from '../ApolloTable';

function renderDraggableWindow_History(props) {
    const {
        cssPrefix,
        spawnTitle,
        query,
        columns,
        table,
        variables,
        children
    } = props;
    return renderDraggableWindow({
        spawnPosition: [0,0],
        spawnSize: [600,300],
        expandSize: [1280,800],
        spawnTitle: spawnTitle,
        spawnContent: <div className={'HISTORY_master_container'}>
            <ApolloTable 
                cssPrefix={cssPrefix}  
                query={query} 
                columns={columns} 
                table={table}
                variables={variables}
            >{children}</ApolloTable>
        </div>,
        type: 'HISTORY',
        expanded: true
    })
}
    
export default renderDraggableWindow_History