import { BrowserRouter, Route, Switch } from 'react-router-dom';
/**components*/
import PageButton from '../../components/PageButton';
/**tabs */ 
import Huqd from './tabs/Huqd'
import Dcb from './tabs/Dcb'
import Lybq from './tabs/Lybq'
import Rcb from './tabs/Rcb'
/**font awesome */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid, regular, brands } from '@fortawesome/fontawesome-svg-core/import.macro' // <-- import styles to be used
//
import { renderDraggableWindow_History } from '../../components/DraggableWindow'
//
import addOn from './addOn';
//
import ApolloTable from '../../components/ApolloTable';

const RuTabs = () => {
    return(<>
        <PageButton type='slave' to="/ru">入</PageButton>
        <PageButton type='slave' to="/ru/hyqd">货运清单</PageButton>
        {/*<PageButton to="/ru/hyqd/ls"><FontAwesomeIcon className={'historyBtnIcon'} icon={regular('calendar-check')} size="xs"/></PageButton>*/}
        <PageButton type='slave' 
                    to="/ru/dcb"
                    icon={solid('book')}
                    onClick_addOn={(e) => {
                        renderDraggableWindow_History({
                            cssPrefix: 'DcbH',
                            spawnTitle: '到仓表',
                            query:  addOn.DcbH.query,
                            table: addOn.DcbH.table,
                            columns: addOn.DcbH.columns,
                            children: <ApolloTable 
                                        cssPrefix={'DcbH0'}  
                                        query={addOn.DcbH0.query} 
                                        columns={addOn.DcbH0.columns} 
                                        table={addOn.DcbH0.table}
                                        variables={addOn.DcbH0.variables}
                                    />
                        })
                    }}
        >到仓表</PageButton>
        <PageButton type='slave' to="/ru/lybq">列印标签</PageButton>
        <PageButton 
            type='slave' 
            to="/ru/rcb"
            icon={solid('book')}
            onClick_addOn={(e) => {
                renderDraggableWindow_History({
                    cssPrefix: 'RcbH',
                    spawnTitle: '入仓表',
                    query:  addOn.RcbH.query,
                    table: addOn.RcbH.table,
                    columns: addOn.RcbH.columns,
                    variables: addOn.RcbH.variables
                })
            }}
        >入仓表</PageButton>
    </>)
}

function Ru(props) {
    return(
        <Switch>
            <Route exact path="/ru">
                <ul>
                    <li>shipment</li>
                    <ul>
                        <li>shipment WHERE status='arrived' AND (purchases all stashed) </li>
                        <li>shipment child table purchase or shipment_activity</li>
                    </ul>
                    <li>purchcase activity</li>
                    <ul>
                        <li>purchcase_activity WHERE  </li>
                    </ul>
                    <li>stash activity</li>
                    <ul>
                        <li>{'full list of stash_activity WHERE qty>0 '}</li>
                    </ul>
                </ul>
            </Route>  
            <Route path="/ru/hyqd">
                <Huqd/>
            </Route>
            <Route path="/ru/hyqd/ls">
                <p>{'shipment(arrived) > purchase'} </p>
            </Route>
            <Route path="/ru/dcb">
                <Dcb/>
            </Route>
            <Route path="/ru/lybq">
                <Lybq/>
            </Route>
            <Route path="/ru/rcb">
                <Rcb/>
            </Route>
            
        </Switch>
    )
}

export { RuTabs , Ru }