import { gql } from "@apollo/client";
/**font awesome */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid, regular, brands } from '@fortawesome/fontawesome-svg-core/import.macro' // <-- import styles to be used
/** */
import renderDraggableWindow from '../DraggableWindow'
/**Apollo Columns */
import { onRender_SKU10 } from '../../components/ApolloCells'

/**GQL */
const parent = {
    query: gql`query SEARCHPRODCUTs($searchQuery: String) {
        searchProducts(searchQuery: $searchQuery) {
            id
            id_product
            SKU10
            category {
                id
                id_category
                parent
            }
            brand
            title
            spec_1
            spec_2
            spec_3
            qty_current
            unit
            holders {
                id
                id_holder
            }
        }
    }`,
    table: {
        footer: true,
        accessor: 'searchProducts',
        action: null,
    },
    columns: [
        {           
            header: 'SKU10',
            accessor: 'SKU10',
            formatter: (value)=>{return value},
            onRender: (value)=>{return onRender_SKU10(value)},
        },        
        {           
            header: 'cat',
            accessor: 'category.parent',
            formatter: (value)=>{return value},
            onRender: (value)=>{return value},
        },
        {         
            header: 'brand',//title need to be shown on the UI
            accessor: 'brand',//column name in GQL schema
            formatter: (value)=>{return value},
            onRender: (value)=>{return value},
        },
        {          
            header: 'title',
            accessor: 'title',
            formatter: (value)=>{return value},
            onRender: (value)=>{return value},
        },
        {            
            header: 'spec 1',
            accessor: 'spec_1',
            formatter: (value)=>{return value},
            onRender: (value)=>{return value},
        },
        {         
            header: 'spec 2',
            accessor: 'spec_2',
            formatter: (value)=>{return value},
            onRender: (value)=>{return value},
        },
        {        
            header: 'spec 3',
            accessor: 'spec_3',
            formatter: (value)=>{return value},
            onRender: (value)=>{return value},
        },
        {        
            header: 'qty',
            accessor: 'qty_current',
            formatter: (value)=>{return value},
            onRender: (value)=>{return value},
        },
        {        
            header: 'unit',
            accessor: 'unit',
            formatter: (value)=>{return value},
            onRender: (value)=>{return value},
        },
    ],
};


const config = { 
    parent,
};

export default config