import { Edit, Delete, Insert, Update } from '../../../../components/ApolloButtons';
/**helper */
import { paramToVariables } from '../../../../helper';
/** */
import { gql } from "@apollo/client";
/** */
import { idGetter, decimal_2, decimalChecker, ApolloSelect, render_ApolloInput, ApolloInput } from '../../../../components/ApolloCells'
/**Apollo Columns */
import { onRender_SKU10 } from '../../../../components/ApolloCells'

/**GQL */
const parent = {/*purchases: [Purchase]*/
    query: gql`query SHIPMENTs {
        shipments(status: "not here yet") {
            id
            id_shipment
            status {
                id
                id_status
                title
            }
            batch_code
            origin
            #
            departure
            est_arrival
            #act_arrival
            updated
            inserted
            #
            shipping_fee
            currency_paid
            conversion_rate
            tariff
            user {
                id
                id_user
                alt_name
            }
        }
    }`,
    table: {
        dataAdder: {
            actions: [
                (index, dataToAdd, onClick_getDataToAdd)=>{
                    return(
                        <Insert
                            key={index}
                            onClick={onClick_getDataToAdd}
                            GQL={gql`mutation SHIPMENTs($status: String, $batch_code:String, $origin:String, $departure:Datetime, $est_arrival:Datetime, $shipping_fee:Int, $currency_paid:String, $conversion_rate:Int, $tariff:Int) {
                                insertShipment(status: $status, batch_code:$batch_code, origin:$origin, departure:$departure, est_arrival:$est_arrival, shipping_fee:$shipping_fee, currency_paid:$currency_paid, conversion_rate:$conversion_rate, tariff:$tariff) {
                                    id
                                    id_shipment
                                    status {
                                        id
                                        id_status
                                        title
                                    }
                                    batch_code
                                    origin
                                    #
                                    departure
                                    est_arrival
                                    #act_arrival
                                    updated
                                    inserted
                                    #
                                    shipping_fee
                                    currency_paid
                                    conversion_rate
                                    tariff
                                }
                            }`}
                            variableParser={ (newValues)=>{
                                const variables = { 
                                    variables: {
                                        'status': newValues['status.title'],
                                        'batch_code': newValues['batch_code'],
                                        'conversion_rate': newValues['conversion_rate'],
                                        'shipping_fee': newValues['shipping_fee'],
                                        'currency_paid': newValues['currency_paid'],
                                        'departure': newValues['departure'].substring(0, 10),
                                        'est_arrival': newValues['est_arrival'].substring(0, 10),
                                        'origin': newValues['origin'],
                                        'tariff': newValues['tariff'],
                                    }
                                };
                                return variables
                            } }
                            updateQuery={gql`query SHIPMENTs {
                                shipments(status: "not here yet") {
                                    id
                                    id_shipment
                                    status {
                                        id
                                        id_status
                                        title
                                    }
                                    batch_code
                                    origin
                                    #
                                    departure
                                    est_arrival
                                    #act_arrival
                                    updated
                                    inserted
                                    #
                                    shipping_fee
                                    currency_paid
                                    conversion_rate
                                    tariff
                                }
                            }`}
                            accessor={'insertShipment'}
                            accessor_cacheData = {'shipments'}
                            reusable={true}
                        >增</Insert>
                    )
                }
            ]
        },
        footer: true,
        accessor: 'shipments',
        actions: [
            (index, rowData, onClick_toggleEdit) => {
                return (
                    <Edit
                        key={index}
                        onClick={onClick_toggleEdit}
                        GQL={ gql`mutation updateShipment($id_shipment: ID!, $status: String, $batch_code:String, $origin:String, $departure:Datetime, $est_arrival:Datetime, $shipping_fee:Int, $currency_paid:String, $conversion_rate:Int, $tariff:Int) {
                            updateShipment(id_shipment: $id_shipment, status: $status, batch_code:$batch_code, origin:$origin, departure:$departure, est_arrival:$est_arrival, shipping_fee:$shipping_fee, currency_paid:$currency_paid, conversion_rate:$conversion_rate, tariff:$tariff) {
                                id
                                id_shipment
                                status {
                                    id
                                    id_status
                                    title
                                }
                                batch_code
                                origin
                                #
                                departure
                                est_arrival
                                #act_arrival
                                updated
                                inserted
                                #
                                shipping_fee
                                currency_paid
                                conversion_rate
                                tariff
                            }
                        }`}
                        variableParser={ (newValues)=>{
                            const variables = { 
                                variables: {
                                    id_shipment: newValues.id.split('_')[1],
                                    'status': newValues['status.title'],
                                    'batch_code': newValues['batch_code'],
                                    'shipping_fee': newValues['shipping_fee'],
                                    'conversion_rate': newValues['conversion_rate'],
                                    'currency_paid': newValues['currency_paid'],
                                    'departure': newValues['departure'].substring(0, 10),
                                    'est_arrival': newValues['est_arrival'].substring(0, 10),
                                    'origin': newValues['origin'],
                                    'tariff': newValues['tariff'],
                                }
                            };
                            return variables
                        } }
                    >{{edit:'改', abort:'撤', save:'存'}}</Edit>
                )
            },
            (index, rowData) => { //console.log(paramToVariables( ['id_purchase'], rowData))
                return (
                    <Delete
                        key={index}
                        variables = {paramToVariables( ['id_shipment'], rowData) }
                        GQL = { gql`mutation DELETE_SHIPMENT($id_shipment: ID!){
                            deleteShipment(id_shipment: $id_shipment) {
                                id
                                id_shipment
                                status {
                                    id
                                    id_status
                                    title
                                }
                                batch_code
                                origin
                                #
                                departure
                                est_arrival
                                #act_arrival
                                updated
                                inserted
                                #
                                shipping_fee
                                currency_paid
                                conversion_rate
                                tariff
                            }
                        }`}
                    >删</Delete>
                )
            },
        ]
    },
    columns: [
        {
            header: 'status',
            accessor: 'status.title',
            formatter: (value)=>{return value},
            onRender: (value)=>{return value},
            onEdit: (value, [colAccessor, newValues, setNewValues], formatter) => { 
                newValues[colAccessor] = value;
                return (
                    <ApolloSelect
                    //style in component already. move it out
                        style={{
                            width: '100%',
                            boxSizing: 'border-box',
                            textAlign: 'center',
                            fontFamily: 'Inconsolata, monospace',
                            fontSize: '14px'
                        }}
                        defaultValue={value}
                        onChange={(e)=>{
                            newValues[colAccessor] = e.currentTarget.value;
                            setNewValues(newValues);
                            console.log(newValues)
                        }}
                        GQL={gql`query STATUSs {
                            statuss( skip: "arrived") {
                                id
                                id_status
                                title
                            }
                        }`}
                        accessors={['statuss', ['title']]}
                        valuePicker={['title']}
                    />
                )
            }
        },
        {
            header: 'batch_code',
            accessor: 'batch_code',
            formatter: (value)=>{return value.toUpperCase()},
            onRender: (value)=>{return value},
            onEdit: (value, [colAccessor, newValues, setNewValues], formatter)=>{
                newValues[colAccessor] = value;
                return (
                    <input
                        style={{
                            width: '100%',
                            boxSizing: 'border-box',
                            textAlign: 'center',
                            fontFamily: 'Inconsolata, monospace',
                            fontSize: '14px'
                        }}
                        type='text'
                        defaultValue={formatter(value)}
                        minLength={6}
                        maxLength={6}
                        onChange={(e)=>{
                            //force uppercase on all inputs
                            e.target.value = formatter(e.target.value)
                            newValues[colAccessor] = e.target.value;
                            console.log('value shown', newValues);
                            setNewValues(newValues);
                        }}
                    />
                );
            },
        },
        {
            header: 'origin',
            accessor: 'origin',
            formatter: (value)=>{
                return value.split('').reduce( (result, current, index, array)=>{
                    switch (array[index-1]){
                        case undefined: return result+current.toUpperCase();
                        case ' ': return result+current.toUpperCase();
                        default: return result+current;
                    }
                },'')
            },
            onRender: (value)=>{return value},
            onEdit: (value, [colAccessor, newValues, setNewValues], formatter)=>{
                newValues[colAccessor] = value;
                return (
                    <input
                        style={{
                            width: '100%',
                            boxSizing: 'border-box',
                            textAlign: 'center',
                            fontFamily: 'Inconsolata, monospace',
                            fontSize: '14px'
                        }}
                        minLength={0}
                        maxLength={99}
                        defaultValue={formatter(value)}
                        type={'text'}
                        onChange={(e)=>{
                            //force uppercase on all inputs
                            e.target.value = formatter(e.target.value)
                            newValues[colAccessor] = e.target.value;
                            console.log('value shown', newValues);
                            setNewValues(newValues);
                        }}
                    />
                )
            },
        },
        {
            header: 'departure',
            accessor: 'departure',
            formatter: (value)=>{return value.substring(0, 10)},
            onRender: (value)=>{return value},
            onEdit: (value, [colAccessor, newValues, setNewValues], formatter)=>{
                newValues[colAccessor] = value;
                return (
                    <input
                        style={{
                            width: '100%',
                            boxSizing: 'border-box',
                            textAlign: 'center',
                            fontFamily: 'Inconsolata, monospace',
                            fontSize: '14px'
                        }}
                        type="date"
                        defaultValue={formatter(value)}
                        onChange={(e)=>{
                            //force uppercase on all inputs
                            e.target.value = formatter(e.target.value)
                            newValues[colAccessor] = e.target.value;
                            console.log('value shown', newValues);
                            setNewValues(newValues);
                        }}
                    />
                )
            },
        },
        {
            header: 'est_arrival',
            accessor: 'est_arrival',
            formatter: (value)=>{return value.substring(0, 10)},
            onRender: (value)=>{return value},
            onEdit: (value, [colAccessor, newValues, setNewValues], formatter)=>{
                newValues[colAccessor] = value;
                return (
                    <input
                        style={{
                            width: '100%',
                            boxSizing: 'border-box',
                            textAlign: 'center',
                            fontFamily: 'Inconsolata, monospace',
                            fontSize: '14px'
                        }}
                        type="date"
                        defaultValue={formatter(value)}
                        onChange={(e)=>{
                            //force uppercase on all inputs
                            e.target.value = formatter(e.target.value)
                            newValues[colAccessor] = e.target.value;
                            console.log('value shown', newValues);
                            setNewValues(newValues);
                        }}
                    />
                )
            },
        },
        {
            header: 'shipping fee',
            accessor: 'shipping_fee',
            formatter: (value)=>{return decimal_2(value)},
            onRender: (value)=>{return value},
            onEdit: (value, [colAccessor, newValues, setNewValues], formatter)=>{
                newValues[colAccessor] = value===''? 0 : value;
                return(
                    <input
                        style={{
                            width: '100%',
                            boxSizing: 'border-box',
                            textAlign: 'center',
                            fontFamily: 'Inconsolata, monospace',
                            fontSize: '14px'
                        }}
                        type="number"
                        min='0.00'
                        step='0.01'
                        defaultValue={formatter(value)}
                        onChange={(e)=>{
                            //typeof e.target.value === 'string'
                            const restoreValue = decimalChecker( e.target.value );
                            newValues[colAccessor] = parseInt(restoreValue);
                            //console.log('value shown', newValues);
                            setNewValues(newValues);
                        }}
                    />
                )
            },
        },
        {
            header: 'ccy',
            accessor: 'currency_paid',
            formatter: (value)=>{return value.toUpperCase()},
            onRender: (value)=>{return value},
            onEdit: (value, [colAccessor, newValues, setNewValues], formatter)=>{
                newValues[colAccessor] = value;
                return(
                    <input
                        style={{
                            width: '100%',
                            boxSizing: 'border-box',
                            textAlign: 'center',
                            fontFamily: 'Inconsolata, monospace',
                            fontSize: '14px'
                        }}
                        type="text"
                        minLength={3}
                        maxLength={3}
                        defaultValue={formatter(value)}
                        onChange={(e)=>{
                            //force uppercase on all inputs
                            e.target.value = formatter(e.target.value)
                            newValues[colAccessor] = e.target.value;
                            console.log('value shown', newValues);
                            setNewValues(newValues);
                        }}
                    />
                )
            },
        },
        {
            header: 'cvr',
            accessor: 'conversion_rate',
            formatter: (value)=>{return decimal_2(value)},
            onRender: (value)=>{return value},
            onEdit: (value, [colAccessor, newValues, setNewValues], formatter)=>{
                newValues[colAccessor] = value===''? 0 : value;
                return(
                    <input
                        style={{
                            width: '100%',
                            boxSizing: 'border-box',
                            textAlign: 'center',
                            fontFamily: 'Inconsolata, monospace',
                            fontSize: '14px'
                        }}
                        type="number"
                        min='0.00'
                        step='0.01'
                        defaultValue={formatter(value)}
                        onChange={(e)=>{
                            //typeof e.target.value === 'string'
                            const restoreValue = decimalChecker( e.target.value );
                            newValues[colAccessor] = parseInt(restoreValue);
                            //console.log('value shown', newValues);
                            setNewValues(newValues);
                        }}
                    />
                )
            },
        },
        {
            header: 'tariff',
            accessor: 'tariff',
            formatter: (value)=>{return decimal_2(value)},
            onRender: (value)=>{return value},
            onEdit: (value, [colAccessor, newValues, setNewValues], formatter)=>{
                newValues[colAccessor] = value===''? 0 : value;
                return(
                    <input
                        style={{
                            width: '100%',
                            boxSizing: 'border-box',
                            textAlign: 'center',
                            fontFamily: 'Inconsolata, monospace',
                            fontSize: '14px'
                        }}
                        type="number"
                        min='0.00'
                        step='0.01'
                        defaultValue={formatter(value)}
                        onChange={(e)=>{
                            //typeof e.target.value === 'string'
                            const restoreValue = decimalChecker( e.target.value );
                            newValues[colAccessor] = parseInt(restoreValue);
                            //console.log('value shown', newValues);
                            setNewValues(newValues);
                        }}
                    />
                )
            },
        },
        {
            header: 'inserted',
            accessor: 'inserted',
            formatter: (value)=>{return value.substring(0, 10)},
            onRender: (value)=>{return value},
        },           
        {
            header: 'updated',
            accessor: 'updated',
            formatter: (value)=>{return value.substring(0, 10)},
            onRender: (value)=>{return value},
        },
        {
            header: 'user',
            accessor: 'user.alt_name',
            formatter: (value)=>{return value},
            onRender: (value)=>{return value},
        },
    ],
}

/**GQL */
const child0 = {
    query: gql`query SHIPMENTs($id_shipment: ID) {
        shipments(id_shipment: $id_shipment) {
            purchases {
                id
                id_purchase
                shipment {
                    id
                    id_shipment
                    batch_code
                }
                product {
                    category {
                        id
                        id_category
                        child
                    }
                    id
                    id_product
                    SKU10
                    brand
                    title
                    spec_1
                    spec_2
                    spec_3
                    unit
                }
                qty_purchased
                supplier {
                    company_name
                }
                inserted
                note
                currency_paid
                conversion_rate
                cost_per_unit
                supplier {
                    id
                    id_supplier
                    company_name
                }
            }
        }
    }`,
    table: {
        accessor: 'shipments.0.purchases',
        actions: [
            (index, rowData, onClick_toggleEdit) => {
                return (
                    <Edit
                        key={index}
                        onClick={onClick_toggleEdit}
                        variableParser={ (newValues)=>{
                            console.log(rowData)
                            const variables = { 
                                variables: {
                                    id_purchase: rowData.id.split('_')[1],
                                    qty_purchased: +rowData.qty_purchased,
                                    cost_per_unit: +rowData.cost_per_unit,
                                    conversion_rate: +rowData.conversion_rate,
                                    batch_code: rowData.shipment.batch_code, //could easily be id
                                    company_name: rowData.supplier.company_name,
                                    currency_paid: rowData.currency_paid,
                                    note: newValues['note'],
                                }
                            };
                            console.log(variables)
                            return variables
                        } }
                        GQL={ gql`mutation updatePurchase($id_purchase: ID!, $qty_purchased: Int, $cost_per_unit: Int, $conversion_rate: Int, $batch_code: String, $company_name: String, $currency_paid: String, $note: String){
                            updatePurchase(id_purchase: $id_purchase, qty_purchased: $qty_purchased, cost_per_unit:$cost_per_unit, conversion_rate:$conversion_rate, batch_code:$batch_code, company_name:$company_name, currency_paid:$currency_paid, note:$note) {
                                id
                                id_purchase
                                cost_per_unit
                                shipment {
                                    id
                                    id_shipment
                                    batch_code
                                    departure
                                    est_arrival
                                    act_arrival
                                }
                                product {
                                    id
                                    id_product
                                    SKU10
                                    brand
                                    title
                                    spec_1
                                    spec_2
                                    spec_3
                                    qty_current
                                    unit
                                    category {
                                        id
                                        parent
                                        child
                                    }
                                    need_purchase
                                }
                                qty_purchased
                                total_cost
                                conversion_rate
                                currency_paid
                                supplier {
                                    id
                                    id_supplier
                                    company_name
                                }
                                updated
                                note
                            }
                        }`}
                    >{{edit:'改', abort:'撤', save:'存'}}</Edit>
                )
            },
            (index, rowData) => { //console.log(paramToVariables( ['id_purchase'], rowData))
                return (
                    <Update
                        key = {index}
                        onClick = {()=>{ return {
                            id_purchase: rowData.id.split('_')[1],
                            qty_purchased: +rowData.qty_purchased,
                            cost_per_unit: +rowData.cost_per_unit,
                            conversion_rate: +rowData.conversion_rate,
                            batch_code: '',//rowData.shipment.batch_code, //could easily be id
                            company_name: rowData.supplier.company_name,
                            currency_paid: rowData.currency_paid,
                            note: rowData.note,
                        }}}
                        variableParser = {(variables)=>{ return {variables}}}

                        GQL={ gql`mutation updatePurchase($id_purchase: ID!, $qty_purchased: Int, $cost_per_unit: Int, $conversion_rate: Int, $batch_code: String, $company_name: String, $currency_paid: String, $note: String){
                            updatePurchase(id_purchase: $id_purchase, qty_purchased: $qty_purchased, cost_per_unit:$cost_per_unit, conversion_rate:$conversion_rate, batch_code:$batch_code, company_name:$company_name, currency_paid:$currency_paid, note:$note) {
                                id
                                id_purchase
                                shipment {
                                    id
                                    id_shipment
                                }
                            }
                        }`}
                        accessor = {'shipments'}
                        updateQuery = {gql`query SHIPMENTs {
                            shipments {
                                id
                                id_shipment
                                status {
                                    id
                                    id_status
                                    title
                                }
                                batch_code
                                origin
                                #
                                departure
                                est_arrival
                                #act_arrival
                                updated
                                inserted
                                #
                                shipping_fee
                                currency_paid
                                conversion_rate
                                tariff
                            }
                        }`}
                        accessor_cacheData = {'shipments'}
                    >反</Update>
                )
            }
        ]
    },
    columns: [
        {
            header: 'inserted',
            accessor: 'inserted',
            formatter: (value)=>{return value.substring(5, 10)},
            onRender: (value)=>{return value},
        },
        {
            header: 'subcat',
            accessor: 'product.category.child',
            formatter: (value)=>{return value},
            onRender: (value)=>{return value},
        },
        {
            header: 'SKU',
            accessor: 'product.SKU10',
            formatter: (value)=>{return value},
            onRender: (value)=>{return onRender_SKU10(value)},
        },
        {
            header: 'brand',
            accessor: 'product.brand',
            formatter: (value)=>{return value},
            onRender: (value)=>{return value},
        },
        {
            header: 'title',
            accessor: 'product.title',
            formatter: (value)=>{return value},
            onRender: (value)=>{return value},
        },
        {
            header: 'spec 1',
            accessor: 'product.spec_1',
            formatter: (value)=>{return value},
            onRender: (value)=>{return value},
        },
        {
            header: 'spec 2',
            accessor: 'product.spec_2',
            formatter: (value)=>{return value},
            onRender: (value)=>{return value},
        },
        {
            header: 'spec 3',
            accessor: 'product.spec_3',
            formatter: (value)=>{return value},
            onRender: (value)=>{return value},
        },
        {
            header: 'qty',
            accessor: 'qty_purchased',
            formatter: (value)=>{return value},
            onRender: (value)=>{return value},
        },
        {
            header: 'unit',
            accessor: 'product.unit',
            formatter: (value)=>{return value},
            onRender: (value)=>{return value},
        },
        {
            header: 'supplier',
            accessor: 'supplier.company_name',
            formatter: (value)=>{return value},
            onRender: (value)=>{return value},
        },
        {
            header: 'note',
            accessor: 'note',
            formatter: (value)=>{return value},
            onRender: (value)=>{return value},
            onEdit: (value, [colAccessor, newValues, setNewValues], formatter) => {
                //console.log(newValues);
                newValues[colAccessor] = value;
                return  (
                    <input
                        style={{
                            width: '100%',
                            boxSizing: 'border-box',
                            textAlign: 'left',
                            fontFamily: 'Inconsolata, monospace',
                            fontSize: '14px'
                        }}
                        type={'text'} 
                        defaultValue={formatter(value)}
                        onChange={(e)=>{
                            //typeof e.target.value === 'string'
                            newValues[colAccessor] = e.target.value;
                            //console.log('value shown', newValues);
                            setNewValues(newValues);
                        }}
                    />
                )
            }
        },
    ],
    variables: ['id_shipment'],
}

const config = {
    parent,
    child0
}
export default config;