/**css */
import './index.css';
/**react */
import { useState, useEffect } from 'react';
/**font awesome */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid, regular, brands } from '@fortawesome/fontawesome-svg-core/import.macro' // <-- import styles to be used
/**apollo-client*/
import { useQuery, useMutation } from "@apollo/client";
import { Children } from 'react';

function ApolloButton_Edit(props) {
    const { 
        onClick,
        //updateQuery, // needed to be updated
        //accessor_cacheData, //usually first key for updateQuery result
        GQL, 
        //accessor, //the first key for GQL(mutation) result
        variableParser ,
        children
    } = props;
    const [toggleEdit, setToggleEdit] = useState();
    //handles the state of this button group. mirroring the state of row toggle
    const onClickHandler_toggleEdit = () => {
        //onClick is onClick_toggleEdit from ApolloTable. it returns both toggle<bool> and form values<obj>
        const {nextToggle, newValues} = onClick();
        setToggleEdit( nextToggle );
        //console.log('inputs',newValues);
    }

    const [runMutation, { data, loading, error }] = useMutation(GQL, {
/*  magically, i didnt need to update cache.... lets leave this here for now    
        update(cache, mutationResult) {
            //retrieve the data needed to be updated in cache
            const oldData = cache.readQuery({query:updateQuery});
            //if it doesnt exist abort
            if (! oldData) return;
            //if the needed key in it doesnt exist abort
            if (! accessor_cacheData in oldData) return;
            //construct new cache data by appending new data to old data
            const newData = {};
            //unlike insert, we need to look for the child that changed, then replace it out
            const updateChild = [...oldData[accessor_cacheData]].find(child => child.id == mutationResult.data[accessor].id);
            console.log( updateChild );

            newData[accessor_cacheData] = [ ...oldData.purchases, mutationResult.data[accessor] ];
            console.log( newData );
            cache.writeQuery({
                query: updateQuery,
                data: newData
            });
        }
*/
    });

    const onClickHandler_save = () => {
        

        const {nextToggle, newValues} = onClick();
        //console.log('should update to this', newValues);
        runMutation(
            variableParser(newValues)
        );
        
        setToggleEdit( nextToggle );

        console.log('updated to',variableParser(newValues));
    }
    if (loading) return <button className={'faBtnCircleNotch'}><FontAwesomeIcon icon={solid('circle-notch')} spin/></button>;
    if (error) {console.log(JSON.stringify(error, null, 2)); return `${error}`};
    if (toggleEdit) {
        return(<>
            <button  className={'aplBtnAbort'} onClick={onClickHandler_toggleEdit}>{children.abort}</button>
            <button  className={'aplBtnSave'} onClick={onClickHandler_save}>{children.save}</button>
        </>)
    }

    return(
        <button  className={'aplBtnEdit'} onClick={onClickHandler_toggleEdit}>{children.edit}</button>
    )
}

export default ApolloButton_Edit;