import ApolloTable from "../../../../components/ApolloTable";
/**css */
import './index.css';
/**config */
import config from './config';

const filter = (arr) => {
    let result = [];
    arr.forEach(element => {
        //if (element.need_purchase) {result.push(element)}
        if ( element.qty_current===0 || element.need_purchase) {result.push(element)}
    });
    return result;
}
function Tjqd() {
    return(
        <div className={'Tjqd_container'}>
            <ApolloTable
                cssPrefix={'Tjqd'}
                query={config.parent.query} 
                columns={config.parent.columns} 
                table={config.parent.table} 
                /*dataModifier={filter}*/
            />
        </div>
    )
}

export default Tjqd;