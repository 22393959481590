import { gql } from "@apollo/client";
/**Apollo Columns */
import { onRender_SKU10 } from '../../components/ApolloCells'
const SpzbH = {
    query: gql`query STASHACTIVITYs_QTY_CORRECTION {
        stashActivitys {
            id
            id_stash_activity
            inserted
            updated
            qty
            product {     
                id
                id_product           
                category {
                    id
                    id_category
                    parent
                }
                SKU10
                brand
                title
                spec_1
                spec_2
                spec_3
                unit
            }
            holder {
                id
                id_holder
                name
                location {
                    id
                    id_location
                    spot
                    shelf
                    floor
                }
            }
            destination {
                id
                id_destination
                title
            }
            user {
                id
                id_user
                alt_name
            }
        }
    }`,
    table: {
        footer: true,
        accessor: 'stashActivitys',
    },
    columns: [
        {           
            header: 'time',
            accessor: 'updated',
            formatter: (value)=>{return value.substring(0, 10)},
            onRender: (value)=>{return value},
        }, 
        {           
            header: 'spot',
            accessor: 'holder.location.spot',
            formatter: (value)=>{return value},
            onRender: (value)=>{return value},
        },  
        {           
            header: 'shelf',
            accessor: 'holder.location.shelf',
            formatter: (value)=>{return value},
            onRender: (value)=>{return value},
        },  
        {           
            header: 'floor',
            accessor: 'holder.location.floor',
            formatter: (value)=>{return value},
            onRender: (value)=>{return value},
        },  
        {           
            header: 'name',
            accessor: 'holder.name',
            formatter: (value)=>{return value},
            onRender: (value)=>{return value},
        },  
        {           
            header: 'SKU10',
            accessor: 'product.SKU10',
            formatter: (value)=>{return value},
            onRender: (value)=>{return onRender_SKU10(value)},
        }, 
        {           
            header: 'cat',
            accessor: 'product.category.parent',
            formatter: (value)=>{return value},
            onRender: (value)=>{return value},
        },
        {           
            header: 'brand',
            accessor: 'product.brand',
            formatter: (value)=>{return value},
            onRender: (value)=>{return value},
        }, 
        {           
            header: 'title',
            accessor: 'product.title',
            formatter: (value)=>{return value},
            onRender: (value)=>{return value},
        }, 
        {           
            header: 'qty',
            accessor: 'qty',
            formatter: (value)=>{return value},
            onRender: (value)=>{return value},
        },  
        {           
            header: 'unit',
            accessor: 'product.unit',
            formatter: (value)=>{return value},
            onRender: (value)=>{return value},
        },  
        {           
            header: 'purpose',
            accessor: 'destination.title',
            formatter: (value)=>{return value},
            onRender: (value)=>{return value},
        },
        {
            header: 'user',
            accessor: 'user.alt_name',
            formatter: (value)=>{return value},
            onRender: (value)=>{return value},
        },
    ]
}

const CkzbH = {
    query: gql`query HOLDERACTIVITYs_ALL {
        holderActivitys {
            id
            id_holder_activity
            inserted
            updated
            holder {
                id
                id_holder
                name
            }
            location {
                id
                id_location
                spot
                shelf
                floor
            }
            user {
                id
                id_user
                alt_name
            }
        }
    }`,
    table: {
        footer: true,
        accessor: 'holderActivitys',
    },
    columns: [
        {           
            header: 'time',
            accessor: 'updated',
            formatter: (value)=>{return value.substring(0, 10)},
            onRender: (value)=>{return value},
        }, 
        {           
            header: 'spot',
            accessor: 'location.spot',
            formatter: (value)=>{return value},
            onRender: (value)=>{return value},
        },  
        {           
            header: 'shelf',
            accessor: 'location.shelf',
            formatter: (value)=>{return value},
            onRender: (value)=>{return value},
        },  
        {           
            header: 'floor',
            accessor: 'location.floor',
            formatter: (value)=>{return value},
            onRender: (value)=>{return value},
        },  
        {           
            header: 'name',
            accessor: 'holder.name',
            formatter: (value)=>{return value},
            onRender: (value)=>{return value},
        },
        {
            header: 'user',
            accessor: 'user.alt_name',
            formatter: (value)=>{return value},
            onRender: (value)=>{return value},
        },
    ]
}

const CcbH = {
    query: gql`query STASHACTIVITYs_OUT($inOut: String) {
        stashActivitys(inOut: $inOut) {
            id
            id_stash_activity
            inserted
            updated
            qty
            product {     
                id
                id_product           
                category {
                    id
                    id_category
                    parent
                }
                SKU10
                brand
                title
                spec_1
                spec_2
                spec_3
                unit
            }
            holder {
                id
                id_holder
                name
                location {
                    id
                    id_location
                    spot
                    shelf
                    floor
                }
            }
            destination {
                id
                id_destination
                title
            }
            user {
                id
                id_user
                alt_name
            }
        }
    }`,
    table: {
        footer: true,
        accessor: 'stashActivitys',
    },
    columns: [
        {           
            header: 'time',
            accessor: 'updated',
            formatter: (value)=>{return value.substring(0, 10)},
            onRender: (value)=>{return value},
        }, 
        {           
            header: 'spot',
            accessor: 'holder.location.spot',
            formatter: (value)=>{return value},
            onRender: (value)=>{return value},
        },  
        {           
            header: 'shelf',
            accessor: 'holder.location.shelf',
            formatter: (value)=>{return value},
            onRender: (value)=>{return value},
        },  
        {           
            header: 'floor',
            accessor: 'holder.location.floor',
            formatter: (value)=>{return value},
            onRender: (value)=>{return value},
        },  
        {           
            header: 'name',
            accessor: 'holder.name',
            formatter: (value)=>{return value},
            onRender: (value)=>{return value},
        },  
        {           
            header: 'SKU10',
            accessor: 'product.SKU10',
            formatter: (value)=>{return value},
            onRender: (value)=>{return onRender_SKU10(value)},
        }, 
        {           
            header: 'cat',
            accessor: 'product.category.parent',
            formatter: (value)=>{return value},
            onRender: (value)=>{return value},
        },
        {           
            header: 'brand',
            accessor: 'product.brand',
            formatter: (value)=>{return value},
            onRender: (value)=>{return value},
        }, 
        {           
            header: 'title',
            accessor: 'product.title',
            formatter: (value)=>{return value},
            onRender: (value)=>{return value},
        }, 
        {           
            header: 'qty',
            accessor: 'qty',
            formatter: (value)=>{return value},
            onRender: (value)=>{return value},
        },  
        {           
            header: 'unit',
            accessor: 'product.unit',
            formatter: (value)=>{return value},
            onRender: (value)=>{return value},
        },  
        {           
            header: 'purpose',
            accessor: 'destination.title',
            formatter: (value)=>{return value},
            onRender: (value)=>{return value},
        },
        {
            header: 'user',
            accessor: 'user.alt_name',
            formatter: (value)=>{return value},
            onRender: (value)=>{return value},
        },
    ],
    variables: {
        "inOut": "out"
    }
}

const addOn = {
    SpzbH,
    CkzbH,
    CcbH,
}

export default addOn