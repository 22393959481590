import { gql } from "@apollo/client";
/**font awesome */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid, regular, brands } from '@fortawesome/fontawesome-svg-core/import.macro' // <-- import styles to be used
/** */
import renderDraggableWindow from '../../../../components/DraggableWindow'
import { isNonEmptyArray } from "@apollo/client/utilities";
import { Insert, Edit } from '../../../../components/ApolloButtons';
import { ApolloSelect } from '../../../../components/ApolloCells'
/** */ 
import { productImgLink } from '../../../../context/variables'
/**Apollo Columns */
import { onRender_SKU10 } from '../../../../components/ApolloCells'
import { intStrToDemicalStr } from '../../../../helper';
//
import { renderKeywordNotepad } from '../../../Cai/tabs/Xzsp/Notepad'

/**GQL */
const parent = {
    query: gql`query PRODUCTFAMILYS {
        productFamilys {
            id
            SKU04
            COUNT_variants
            category {
                id
                parent
                child
            }
            brand
            title
            COUNT_spec_1
            COUNT_spec_2
            COUNT_spec_3
            
        }
    }`,
    table: {
        footer: true,
        accessor: 'productFamilys',
        action: null,
    },
    columns: [
        {
            header: 'SKU04',
            accessor: 'SKU04',
            formatter: (value)=>{return value},
            onRender: (value)=>{return value},
        },
        {           
            header: 'SKU10s',
            accessor: 'COUNT_variants',
            formatter: (value)=>{return value},
            onRender: (value)=>{return value},
        },        
        {           
            header: 'cat',
            accessor: 'category.parent',
            formatter: (value)=>{return value},
            onRender: (value)=>{return value},
        },
        {         
            header: 'subcat',
            accessor: 'category.child',
            formatter: (value)=>{return value},
            onRender: (value)=>{return value},
        },
        {         
            header: 'brand',//title need to be shown on the UI
            accessor: 'brand',//column name in GQL schema
            formatter: (value)=>{return value},
            onRender: (value)=>{return value},
        },
        {          
            header: 'title',
            accessor: 'title',
            formatter: (value)=>{return value},
            onRender: (value)=>{return value},
        },
        {            
            header: 'spec 1s',
            accessor: 'COUNT_spec_1',
            formatter: (value)=>{return value},
            onRender: (value)=>{return value},
        },
        {         
            header: 'spec 2s',
            accessor: 'COUNT_spec_2',
            formatter: (value)=>{return value},
            onRender: (value)=>{return value},
        },
        {        
            header: 'spec 3s',
            accessor: 'COUNT_spec_3',
            formatter: (value)=>{return value},
            onRender: (value)=>{return value},
        },
    ],
};

/**GQL */
const child0 = {
    query: gql`query PRODUCTFAMILY_PRODUCTS($SKU04: String!) {
        productFamilys(SKU04: $SKU04) {
            id
            products {
                id
                id_product
                SKU10
                spec_1
                spec_2
                spec_3
                qty_current
                qty_future
                unit
                msrp
                keywords
            }
        }
    }`,
    table: {
        accessor: 'productFamilys.0.products',
        actions: [ //action button null. what do in table generator. ****param is nest what do? *****
            (index, rowData) => {
                return (
                    <Insert
                        key={index}
                        onClick={()=>{return { fk_product: rowData['id_product'] } }}
                        variableParser={(values)=>{return { variables: values } }}
                        GQL={gql`mutation BUYTHIS($fk_product: ID!){
                            insertPurchase(fk_product: $fk_product) {
                                id
                                id_purchase
                                shipment {
                                    id
                                    id_shipment
                                    batch_code
                                    departure
                                    est_arrival
                                    act_arrival
                                }
                                product {
                                    id
                                    id_product
                                    SKU10
                                    brand
                                    title
                                    spec_1
                                    spec_2
                                    spec_3
                                    qty_current
                                    unit
                                    category {
                                        id
                                        parent
                                        child
                                    }
                                    need_purchase
                                }
                                qty_purchased
                            }
                        }`}
                        accessor={'insertPurchase'}
                        updateQuery={gql`query Purchase {
                            purchases {
                                id
                                id_purchase
                                shipment {
                                    id
                                    id_shipment
                                    batch_code
                                    departure
                                    est_arrival
                                    act_arrival
                                }
                                product {
                                    id
                                    id_product
                                    SKU10
                                    brand
                                    title
                                    spec_1
                                    spec_2
                                    spec_3
                                    qty_current
                                    unit
                                    category {
                                        id
                                        parent
                                        child
                                    }
                                    need_purchase
                                }
                                qty_purchased
                            }
                        }`}
                        accessor_cacheData={'purchases'}
                    >采</Insert>
                )
            },
        ]
    },
    columns: [
        {
            header: 'SKU10',
            accessor: 'SKU10',
            formatter: (value)=>{return value},
            onRender: (value)=>{return onRender_SKU10(value)},
        },
        {
            header: 'spec 1',
            accessor: 'spec_1',
            formatter: (value)=>{return value},
            onRender: (value)=>{return value},
        },
        {
            header: 'spec 2',
            accessor: 'spec_2',
            formatter: (value)=>{return value},
            onRender: (value)=>{return value},
        },
        {
            header: 'spec 3',
            accessor: 'spec_3',
            formatter: (value)=>{return value},
            onRender: (value)=>{return value},
        },
        {
            header: 'qty',
            accessor: 'qty_current',
            formatter: (value)=>{return value},
            onRender: (value)=>{return value},
        },
        {
            header: 'ordered',
            accessor: 'qty_future',
            formatter: (value)=>{return value},
            onRender: (value)=>{return value},
        },
        {
            header: 'unit',
            accessor: 'unit',
            formatter: (value)=>{return value},
            onRender: (value)=>{return value},
        },
        {
            header: 'msrp',
            accessor: 'msrp',
            formatter: (value)=>{return intStrToDemicalStr(value.toString(),2)},
            onRender: (value)=>{return value},
        },
        {
            header: 'keywords',
            accessor: 'keywords',
            formatter: (value)=>{ return value },
            onRender: (value, rowData) => { return renderKeywordNotepad(value, rowData) }
        },
    ],
    variables: ['SKU04']
};

/**GQL */
const child1 = {
    query: gql`query PRODUCTS_PRODUCT($SKU10: String!) {
        products(SKU10: $SKU10) {
            id
            holders {
                id
                name
                location {
                    id 
                    spot
                    shelf
                    floor
                }
                inserted
            }
        }
    }`,
    table: {
        accessor: 'products.0.holders',
        actions:[
            (index, rowData, onClick_toggleEdit) => {
                return (
                    <Edit 
                        key={index}
                        onClick={onClick_toggleEdit}
                        GQL={ gql`mutation move_Holder($holder: [[String]], $location:[[String]]) {
                            move_Holder(holder: $holder, location: $location) {
                                id
                                id_holder
                                name
                                location {
                                    id
                                    id_location
                                    spot
                                    shelf
                                    floor
                                }
                            }
                        }`}
                        variableParser={ (newValues)=>{
                            const variables = { 
                                variables: {
                                    'holder': [
                                        ['id', newValues.id.split('_')[1] ]
                                    ],
                                    'location': [
                                        [ 'spot', newValues['location.spot'] ],
                                        [ 'shelf', newValues['location.shelf'] ],
                                        [ 'floor', newValues['location.floor'] ],
                                    ]
                                }
                            };
                            return variables
                        } }                      
                    >{{edit:'移', abort:'撤', save:'存'}}</Edit>
                )
            },
        ]
    },
    columns: [
        {
            header: 'name',
            accessor: 'name',
            formatter: (value)=>{return value},
            onRender: (value)=>{return value},
        },
        {
            header: 'spot',
            accessor: 'location.spot',
            formatter: (value)=>{return value},
            onRender: (value)=>{return value},
            onEdit: (value, [colAccessor, newValues, setNewValues], formatter) => { 
                newValues[colAccessor] = value;
                return (
                    <ApolloSelect
                    //style in component already. move it out
                        style={{
                            width: '100%',
                            boxSizing: 'border-box',
                            textAlign: 'center',
                            fontFamily: 'Inconsolata, monospace',
                            fontSize: '14px'
                        }}
                        defaultValue={value}
                        onChange={(e)=>{
                            newValues[colAccessor] = e.currentTarget.value;
                            setNewValues(newValues);
                            console.log(newValues)
                        }}
                        GQL={gql`query SPOTs {
                            spots {
                                id
                                id_spot
                                spot
                            }
                        }`}
                        accessors={['spots',['spot']]}
                        valuePicker={['spot']}
                    />
                )
            }
        },
        {
            header: 'shelf',
            accessor: 'location.shelf',
            formatter: (value)=>{return value},
            onRender: (value)=>{return value},
            onEdit: (value, [colAccessor, newValues, setNewValues], formatter) => { 
                newValues[colAccessor] = value;
                return (
                    <input
                        style={{
                            width: '100%',
                            boxSizing: 'border-box',
                            textAlign: 'center',
                            fontFamily: 'Inconsolata, monospace',
                            fontSize: '14px'
                        }}
                        type='text'
                        defaultValue={formatter(value)}
                        minLength={1}
                        maxLength={1}
                        onChange={(e)=>{
                            //force uppercase on all inputs
                            e.target.value = formatter(e.target.value)
                            newValues[colAccessor] = e.target.value;
                            setNewValues(newValues);
                        }}
                    />
                );
            }
        },
        {
            header: 'floor',
            accessor: 'location.floor',
            formatter: (value)=>{return value},
            onRender: (value)=>{return value},
            onEdit: (value, [colAccessor, newValues, setNewValues], formatter)=>{
                newValues[colAccessor] = value===''? 0 : value;
                return(
                    <input
                        style={{
                            width: '100%',
                            boxSizing: 'border-box',
                            textAlign: 'center',
                            fontFamily: 'Inconsolata, monospace',
                            fontSize: '14px'
                        }}
                        type="number"
                        min='0'
                        step='1'
                        defaultValue={formatter(value)}
                        onChange={(e)=>{
                            newValues[colAccessor] = e.target.value//parseInt(e.target.value);
                            //console.log('value shown', newValues);
                            setNewValues(newValues);
                        }}
                    />
                )
            },
        },
        {       
            header: 'shelved on',
            accessor: 'inserted',
            formatter: (value)=>{return value},
            onRender: (value)=>{return value},
        },
    ],
    variables: ['SKU10']
};

/**GQL */
const child2 = {
    query: gql`query HOLDERS_PRODUCT($name: String!) {
        holders(name: $name) {
            id
            products {
                id
                SKU10
                spec_1
                spec_2
                spec_3
                qty_current
                unit
                purchases {
                    id
                    id_purchase
                }
            }
        }
    }`,
    table: {
        accessor: 'holders.0.products',
        actions: [
            (index, rowData, onClick_toggleEdit) => {
                return (
                    <Edit 
                        key={index}
                        onClick={onClick_toggleEdit}
                        GQL={ gql`mutation fix_Stash($qty: Int, $product: [[String]]!, $holder: [[String]]!, $fk_purchase: ID) {
                            fix_Stash(qty: $qty, product: $product, holder: $holder, fk_purchase: $fk_purchase) {
                                id
                                qty_current
                            }
                        }`}
                        variableParser={ (newValues)=>{
                            // id: product_00, holder_00
                            const id_product = rowData['id'].split(',')[0].split('_')[1];
                            const id_holder = rowData['id'].split(',')[1].split('_')[1];
                            const fk_purchase = rowData['purchases'][0]? 
                                    rowData['purchases'][0]['id_purchase']
                                :
                                    null;
                            const variables = { 
                                variables: {
                                    'qty': Math.abs(+newValues['qty_current']),
                                    'product': [ ['id', id_product.toString() ] ],
                                    'holder': [ ['id', id_holder.toString()] ],
                                    'fk_purchase': fk_purchase
                                }
                            };
                            return variables
                        } }                      
                    >{{edit:'修', abort:'撤', save:'存'}}</Edit>
                )
            },
            (index, rowData) => {//insertStashPending(holder: [[String]], product: [[String]]!, purchase: [[String]]!, qty: Int): StashPending
                return;
                return (
                    <Insert
                        key={index}
                        onClick={()=>{console.log(rowData)
                            let qtyOut = prompt(`数量 (MAX ${rowData.qty_current} ${rowData.unit})：`, '-1');
                            if ( Math.abs(+qtyOut) > +rowData.qty_current ) {
                                qtyOut = prompt(`数量错误\n请重新输入 (MAX ${rowData.qty_current} ${rowData.unit})：`, '-1');
                            }
                            // id: product_00, holder_00
                            const id_product = rowData['id'].split(',')[0].split('_')[1];
                            const id_holder = rowData['id'].split(',')[1].split('_')[1];
                            return { 
                                holder: [ ['id', id_holder.toString()] ],
                                product: [ ['id', id_product.toString() ] ], 
                                purchase: [ ['id', rowData['purchases'][0]['id_purchase']] ],
                                qty: -Math.abs(parseInt(qtyOut))
                            } 
                        }}
                        variableParser={(values)=>{return { variables: values } }}
                        GQL={gql`mutation QUICK_insertStashPending($holder: [[String]], $product: [[String]]!, $purchase: [[String]]!, $qty: Int){
                            insertStashPending(holder: $holder, product: $product, purchase: $purchase, qty: $qty) {
                                id
                                id_stash_pending
                                holder {
                                    id
                                    id_holder
                                    name
                                    location {
                                        id
                                        id_location
                                        spot
                                        shelf
                                        floor
                                    }
                                }
                                product {
                                    id
                                    id_product
                                    SKU10
                                    brand
                                    title
                                    spec_1
                                    spec_2
                                    spec_3
                                    unit
                                }
                                purchase {
                                    id
                                    id_purchase
                                }
                                qty
                            }
                        }`}
                        accessor={'insertStashPending'}
                        updateQuery={gql`query STASH_PENDINGs_noholder{
                            stashPendings(inOut: "out") {
                                id
                                id_stash_pending
                                product {
                                    id
                                    id_product
                                    SKU10
                                    brand
                                    title
                                    spec_1
                                    spec_2
                                    spec_3
                                    unit
                                }
                                holder {
                                    id
                                    id_holder
                                    name
                                    location {
                                        id
                                        id_location
                                        spot
                                        shelf
                                        floor
                                    }
                                }
                                qty
                            }
                        }`}
                        accessor_cacheData={'stashPendings'}
                    >出</Insert>
                )
            },
        ],
    },
    columns: [
        {            
            header: 'SKU10',
            accessor: 'SKU10',
            formatter: (value)=>{return value},
            onRender: (value)=>{return onRender_SKU10(value)},
        },
        {            
            header: 'spec 1',
            accessor: 'spec_1',
            formatter: (value)=>{return value},
            onRender: (value)=>{return value},
        },
        {          
            header: 'spec 2',
            accessor: 'spec_2',
            formatter: (value)=>{return value},
            onRender: (value)=>{return value},
        },
        {       
            header: 'spec 3',
            accessor: 'spec_3',
            formatter: (value)=>{return value},
            onRender: (value)=>{return value},
        },
        {       
            header: 'qty',
            accessor: 'qty_current',
            formatter: (value)=>{return value},
            onRender: (value)=>{return Math.abs(+value)},
            onEdit: (value, [colAccessor, newValues, setNewValues], formatter) => {
                newValues[colAccessor] = value;
                return  (
                    <input 
                        style={{
                            width: '100%',
                            boxSizing: 'border-box',
                            textAlign: 'right',
                            fontFamily: 'Inconsolata, monospace',
                            fontSize: '14px'
                        }}
                        min="0"
                        type={'number'} 
                        defaultValue={value}
                        onChange={(e)=>{
                            newValues[colAccessor] = parseInt(e.target.value);
                            setNewValues(newValues);
                        }}
                    />
                )
            }
        },
        {       
            header: 'unit',
            accessor: 'unit',
            formatter: (value)=>{return value},
            onRender: (value)=>{return value},
        },
    ],
    variables: ['name'] //variables
};

const config = { 
    parent,
    child0,
    child1,
    child2
};

export default config