import ApolloTable from "../../../../components/ApolloTable";
/**css */
import './index.css';
/**config */
import config from './config';

function Hub() {
    return(
        <div className={'Hub_container'}>
            <ApolloTable
                cssPrefix={'Hub'}
                query={config.parent.query} 
                columns={config.parent.columns} 
                table={config.parent.table} 
                //dataModifier={filter}
            >
                <ApolloTable 
                    cssPrefix={'Hub0'}
                    query={config.child0.query} 
                    columns={config.child0.columns} 
                    table={config.child0.table} 
                    variables={config.child0.variables} 
                />
            </ApolloTable>

        </div>
    )
}

export default Hub;