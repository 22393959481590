import { gql } from "@apollo/client";
/** */
import { Insert, Edit } from '../../../../components/ApolloButtons';
import { ApolloSelect } from '../../../../components/ApolloCells'
/**Apollo Columns */
import { onRender_SKU10 } from '../../../../components/ApolloCells'

const parent = {
    /**GQL */
    query: gql`query SPOTS {
        spots {
            id
            spot
            COUNT_shelf
            shelfs {
                id
                shelf
                COUNT_floor
            }
        }
    }`,
    table: {
        accessor: 'spots',
        actions: null,
    },
    columns: [
        {
            header: 'spot',
            accessor: 'spot',
            formatter: (value)=>{return value},
            onRender: (value)=>{return value},
        },
        {
            header: 'shelfs',
            accessor: 'COUNT_shelf',
            formatter: (value)=>{return value},
            onRender: (value)=>{return value},
        },
    ]
}

/**GQL */
const child0 = {
    query: gql`query SPOT_SHELFS($spot: String!) {
        spots(spot: $spot) {
            id
            shelfs {
                id
                spot
                shelf
                COUNT_floor
            }
        }
    }`,
    table: {
        accessor: 'spots.0.shelfs', //if needs first array of a key, use dot .0 instead of [0]
        actions: null,
    },
    columns: [
        {
            header: 'shelf',
            accessor: 'shelf',
            formatter: (value)=>{return value},
            onRender: (value)=>{return value},
        },
        {
            header: 'floors',
            accessor: 'COUNT_floor',
            formatter: (value)=>{return value},
            onRender: (value)=>{return value},
        },
    ],
    variables: ['spot']
}

/**GQL */
const child1 = {
    query: gql`query SHELF_LOCATIONS($spot: String!, $shelf: String!) {
        shelfs(spot: $spot, shelf: $shelf) {
            id
            locations {
                id
                id_location
                spot
                shelf
                floor
                holders {
                    id
                    name
                }
                COUNT_holder
            }
        }
    }`,
    table: {
        accessor: 'shelfs.0.locations', //if needs first array of a key, use dot .0 instead of [0]
        action: null,
    },
    columns: [
        {
            header: 'floor',
            accessor: 'floor',
            formatter: (value)=>{return value},
            onRender: (value)=>{return value},
        },
        {
            header: 'holders',
            accessor: 'COUNT_holder',
            formatter: (value)=>{return value},
            onRender: (value)=>{return value},
        }
    ],
    variables: ['spot', 'shelf'],
}

/**GQL */
const child2 = { 
    query: gql`query LOCATION_HOLDERS($id_location: ID) {
        locations(id_location: $id_location) {
            id
            holders {
                id
                id_holder
                name
                location {
                    id
                    id_location
                    spot
                    shelf
                    floor
                }
            }
        }
    }`,
    table: {
        accessor: 'locations.0.holders', //if needs first array of a key, use dot .0 instead of [0]
        actions: [
            (index, rowData, onClick_toggleEdit) => {
                return (
                    <Edit 
                        key={index}
                        onClick={onClick_toggleEdit}
                        GQL={ gql`mutation move_Holder($holder: [[String]], $location:[[String]]) {
                            move_Holder(holder: $holder, location: $location) {
                                id
                                id_holder
                                name
                                location {
                                    id
                                    id_location
                                    spot
                                    shelf
                                    floor
                                }
                            }
                        }`}
                        variableParser={ (newValues)=>{
                            const variables = { 
                                variables: {
                                    'holder': [
                                        ['id', newValues.id.split('_')[1] ]
                                    ],
                                    'location': [
                                        [ 'spot', newValues['location.spot'] ],
                                        [ 'shelf', newValues['location.shelf'] ],
                                        [ 'floor', newValues['location.floor'] ],
                                    ]
                                }
                            };
                            return variables
                        } }                      
                    >{{edit:'移', abort:'撤', save:'存'}}</Edit>
                )
            },
        ]
    },
    columns: [
        {
            header: 'name',
            accessor: 'name',
            formatter: (value)=>{return value},
            onRender: (value)=>{return value},
        },
        {
            header: 'spot',
            accessor: 'location.spot',
            formatter: (value)=>{return value},
            onRender: (value)=>{return value},
            onEdit: (value, [colAccessor, newValues, setNewValues], formatter) => { 
                newValues[colAccessor] = value;
                return (
                    <ApolloSelect
                    //style in component already. move it out
                        style={{
                            width: '100%',
                            boxSizing: 'border-box',
                            textAlign: 'center',
                            fontFamily: 'Inconsolata, monospace',
                            fontSize: '14px'
                        }}
                        defaultValue={value}
                        onChange={(e)=>{
                            newValues[colAccessor] = e.currentTarget.value;
                            setNewValues(newValues);
                            console.log(newValues)
                        }}
                        GQL={gql`query SPOTs {
                            spots {
                                id
                                id_spot
                                spot
                            }
                        }`}
                        accessors={['spots',['spot']]}
                        valuePicker={['spot']}
                    />
                )
            }
        },
        {
            header: 'shelf',
            accessor: 'location.shelf',
            formatter: (value)=>{return value},
            onRender: (value)=>{return value},
            onEdit: (value, [colAccessor, newValues, setNewValues], formatter) => { 
                newValues[colAccessor] = value;
                return (
                    <input
                        style={{
                            width: '100%',
                            boxSizing: 'border-box',
                            textAlign: 'center',
                            fontFamily: 'Inconsolata, monospace',
                            fontSize: '14px'
                        }}
                        type='text'
                        defaultValue={formatter(value)}
                        minLength={1}
                        maxLength={1}
                        onChange={(e)=>{
                            //force uppercase on all inputs
                            e.target.value = formatter(e.target.value)
                            newValues[colAccessor] = e.target.value;
                            setNewValues(newValues);
                        }}
                    />
                );
            }
        },
        {
            header: 'floor',
            accessor: 'location.floor',
            formatter: (value)=>{return value},
            onRender: (value)=>{return value},
            onEdit: (value, [colAccessor, newValues, setNewValues], formatter)=>{
                newValues[colAccessor] = value===''? 0 : value;
                return(
                    <input
                        style={{
                            width: '100%',
                            boxSizing: 'border-box',
                            textAlign: 'center',
                            fontFamily: 'Inconsolata, monospace',
                            fontSize: '14px'
                        }}
                        type="number"
                        min='0'
                        step='1'
                        defaultValue={formatter(value)}
                        onChange={(e)=>{
                            newValues[colAccessor] = e.target.value//parseInt(e.target.value);
                            //console.log('value shown', newValues);
                            setNewValues(newValues);
                        }}
                    />
                )
            },
        },
    ],
    variables: ['id_location']
}

/**GQL */
const child3 = {
    query: gql`query HOLDERS_PRODUCT($id_holder: ID!) {
        holders(id_holder: $id_holder) {
            id
            products {
                id
                id_product
                SKU10
                spec_1
                spec_2
                spec_3
                qty_current
                unit
                purchases {
                    id
                    id_purchase
                }
            }
            
        }
    }`,
    table: {
        accessor: 'holders.0.products', //if needs first array of a key, use dot .0 instead of [0]
        actions: [ //action button null. what do in table generator. ****param is nest what do? *****
            (index, rowData, onClick_toggleEdit) => {
                return (
                    <Edit
                        key={index}
                        onClick={onClick_toggleEdit}
                        GQL={ gql`mutation fix_Stash($qty: Int, $product: [[String]]!, $holder: [[String]]!, $fk_purchase: ID) {
                            fix_Stash(qty: $qty, product: $product, holder: $holder, fk_purchase: $fk_purchase) {
                                id
                                qty_current
                            }
                        }`}
                        variableParser={ (newValues)=>{
                            // id: product_00, holder_00
                            const id_product = rowData['id'].split(',')[0].split('_')[1];
                            const id_holder = rowData['id'].split(',')[1].split('_')[1];
                            const fk_purchase = rowData['purchases'][0]? 
                                    rowData['purchases'][0]['id_purchase']
                                :
                                    null;
                            const variables = { 
                                variables: {
                                    'qty': Math.abs(+newValues['qty_current']),
                                    'product': [ ['id', id_product.toString() ] ],
                                    'holder': [ ['id', id_holder.toString()] ],
                                    'fk_purchase': fk_purchase
                                }
                            };
                            return variables
                        } }                      
                    >{{edit:'修', abort:'撤', save:'存'}}</Edit>
                )
            },
            (index, rowData) => {//insertStashPending(holder: [[String]], product: [[String]]!, purchase: [[String]]!, qty: Int): StashPending
                return (
                    <Insert
                        key={index}
                        onClick={()=>{console.log(rowData)
                            let qtyOut = prompt(`数量 (MAX ${rowData.qty_current} ${rowData.unit})：`, '-1');
                            if ( Math.abs(+qtyOut) > +rowData.qty_current ) {
                                qtyOut = prompt(`数量错误\n请重新输入 (MAX ${rowData.qty_current} ${rowData.unit})：`, '-1');
                            }
                            // id: product_00, holder_00
                            const id_product = rowData['id'].split(',')[0].split('_')[1];
                            const id_holder = rowData['id'].split(',')[1].split('_')[1];
                            return { 
                                holder: [ ['id', id_holder.toString()] ],
                                product: [ ['id', id_product.toString() ] ], 
                                purchase: [ ['id', rowData['purchases'][0]['id_purchase']] ],
                                qty: -Math.abs(parseInt(qtyOut))
                            } 
                        }}
                        variableParser={(values)=>{console.log(values);return { variables: values } }}
                        GQL={gql`mutation QUICK_insertStashPending($holder: [[String]], $product: [[String]]!, $purchase: [[String]]!, $qty: Int){
                            insertStashPending(holder: $holder, product: $product, purchase: $purchase, qty: $qty) {
                                id
                                id_stash_pending
                                holder {
                                    id
                                    id_holder
                                    name
                                    location {
                                        id
                                        id_location
                                        spot
                                        shelf
                                        floor
                                    }
                                }
                                product {
                                    id
                                    id_product
                                    SKU10
                                    brand
                                    title
                                    spec_1
                                    spec_2
                                    spec_3
                                    unit
                                }
                                purchase {
                                    id
                                    id_purchase
                                }
                                qty
                            }
                        }`}
                        accessor={'insertStashPending'}
                        updateQuery={gql`query STASH_PENDINGs_noholder{
                            stashPendings(inOut: "out") {
                                id
                                id_stash_pending
                                product {
                                    id
                                    id_product
                                    SKU10
                                    brand
                                    title
                                    spec_1
                                    spec_2
                                    spec_3
                                    unit
                                }
                                holder {
                                    id
                                    id_holder
                                    name
                                    location {
                                        id
                                        id_location
                                        spot
                                        shelf
                                        floor
                                    }
                                }
                                qty
                            }
                        }`}
                        accessor_cacheData={'stashPendings'}
                    >出</Insert>
                )
            },
        ],
    },
    columns: [
        {
            header: 'SKU10',
            accessor: 'SKU10',
            formatter: (value)=>{return value},
            onRender: (value)=>{return onRender_SKU10(value)},
        },
        {
            header: 'spec 1',
            accessor: 'spec_1',
            formatter: (value)=>{return value},
            onRender: (value)=>{return value},
        },
        {
            header: 'spec 2',
            accessor: 'spec_2',
            formatter: (value)=>{return value},
            onRender: (value)=>{return value},
        },
        {
            header: 'spec 3',
            accessor: 'spec_3',
            formatter: (value)=>{return value},
            onRender: (value)=>{return value},
        },
        {       
            header: 'qty',
            accessor: 'qty_current',
            formatter: (value)=>{return value},
            onRender: (value)=>{return Math.abs(+value)},
            onEdit: (value, [colAccessor, newValues, setNewValues], formatter) => {
                newValues[colAccessor] = value;
                return  (
                    <input 
                        style={{
                            width: '100%',
                            boxSizing: 'border-box',
                            textAlign: 'right',
                            fontFamily: 'Inconsolata, monospace',
                            fontSize: '14px'
                        }}
                        min="0"
                        type={'number'} 
                        defaultValue={value}
                        onChange={(e)=>{
                            newValues[colAccessor] = parseInt(e.target.value);
                            setNewValues(newValues);
                        }}
                    />
                )
            }
        },
        {
            header: 'unit',
            accessor: 'unit',
            formatter: (value)=>{return value},
            onRender: (value)=>{return value},
        },
    ],
    variables: ['id_holder']
};

const config = { 
    parent,
    child0,
    child1,
    child2,
    child3
};

export default config

