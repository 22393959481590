import ApolloTable from "../../../../components/ApolloTable";
/**css */
import './index.css';
/**config */
import config from './config';

/**even though config querys for correct status.title 
 * but after user does mutation, new mutated data will be append to cache, causing duplicates
 * same data will sit in SHIPMENT(arrived || not arrived) BUT they show correct status and wont see duplicates in each table if updateQuery name is correct
 * 
 * turn this filter on for a second layer (right before frontend) filter
 * turn off when its hurting performance
 * */
const filter = (arr) => {
    let result = [];
    arr.forEach(element => {
        if ( element.status.title==='arrived') {result.push(element)}
    });
    return result;
}

function Dcb() {
    return(
        <div className={'Dcb_container'}>
            <ApolloTable
                cssPrefix={'Dcb'}
                query={config.parent.query} 
                columns={config.parent.columns} 
                table={config.parent.table} 
                dataModifier={filter}
                exposedChildren={true}
            >
                <ApolloTable 
                    cssPrefix={'Dcb0'}
                    query={config.child0.query} 
                    columns={config.child0.columns} 
                    table={config.child0.table} 
                    variables={config.child0.variables} 
                />
            </ApolloTable>

        </div>
    )
}

export default Dcb;