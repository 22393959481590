import css from './index.module.css'
import PropTypes from 'prop-types';
import { useState } from 'react';
/** */
import { useToken } from '../../context/auth';
/**font awesome */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid, regular, brands } from '@fortawesome/fontawesome-svg-core/import.macro' // <-- import styles to be used
/** */
import { domain } from '../../context/variables'
import { useEffect } from 'react';
import renderDraggableWindow, { killDraggableWindow } from '../../components/DraggableWindow'

async function registerUser(credentials) {
    return fetch('/api/register', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(credentials)
    })
        .then(data => {return data.json()})
}

function SignUp(props) {
    const {
    } = props;

    const [email, setEmail] = useState();
    const [username, setUsername] = useState();
    const [password, setPassword] = useState();
    const [confirmPassword, setConfirmPassword] = useState();

    async function handleRegister(e) {
        e.preventDefault(); //console.log(password, confirmPassword)
        if (password !== confirmPassword) { return alert('passwords not matching'); }
        const register = await registerUser({
            email,
            username,
            password
        })
        if (register !== 'SUCCESS') { alert(register.msg); return; }
        return alert(register.msg);
    }

    return(
        <div className={css.container}>
            <a className={css.iconContainer} href='https://www.bnpbeautysupply.com'>
                <img className={css.icon} src='/SVG/logo-bnp-icon-0.svg' />
            </a>
            <form className={css.form} onSubmit={handleRegister}>
                <label className={css.formRow}>
                    <div className={css.formRowIcon} >
                        <FontAwesomeIcon icon={solid('at')} inverse/>
                    </div>
                    <input className={css.formRowInput} type="email" onChange={e => setEmail(e.target.value)}/>
                </label>
                <label className={css.formRow}>
                    <div className={css.formRowIcon} >
                        <FontAwesomeIcon icon={solid('user')} inverse/>
                    </div>
                    <input className={css.formRowInput} type="text" onChange={e => setUsername(e.target.value)}/>
                </label>
                <label className={css.formRow}>
                    <div className={css.formRowIcon} >
                        <FontAwesomeIcon icon={solid('key')} inverse/>
                    </div>
                    <input className={css.formRowInput} type="password" onChange={e => setPassword(e.target.value)}/>
                </label>
                <label className={css.formRow}>
                    <div className={css.formRowIcon} >
                        <FontAwesomeIcon icon={solid('key')} inverse/>
                    </div>
                    <input className={css.formRowInput} type="password" onChange={e => setConfirmPassword(e.target.value)}/>
                </label>
                <button className={css.signUpButton} type="submit">{' CONFIRM '}</button>
            </form>
            <a className={css.formForgotPasswordContainer} href={`${domain}`}>
                take me back to login
            </a>
        </div>
    )
}

export default SignUp;