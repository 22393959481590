import './index.css'
import { BrowserRouter, Route, Switch } from 'react-router-dom';
/**components*/
import PageButton from '../../components/PageButton';
/**tabs */ 
import Spzb from './tabs/Spzb'
import Ckzb from './tabs/Ckzb'
import Ccb from './tabs/Ccb'
/**font awesome */
import { solid, regular, brands } from '@fortawesome/fontawesome-svg-core/import.macro' 
//
import { renderDraggableWindow_History } from '../../components/DraggableWindow'
//
import addOn from './addOn';

const CangTabs = () => {
    return(<>
        <PageButton type='slave' to="/cang">仓</PageButton>
        <PageButton 
            type='slave' 
            to="/cang/spzb"
            icon={solid('book')}
            onClick_addOn={(e) => {
                renderDraggableWindow_History({
                    cssPrefix: 'SpzbH',
                    spawnTitle: '商品总表',
                    query:  addOn.SpzbH.query,
                    table: addOn.SpzbH.table,
                    columns: addOn.SpzbH.columns
                })
            }}
        >商品总表</PageButton>
        <PageButton 
            type='slave' 
            to="/cang/ckzb"
            icon={solid('book')}
            onClick_addOn={(e) => {
                renderDraggableWindow_History({
                    cssPrefix: 'CkzbH',
                    spawnTitle: '仓库总表',
                    query:  addOn.CkzbH.query,
                    table: addOn.CkzbH.table,
                    columns: addOn.CkzbH.columns
                })
            }}
        >仓库总表</PageButton>
        <PageButton 
            type='slave' 
            to="/cang/ccb"
            icon={solid('book')}
            onClick_addOn={(e) => {
                renderDraggableWindow_History({
                    cssPrefix: 'CcbH',
                    spawnTitle: '出仓表',
                    query:  addOn.CcbH.query,
                    table: addOn.CcbH.table,
                    columns: addOn.CcbH.columns,
                    variables: addOn.CcbH.variables
                })
            }}
        >出仓表</PageButton>
    </>)
}

function Cang(props) {
    return(
        <Switch>
            <Route exact path="/cang">
                <p>CANG</p>
                <ul>
                    <li>+ 出仓表</li>
                    <li>+ 仓库历史</li>
                        <ul>
                            <li>入仓</li>
                            <li>出仓</li>
                            <li>修正</li>
                        </ul>
                </ul>
            </Route>  
            <Route path="/cang/spzb">
                <Spzb/>
            </Route>
            <Route path="/cang/ckzb">
                <Ckzb/>
            </Route>
            <Route path="/cang/ccb">
                <Ccb/>
                {/*<p>
                    出仓表显示<br/>
                    <ul>
                        <li>fk_holder(fk_location), date</li>
                        <li>give user the ability to keep empty holder on shelf (by deleting it from this list before pushing through acitivity)</li>
                        <li>upon pushing pending through activity: 
                            <ol>
                                <li>copy entries to activity with new date(total 2 dates, created in pending and when pushed to activity)</li>
                                <li>{`updates corresponding entries in holder by matching fk_holder. alive=>0, fk_location=>null`}</li>
                                <li>redirect to history</li>
                            </ol>
                        </li>
                    </ul>
                </p>
                <p>problem: history cxb vs ccb. how to show together? show log instead?</p>
                <p>/chu/cxb</p>
                <p>
                    出箱表显示<br/>
                    <ul>
                        <li>fk_product(*), qty, fk_holder(fk_location), fk_destination(*), date</li>
                        <li>upon pushing pending through activity: 
                            <ol>
                                <li>copy entries to activity with new date(total 2 dates, created in pending and when pushed to activity)</li>
                                <li>updates corresponding entries in "stash" by match [fk_product, fk_holder]</li>
                                <li>query all affected holders, push them to holder_pending if empty</li>
                                <li>{`assuming the list of holders will be saved in an array. if array.lenght>0 redirect to 出仓表, else redirect to history`}</li>
                            </ol>
                        </li>
                    </ul>
                </p>
                <p>problem: history cxb vs ccb. how to show together? show log instead?</p>*/}
            </Route>
        </Switch>
    )
}

export { CangTabs , Cang }