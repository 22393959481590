import ApolloTable from "../../../../components/ApolloTable";
/**css */
import './index.css';
/**config */
import config from './config';
/**apollo-client*/
import { CallProcedure } from "../../../../components/ApolloButtons";
/** */
import { gql, useQuery } from "@apollo/client";

function Ccb() {
    return(
        <div className={'Ccb_container'}>
            <div>
                <ApolloTable
                    cssPrefix={'Ccb'}
                    query={config[0].parent.query} 
                    columns={config[0].parent.columns} 
                    table={config[0].parent.table} 
                >
                    <ApolloTable 
                        query={config[0].child0.query} 
                        columns={config[0].child0.columns} 
                        table={config[0].child0.table} 
                        variables={config[0].child0.variables} 
                    />
                </ApolloTable>
            </div>
            <CallProcedure
                className={'Ccb_confirm_button'}
                variables={()=>{
                    const answer = window.confirm(`
                        About to update.
                        
                        Are you sure?
                    `) 
                    return {variables:{confirm:answer}}
                }}
                GQL = { gql`mutation CONFIRM_REMOVE_STASH_PENDING($confirm: Boolean!){
                    remove_Stash(confirm: $confirm) {
                        id
                        id_stash_pending
                        product {
                            id
                            id_product
                            SKU10
                            brand
                            title
                            spec_1
                            spec_2
                            spec_3
                            unit
                        }
                        holder {
                            id
                            id_holder
                            name
                        }
                        qty
                        destination {
                            id
                            id_destination
                            title
                        }
                    }
                }`}
/*
This part was supposed to update CCB but it doesnt work               
                accessor = {'remove_Stash'}
                updateQuery = {gql`query STASH_PENDINGs_noholder{
                    stashPendings(fk_holder: null, inOut: "in") {
                        id
                        id_stash_pending
                        product {
                            id
                            id_product
                            SKU10
                            brand
                            title
                            spec_1
                            spec_2
                            spec_3
                            unit
                        }
                        holder {
                            id
                            id_holder
                            name
                        }
                        qty
                        destination {
                            id
                            id_destination
                            title
                        }
                    }
                }`}
            accessor_cacheData = {'stashPendings'}
*/
            >CONFIRM</CallProcedure>
        </div>
    )
}

export default Ccb;