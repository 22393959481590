import { BrowserRouter, Route, Switch } from 'react-router-dom';
/**components*/
import PageButton from '../../components/PageButton';
/**tabs */ 
import Tjqd from './tabs/Tjqd'
import Cgb from './tabs/Cgb'
import Hub from './tabs/Hub'
import Xzsp from './tabs/Xzsp'

const CaiTabs = () => {
    return(<>
        <PageButton type='slave' to="/cai">采</PageButton>
        <PageButton type='slave' to="/cai/tjqd">推荐清单</PageButton>
        <PageButton type='slave' to="/cai/cgb">采购表</PageButton>
        <PageButton type='slave' to="/cai/hub">货运表</PageButton>
        <PageButton type='slave' to="/cai/xzsp">新增商品</PageButton>
    </>)
}

function Cai(props) {
    return(
        <Switch>
            <Route exact path="/cai">
                <p>CAI</p>
            </Route>  
            <Route path="/cai/tjqd">
                <Tjqd/>
            </Route>
            <Route path="/cai/cgb">
                <Cgb/>
            </Route>
            <Route path="/cai/hub">
                <Hub/>
            </Route>
            <Route path="/cai/xzsp">
                <Xzsp/>
            </Route>
        </Switch>
    )
}

export { CaiTabs , Cai }